import React, { useState, useRef } from "react";
import {
    Box,
    Paper,
    Typography,
    ListItem,
    ListItemText,
    IconButton,
    ListItem as MuiListItem,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const getBackgroundColor = (value) => {
    if (value <= 30) {
        return "#399918";
    } else if (value > 30 && value <= 60) {
        return "#D3D3D3";
    } else if (value > 60 && value <= 100) {
        return "#EB5B00";
    } else {
        return "white";
    }
};

const TemplateSelector = ({ templates, spamWords , onTemplateSelect}) => {
    const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);
    const scrollRef = useRef(null);

      const sortedTemplates = templates.sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10));

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
         onTemplateSelect(template);
    };

    const evaluateSpam = (text) => {
        const words = text.split(/\s+/);
        const spamIndicators = words.filter((word) => spamWords.includes(word));
        return spamIndicators;
    };

    const spamIndicators = evaluateSpam(selectedTemplate.body);

    const scrollLeft = () => {
        if (templates.length > 5 && scrollRef.current) {
            scrollRef.current.scrollBy({ left: -130, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (templates.length > 5 && scrollRef.current) {
            scrollRef.current.scrollBy({ left: 130, behavior: "smooth" });
        }
    };

    return (
        <Box>
            <Paper
                sx={{
                    width: "600px",
                    padding: "20px",
                    border: "1px solid #f1f1f1",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    boxShadow: "none !important",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {templates.length > 5 && (
                        <IconButton onClick={scrollLeft} style={{ marginTop: "-25px" }}>
                            <ArrowBackIosIcon sx={{ height: "20px" }} />
                        </IconButton>
                    )}
                    <Box
                        ref={scrollRef}
                        sx={{
                            display: "flex",
                            overflowX: "hidden",
                            pb: 2,
                            width: "calc(100% - 64px)",
                        }}
                    >
                        {sortedTemplates.map((template) => {
                            const backgroundColor = getBackgroundColor(parseInt(template.value, 10));

                            return (
                                <ListItem
                                    key={template.id}
                                    className="template-card"
                                    onClick={() => handleTemplateSelect(template)}
                                    sx={{
                                        backgroundColor:
                                            selectedTemplate.id === template.id ? "#e0f2f1" : backgroundColor,
                                        "&:hover": {
                                            backgroundColor: "#e0f2f1",
                                        },
                                        flex: "0 0 auto",
                                        width: "120px",
                                        m: 1,
                                        p: 2,
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        border: "1px solid #ccc",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <ListItemText sx={{ display: "flex", justifyContent: "center" }}>
                                        <Typography>{template.subject.slice(0, 20).concat("....")}</Typography>
                                    </ListItemText>
                                </ListItem>
                            );
                        })}
                    </Box>
                    {templates.length > 5 && (
                        <IconButton onClick={scrollRight} style={{ marginTop: "-25px" }}>
                            <ArrowForwardIosIcon sx={{ height: "20px" }} />
                        </IconButton>
                    )}
                </Box>
                <Paper sx={{ p: 2, display: "flex" }}>
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                borderRadius: "5px",
                                padding: "10px",
                                marginBottom: "20px",
                                backgroundColor: "white",
                                alignItems: "center",
                                height: "40px",
                                gap: 2,
                                width: "530px", // Set width to 550px
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#24a0ed",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                Subject:
                            </Typography>
                            {selectedTemplate && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        padding: "5px 10px",
                                        borderRadius: "5px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        flexGrow: 1, // Allows the text to take up remaining space
                                    }}
                                >
                                    {selectedTemplate.subject}
                                </Typography>
                            )}
                            <ContentCopyIcon sx={{ marginLeft: "auto", cursor: "pointer" }} />
                        </Box>
                        <Box
                            sx={{
                                borderRadius: "5px",
                                padding: "10px",
                                backgroundColor: "white",
                                alignItems: "center",
                                overflow: "hidden",
                                width: "530px", // Set width to 550px
                            }}
                        >
                            <div
                                style={{
                                    height: "300px",
                                    overflowY: "auto",
                                    padding: "10px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "5px",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: selectedTemplate?.body,
                                }}
                            ></div>
                            <ContentCopyIcon sx={{ marginTop: "10px", cursor: "pointer", float: "right" }} />
                        </Box>
                    </Box>
                </Paper>
            </Paper>
        </Box>
    );
};

export default TemplateSelector;
