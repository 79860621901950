import React, { useState, useEffect } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Checkbox,
    IconButton,
} from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import OverflowMenu from "./OverflowMenu";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";

const StyledTableCell = (props) => <TableCell {...props} />;
const StyledTableRow = (props) => <TableRow {...props} />;

const CustomTable = ({
    data,
    headers,
    renderCell,
    selectedRows,
    setSelectedRows,
    handleErrorStatus,
    setAuditRecords,
    tableloading,
    deleteFunction,
    auditRecords,
}) => {
    const [tableData, setTableData] = useState(data);
    const [loading, setLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState("desc");
    const [manualAuditSortOrder, setManualAuditSortOrder] = useState("desc");

    useEffect(() => {
        const initialSortedData = [...data].sort((a, b) => {
            const dateA = new Date(a.updatedAt);
            const dateB = new Date(b.updatedAt);

            return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        });
        setTableData(initialSortedData);
    }, [data, sortOrder]);


    
    // Extract the keys from the first object in the data array (excluding 'id','uniqueProcessCode')
    const keys =
        data.length > 0 ? Object.keys(data[0]).filter((key) => !["id", "uniqueProcessCode"].includes(key)) : [];

    const handleSelectRow = (rowId) => {
        setSelectedRows((prevSelected) =>
            prevSelected.includes(rowId) ? prevSelected.filter((id) => id !== rowId) : [...prevSelected, rowId]
        );
    };

    const handleDeleteRow = async (rowId) => {
        deleteFunction(rowId);
    };

    const handleSortDate = () => {
        const sortedData = [...tableData].sort((a, b) => {
            const dateA = new Date(a.updatedAt);
            const dateB = new Date(b.updatedAt);
            return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        });

        setTableData(sortedData);
        setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    };

    const handleSortManualAudit = () => {
        const sortedData = [...tableData].sort((a, b) => {
            if (manualAuditSortOrder === "asc") {
                return a.manualAuditCheckResult > b.manualAuditCheckResult ? 1 : -1;
            } else {
                return a.manualAuditCheckResult < b.manualAuditCheckResult ? 1 : -1;
            }
        });
        
        setTableData(sortedData);
        setManualAuditSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    };
    const TableCellComponent = (row, key) => {
        return (
            <StyledTableCell key={key}>
                <Link
                    to={
                        row.processStatus !== "ERROR" &&
                        (function () {
                            let url = "/dashboard/manualAudit";
                            let data = auditRecords.find((item) => {
                                return item.id === row.id;
                            });
                           
                            url =
                                data !== undefined && row.processStatus === "SUCCESS"
                                    ? `/dashboard/manualAudit/${data.uniqueProcessCode}`
                                    : url;
                            // you can change the function for progress and error;
                            return url;
                        })()
                    }
                    style={{ textDecoration: "none", color: "#000" }}
                >
                    {renderCell ? renderCell(key, row[key]) : row[key]}
                </Link>
            </StyledTableCell>
        );
    };
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead sx={{ backgroundColor: "#F8F9FB" }}>
                    <TableRow>
                        <StyledTableCell align="center">
                            <Checkbox
                                checked={selectedRows.length === tableData.length && tableData.length > 0}
                                onChange={(e) =>
                                    setSelectedRows(e.target.checked ? tableData.map((row) => row.id) : [])
                                }
                            />
                        </StyledTableCell>
                        {headers.map((header, index) => (
                            <StyledTableCell
                                key={index}
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                }}
                            >
                                {header === "Report-Date" ? (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {header}
                                        <IconButton onClick={handleSortDate}>
                                            {sortOrder === "asc" ? <ArrowUpward /> : <ArrowDownward />}
                                        </IconButton>
                                    </div>
                                ) : header === "Status" ? (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {header}
                                        <IconButton onClick={handleSortManualAudit}>
                                            {manualAuditSortOrder === "asc" ? <ArrowUpward /> : <ArrowDownward />}
                                        </IconButton>
                                    </div>
                                ) : (
                                    header
                                )}
                            </StyledTableCell>
                        ))}
                        <StyledTableCell
                            sx={{
                                fontSize: "16px",
                                fontWeight: "bold",
                            }}
                        >
                            Actions
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableloading ? (
                        [...Array(5)].map((_, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell>
                                    <Skeleton variant="text" width={50} height={30} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" width={100} height={30} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" width={100} height={30} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" width={100} height={30} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" width={50} height={30} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" width={50} height={30} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Skeleton variant="text" width={50} height={30} />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : tableData.length === 0 ? (
                        <StyledTableRow>
                            <StyledTableCell colSpan={headers.length + 2} align="center">
                                Data Not Found
                            </StyledTableCell>
                        </StyledTableRow>
                    ) : (
                        tableData.map((row) => (
                            <StyledTableRow
                                key={row.id}
                                sx={{ backgroundColor: "#fff", "&:hover": { backgroundColor: "#f9f9f9" } }}
                            >
                                <StyledTableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(row.id)}
                                        onChange={() => handleSelectRow(row.id)}
                                    />
                                </StyledTableCell>
                                {keys.map((key) => (
                                    <StyledTableCell
                                        key={key}
                                        onClick={() => {
                                            if (row.processStatus === "ERROR") {
                                                handleErrorStatus("Report Failed to Generated");
                                            }
                                        }}
                                    >
                                        <Link
                                            to={(function () {
                                                let url = "/dashboard/manualAudit";
                                                let data = auditRecords.find((item) => {
                                                    return item.id === row.id;
                                                });
                                                url =
                                                    data !== undefined && row.processStatus === "SUCCESS"
                                                        ? `/dashboard/manualAudit/${data.uniqueProcessCode}`
                                                        : row.processStatus === "ERROR"
                                                          ? url
                                                          : `/dashboard/manualAudit/${row.sender}?reCheck=${data.uniqueProcessCode}`;
                                                return url;
                                            })()}
                                            style={{ textDecoration: "none", color: "#000" }}
                                        >
                                            {renderCell ? renderCell(key, row[key]) : row[key]}
                                        </Link>
                                    </StyledTableCell>
                                ))}
                                <StyledTableCell>
                                    <OverflowMenu
                                        rowId={row.id}
                                        data={row}
                                        onDelete={() => handleDeleteRow(row.id)}
                                        loading={loading}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CustomTable;
