import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Audit.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import PropTypes from "prop-types";

import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import {
    Box,
    Button,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    ClickAwayListener,
    IconButton,
} from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Pie, Line, PolarArea } from "react-chartjs-2";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import _ from "underscore";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate } from "react-router-dom";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
ChartJS.register(ArcElement, Tooltip, Legend);

const circleStyle = (color) => ({
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: color,
    marginRight: "5px",
});
const circleStyle1 = (color) => ({
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: color,
    marginRight: "5px",
});
const circleStyle2 = (color) => ({
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: color,
    marginRight: "5px",
});

const AuditReport = () => {
    const intervalRef = React.useRef(null);
    const theme = useTheme();
    const [progress, setProgress] = useState(0);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({
        subject: "Subject 1",
        originalContent: "Hi this is my content",
    });
    const [dnsRecords, setDnsRecords] = useState([]);
    const [ipTests, setIpTests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [domainBlacklist, setDomainBlacklist] = useState([]);
    const [ipBlacklist, setIpBlacklist] = useState([]);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [domainTest, setDomainTest] = useState([]);
    const [lineGraphLabel, setLibeGraphLabel] = useState([]);
    const [lineGraphDataSet, setLineGraphDataSet] = useState([]);
    const [lastReportDate, setLastReportDate] = useState("");
    const [open, setOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [randomStringForProcess, setRandomStringForProcess] = useState("");
    const [expandedTemplate, setExpandedTemplate] = useState(null);
    const [overallScore, setOverallScore] = useState(0);
    const navigate = useNavigate();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopySubject = () => {
        const subjectText = selectedTemplate.subject;

        navigator.clipboard
            .writeText(subjectText)
            .then(() => {
                setSnackbarMessage("Subject text copied to clipboard");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((err) => {
                setSnackbarMessage("Error copying subject text");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    const handleCopyBody = () => {
        const bodyText = selectedTemplate.originalContent;
        navigator.clipboard
            .writeText(bodyText)
            .then(() => {
                setSnackbarMessage("Body text copied to clipboard");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((err) => {
                setSnackbarMessage("Error copying body text");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    const handlerefinedBody = () => {
        const refinedbody = selectedTemplate.refinedContent;
        navigator.clipboard
            .writeText(refinedbody)
            .then(() => {
                setSnackbarMessage("Body text copied to clipboard");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((err) => {
                setSnackbarMessage("Error copying body text");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    useEffect(() => {
        if (randomStringForProcess) {
            // First call after 7 seconds
            const initialTimeout = setTimeout(() => {
                getAuditProgress(); // Call immediately after the initial delay

                // Set up interval to call every 5 seconds
                intervalRef.current = setInterval(() => {
                    getAuditProgress();
                }, 5000);
            }, 10000);

            return () => {
                clearTimeout(initialTimeout); // Cleanup timeout
                clearInterval(intervalRef.current); // Stop the interval
            };
        }
    }, [randomStringForProcess]);

    const getAuditProgress = async () => {
        try {
            await fetch(`${process.env.REACT_APP_SERVER_URL}/getAuditProgress?randomString=${randomStringForProcess}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Data-------->", data.progress);
                    if (data && data.progress !== undefined && data.progress !== null) {
                        setProgress(data.progress);
                    }

                    if (data.progress >= 100 || data.status === "Fail") {
                        clearInterval(intervalRef.current);
                        setProgress(0);
                    }
                });
        } catch (error) {
            console.log(error, "getting error...");
        }
    };

    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        {...props}
                        sx={{
                            height: 10, // **Height of the progress bar** (changed)

                            borderRadius: 5, // **Rounded corners** (changed)
                            "& .MuiLinearProgress-bar": {
                                borderRadius: 5, // **Rounded corners of the progress bar fill** (changed)
                            },
                            color: "#fff",
                        }}
                    />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleExpandClick = (templateId) => {
        setExpandedTemplate(expandedTemplate === templateId ? null : templateId);
    };

    const hardcodedValue = { reasons: "isSpammycontentWithoutExternalLink: true" };
    const queryParams = new URLSearchParams(window.location.search);
    let fromDate = queryParams.get("fromDate");
    let toDate = queryParams.get("toDate");
    let domainName = queryParams.get("domainName");

    const generateRandomString = (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const fetchAuditAutomation = async (fromFetchAutomation) => {
        setLoader(true);
        if (fromFetchAutomation) {
            setLoading(true);
        }
        const randomString = generateRandomString(10);
        console.log(randomString, "before storing...");
        setRandomStringForProcess(randomString);
        console.log(randomStringForProcess, "after storing...");

        try {
            console.log("inside fetchAuditAutomation");
            const response = await fetch(`${REACT_APP_SERVER_URL}/auditAutomationProcess`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    fromDate: fromDate,
                    toDate: toDate,
                    domainName: domainName,
                    randomString: randomString,
                }),
            });
            const json = await response.json();
            if (!json.success || !json.data.success) {
                setSnackbarSeverity("error");
                setSnackbarMessage(json.data.message);
                setSnackbarOpen(true);
                navigate("/dashboard/spreadsheet", { state: { message: json.data.message } });
                return;
            }
            const results = json.data.results;

            for (let i = 0; i < results.length; i++) {
                if (results[i].dnsAuditResult) {
                    let arr = [];
                    for (let key in results[i].dnsAuditResult) {
                        arr.push({
                            name: key,
                            status: results[i].dnsAuditResult[key] ? "Passing" : "Failing",
                        });
                    }
                    setDnsRecords(arr);
                } else if (results[i].domainListedZones) {
                    if (results[i].domainListedZones.listed.length > 0) {
                        setDomainTest((prevValue) => [{ name: "Domain Blacklist", status: "Failing" }]);
                    } else {
                        setDomainTest((prevValue) => [{ name: "Domain Blacklist", status: "Passing" }]);
                    }
                    const blackList = results[i].domainListedZones?.listed.map((zone) => ({
                        name: zone.zone,
                        url: zone.url,
                    }));
                    setDomainBlacklist(blackList);
                } else if (results[i].domainSendMailResult) {
                    let label = results[i].domainSendMailResult.label;
                    setDomainTest((prevValue) => [
                        ...prevValue,
                        label === "inbox"
                            ? { name: "Domain Health", status: "Passing" }
                            : { name: "Domain Health", status: "Failing" },
                    ]);
                } else if (results[i].ipListedZones) {
                    if (results[i].ipListedZones.listed.length > 0) {
                        setIpTests([{ name: "IP Blacklist", status: "Failing" }]);
                    } else {
                        setIpTests([{ name: "IP Blacklist", status: "Passing" }]);
                    }
                    const iplistDomains = results[i].ipListedZones?.listed
                        .filter((zone) => ({ name: zone.url }))
                        .map((zone) => ({ name: zone.zone, url: zone.url }));
                    setIpBlacklist(iplistDomains);
                } else if (results[i].templates) {
                    console.log("inside templates");
                    let templateList = [];
                    for (let j = 0; j < results[i].templates.length; j++) {
                        if (results[i].templates[j]) {
                            let arr = [];
                            for (let k = 0; k < results[i].templates[j].reasons; k++) {
                                arr.push({
                                    "Without Externallink": results[i].templates[j].reasons[k]
                                        .isSpammycontentWithoutExternalLink
                                        ? true
                                        : false,
                                    "Without Signature": results[i].templates[j].reasons[k]
                                        .isSpammycontentWithoutSignature
                                        ? true
                                        : false,
                                    "Without Images": results[i].templates[j].reasons[k].isSpammycontentWithoutImages,
                                });
                            }
                            templateList.push({
                                id: i,
                                subject: results[i].templates[j].originalTemplate.subject,
                                originalContent: results[i].templates[j].originalTemplate.content,
                                refinedContent: results[i].templates[j].refinedTemplate.content,
                                name: `Template - ${j + 1}`,
                                reasons: arr,
                            });
                        }
                    }
                    setTemplates(templateList);
                    setSelectedTemplate(templateList[0]);
                } else if (results[i].overAllScore) {
                    setOverallScore(Math.floor(results[i].overAllScore));
                }
            }
            console.log("json", json);
            console.log("dnsrecords inside regenerate", dnsRecords);
        } catch (error) {
            // if (error.response) {
            //     // setSnackbarSeverity("error");
            //     // setSnackbarMessage(json.message);
            //     // setSnackbarOpen(true);
            //     // navigate("/dashboard/spreadsheet");
            //     return;
            // }
            console.error(`\n Error occured while regenerate audit`);
            // throw error;
        } finally {
            setLoader(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchStoreAuditAutomationData = async () => {
            setLoading(true);
            let goingToCreateAuditAutomationData = false;
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_SERVER_URL}/fetchAuditAutomationData?fromDate=${fromDate}&toDate=${toDate}&domainName=${domainName}`,
                    {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        credentials: "include",
                    }
                );
                const json = await response.json();
                if (!json.audit_automation) {
                    goingToCreateAuditAutomationData = true;
                    fetchAuditAutomation(true);
                    return;
                }
                console.log("after if");
                const auditAutomation = json.audit_automation[0];
                let keys = Object.keys(auditAutomation);
                setLastReportDate(moment(auditAutomation.createdAt).format("lll"));
                for (let i = 0; i < keys.length; i++) {
                    if (keys[i] === "spf" || keys[i] === "dkim" || keys[i] === "dmarc") {
                        let obj = {
                            name: keys[i],
                            status: auditAutomation[keys[i]] ? "Passing" : "Failing",
                        };
                        setDnsRecords((prevValue) => [...prevValue, obj]);
                        continue;
                    } else if (keys[i] === "domain_blacklist") {
                        setDomainTest(
                            auditAutomation[keys[i]]?.listed.length > 0
                                ? [{ name: "Domain Blacklist", status: "Failing" }]
                                : [{ name: "Domain Blacklist", status: "Passing" }]
                        );
                        if (auditAutomation[keys[i]]?.listed.length > 0) {
                            const blackList = auditAutomation[keys[i]].listed.map((zone) => ({
                                name: zone.zone,
                                url: zone.url,
                            }));
                            setDomainBlacklist(blackList);
                        }
                        continue;
                    } else if (keys[i] === "domain_send_mail_result") {
                        setDomainTest((prevValue) => [
                            ...prevValue,
                            auditAutomation[keys[i]]?.label === "inbox"
                                ? { name: "Domain Health", status: "Passing" }
                                : { name: "Domain Health", status: "Failing" },
                        ]);
                        continue;
                    } else if (keys[i] === "ip_blacklist") {
                        setIpTests(
                            auditAutomation[keys[i]]?.listed.length > 0
                                ? [{ name: "Domain Blacklist", status: "Failing" }]
                                : [{ name: "Domain Blacklist", status: "Passing" }]
                        );
                        if (auditAutomation[keys[i]]?.listed.length > 0) {
                            const iplistDomains = auditAutomation[keys[i]].listed
                                .filter((zone) => ({ name: zone.url }))
                                .map((zone) => ({ name: zone.zone, url: zone.url }));
                            setIpBlacklist(iplistDomains);
                        }
                        continue;
                    } else if (keys[i] === "scores") {
                        let createdAt = _.pluck(auditAutomation[keys[i]], "createdAt").map((date) => {
                            return moment(date).format("YYYY-MM-DD");
                        });
                        setLibeGraphLabel(createdAt);
                        setLineGraphDataSet(() => [
                            {
                                label: "Domain",
                                data: _.pluck(auditAutomation[keys[i]], "domainScore"),
                                fill: false,
                                borderColor: "rgba(153,102,255,1)",
                                backgroundColor: "rgba(153,102,255,0.4)",
                            },
                            {
                                label: "IP",
                                data: _.pluck(auditAutomation[keys[i]], "ipScore"),
                                fill: false,
                                borderColor: "rgba(75,192,192,1)",
                                backgroundColor: "rgba(75,192,192,0.4)",
                            },
                        ]);
                        continue;
                    } else if (keys[i] === "templates") {
                        let templatesData = auditAutomation[keys[i]];
                        let templateList = [];
                        if (templatesData.length && templatesData.length > 0) {
                            for (let j = 0; j < templatesData.length; j++) {
                                let arr = [];
                                for (let k = 0; k < templatesData[j].reasons.length; k++) {
                                    arr.push({
                                        "Without Externallink": templatesData[j].reasons[k]
                                            .isSpammycontentWithoutExternalLink
                                            ? true
                                            : false,
                                        "Without Signature": templatesData[j].reasons[k].isSpammycontentWithoutSignature
                                            ? true
                                            : false,
                                        "Without Images": templatesData[j].reasons[k].isSpammycontentWithoutImages
                                            ? true
                                            : false,
                                    });
                                }
                                templateList.push({
                                    id: j,
                                    subject: templatesData[j].originalTemplate.subject
                                        ? templatesData[j].originalTemplate.subject
                                        : "Default Subject",
                                    originalContent: templatesData[j].originalTemplate.content,
                                    refinedContent: templatesData[j].refinedTemplate.content,
                                    name: `Template - ${j + 1}`,
                                    reasons: arr,
                                });
                            }
                            setTemplates(templateList);
                            setSelectedTemplate(templateList[0]);
                        }
                    } else if (keys[i] === "overAllScore") {
                        setOverallScore(Math.floor(auditAutomation[keys[i]]));
                    }
                }
                console.log("auditAutomation", auditAutomation);
                console.log("json", json);
            } catch (error) {
                console.error(`\n Error in fetchStoreAuditAutomationData --> ${error}`);
            } finally {
                if (goingToCreateAuditAutomationData) {
                    setLoading(true);
                } else {
                    setLoading(false);
                }
            }
        };

        fetchStoreAuditAutomationData();

        // Simulated fetch of blacklist data from API
        // const fetchBlacklistData = async () => {
        //     setLoading(true);
        //     try {
        //         const response = await fetch(
        //             `${REACT_APP_SERVER_URL}/fetchAuditAutomationData?fromDate=${fromDate}&toDate=${toDate}&domainName=${domainName}`,
        //             {
        //                 headers: {
        //                     Accept: "application/json",
        //                     "Content-Type": "application/json",
        //                 },
        //                 credentials: "include",
        //                 method: "GET",
        //             }
        //         );
        //         const results = await response.json();
        //         console.log(results, "##################");
        //         if (results.status === 200) {
        //         }
        //         for (let j = 0; j < results.length; j++) {
        //             let dnsAuditResults = results[j]?.dnsAuditResult;
        //             ipListedZones = results[j]?.ipListedZones;

        //             if (results[j]?.domainListedZones) {
        //                 domainlistZones = results[j]?.domainListedZones;
        //             }
        //             if (ipListedZones?.listed.length > 0) {
        //                 console.log("inisde if");
        //                 setIpTests([{ name: "IP Blacklist", status: "Failing" }]);
        //             } else if (ipListedZones?.listed.length === 0) {
        //                 setIpTests([{ name: "IP Blacklist", status: "Passing" }]);
        //             }

        //             if (domainlistZones?.listed.length > 0) {
        //                 setDomainTest((prevValue) => [{ name: "Domain Blacklist", status: "Failing" }]);
        //             } else if (domainlistZones?.listed.length === 0) {
        //                 setDomainTest((prevValue) => [{ name: "Domain Blacklist", status: "Passing" }]);
        //             }

        //             // const iplistDomains = ipListedZones?.listed
        //             //     .filter((zone) => ({ name: zone.url }))
        //             //     .map((zone) => ({ name: zone.zone,url:zone.url }));
        //             // setIpBlacklist(iplistDomains);
        //             // console.log(iplistDomains,"!!!!!!!!!!!!!!!!!!!!!!!");

        //             for (let key in dnsAuditResults) {
        //                 console.log("key", key);
        //                 arr.push({
        //                     name: key,
        //                     status: dnsAuditResults[key] ? "Passing" : "Failing",
        //                 });
        //                 console.log("arr", arr);
        //             }
        //         }
        //         const iplistDomains = ipListedZones?.listed
        //             .filter((zone) => ({ name: zone.url }))
        //             .map((zone) => ({ name: zone.zone, url: zone.url }));
        //         setIpBlacklist(iplistDomains);

        //         const blackList = domainlistZones?.listed.map((zone) => ({ name: zone.zone, url: zone.url }));
        //         setDomainBlacklist(blackList);
        //         setDomainTest((prevValue) => [
        //             ...prevValue,
        //             label === "inbox"
        //                 ? { name: "Domain Health", status: "Passing" }
        //                 : { name: "Domain Health", status: "Failing" },
        //         ]);

        //         setDnsRecords(arr);
        //         await new Promise((resolve) => setTimeout(resolve, 1000));
        //         console.log("dnsrecords", dnsRecords);
        //     } catch (error) {
        //         console.error("Error fetching mail audit data:", error);
        //     } finally {
        //     }
        // };

        // fetchBlacklistData();
    }, []);

    const handleRegenerateAudit = () => {
        fetchAuditAutomation();
    };

    // const increaseProgress = () => {
    //     setProgress((prev) => (prev < 100 ? prev + 10 : 0));
    // };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
    };

    const tests = [
        { name: "SPF", status: "Failing" },
        { name: "DKIM", status: "Passing" },
        { name: "DMARC", status: "Failing" },
    ];

    // Determine DNS status based on the statuses of SPF, DKIM, and DMARC
    const dnsStatus = dnsRecords.every((test) => test.status === "Passing") ? "Passing" : "Failing";

    let domainStatus = domainTest.every((test) => test.status === "Passing") ? "Passing" : "Failing";

    const ipStatus = ipTests.every((test) => test.status === "Passing") ? "Passing" : "Failing";

    const toggleAccordion = () => {
        setIsAccordionOpen((prev) => !prev);
    };

    const closeAccordion = () => {
        setIsAccordionOpen(false);
    };

    const handleDelist = (type, name) => {
        // Assuming `name` is the IP address or domain name
        // Redirect to the IP address or domain
        window.location.href = `http://${name}`; // Assuming it's an HTTP URL
        // Alternatively, you can use `https://` for HTTPS URLs
    };

    const pieChartData = {
        labels: ["Passing", "Failing"],
        datasets: [
            {
                data: [2, 1], // Replace with actual data counts
                backgroundColor: ["green", "red"],
                hoverBackgroundColor: ["#4CAF50", "#F44336"],
            },
        ],
    };

    // const data = {
    //     labels: ["Soft Bounce", "Hard Bounce"],
    //     datasets: [
    //         {
    //             data: [10, 15],
    //             backgroundColor: ["#ef9b20", "#9b19f5"],
    //             hoverBackgroundColor: ["white", "white"],
    //             borderWidth: 1,
    //         },
    //     ],
    // };

    const data = {
        labels: ["Soft Bounce", "Hard Bounce"],
        datasets: [
            {
                data: [10, 15],
                backgroundColor: ["#ef9b20", "#9b19f5"],
                hoverBackgroundColor: ["white", "white"],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true, // Hides the default legend
            },
        },
    };

    const data2 = {
        labels: lineGraphLabel,
        datasets: lineGraphDataSet,
    };

    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                // text: "",
            },
        },
    };

    return (
        <Box className="main-box">
            <>
                <Box>
                    <Box>
                        {loading ? (
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: "1rem",
                                    margin: "16px 0",
                                    padding: "10px 10px",
                                    borderRadius: "10px",
                                }}
                            />
                        ) : (
                            <Box className="main_breadcrumbs">
                                <Breadcrumbs aria-label="breadcrumb" separator="››" className="bread-crumbs">
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        href="/dashboard/spreadsheet"
                                        sx={{
                                            color: "primary.main",
                                            textDecoration: "none",
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                    >
                                        BCC Insights
                                    </Link>
                                    <Typography
                                        color="text.primary"
                                        sx={{
                                            color: "text.primary",
                                        }}
                                    >
                                        Audit Report
                                    </Typography>
                                </Breadcrumbs>

                                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                                    <Typography>
                                        Last Report Date : <span>{lastReportDate}</span>
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        className="Regenrate_btn"
                                        onClick={handleRegenerateAudit}
                                    >
                                        {" "}
                                        Regenerate
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
                {loader ? (
                    <>
                        <Box sx={{ backgroundColor: "#F5F5F5", padding: "20px", borderRadius: "10px" }}>
                            <Box>
                                <Typography>Audit Process is undergoing</Typography>
                                <Typography>Please visit again in 2 to 3 minutes</Typography>
                            </Box>
                            <LinearProgressWithLabel value={progress} />
                        </Box>
                        <Box className="container_box">
                            <Grid container spacing={3}>
                                {/* Score Card Section */}
                                <Grid item xs={12} md={3}>
                                    <Paper
                                        sx={{
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: 2,
                                            padding: "20px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        {loading ? (
                                            <>
                                                <Skeleton variant="text" width={200} height={40} />
                                            </>
                                        ) : (
                                            // <div>cuming....</div>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    color: "#24a0ed",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Overall Audit Score
                                            </Typography>
                                        )}
                                        <Box
                                            sx={{
                                                width: "180px",
                                                height: "180px",
                                                position: "relative",
                                            }}
                                        >
                                            {loading ? (
                                                <Skeleton variant="circular" width={180} height={180} />
                                            ) : (
                                                <CircularProgressbar
                                                    value={overallScore}
                                                    styles={buildStyles({
                                                        pathColor: `rgba(54, 162, 235, ${overallScore / 100})`,
                                                        trailColor: "#d6d6d6",
                                                        backgroundColor: "#3e98c7",
                                                        textSize: "16px", // Adjust the text size
                                                    })}
                                                />
                                            )}
                                            {!loading && (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        fontSize: "20px",
                                                        color: "green",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {`${overallScore}%`}
                                                </Box>
                                            )}
                                        </Box>
                                        {/* {!loading && (
                                <Button
                                    variant="contained"
                                    onClick={increaseProgress}
                                    sx={{
                                        marginTop: 2,
                                    }}
                                >
                                    Increase Progress
                                </Button>
                            )} */}
                                    </Paper>
                                </Grid>

                                {/* Test Section */}
                                <Grid item xs={12} md={9}>
                                    <ClickAwayListener onClickAway={closeAccordion}>
                                        <Paper
                                            sx={{
                                                height: "266px", // Set a maximum height
                                                padding: "10px 10px",
                                                border: "1px solid #f1f1f1",
                                                borderRadius: "5px",
                                                backgroundColor: "#fff",
                                                overflowY: "auto", // Add scroll bar on the Paper
                                                boxShadow: "none !important",
                                            }}
                                        >
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={6}>
                                                    {loading ? (
                                                        <>
                                                            <Skeleton variant="text" width={100} height={30} />
                                                        </>
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                color: "#24a0ed",
                                                                display: "flex",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Test
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {loading ? (
                                                        <Skeleton variant="text" width={100} height={30} />
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                color: "#24a0ed",
                                                                display: "flex",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Severity
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {loading ? (
                                                <Box>
                                                    <Skeleton variant="rectangular" width="100%" height={112} />
                                                    <Skeleton variant="rectangular" width="100%" height={112} />
                                                </Box>
                                            ) : (
                                                <>
                                                    <Accordion expanded={isAccordionOpen} onChange={toggleAccordion}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            sx={{ backgroundColor: "#fafbff" }}
                                                        >
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={6}>
                                                                    <Typography className="DNS-text">DNS</Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    {dnsStatus === "Passing" ? (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: "4px",
                                                                            }}
                                                                        >
                                                                            <CheckCircleIcon sx={{ color: "green" }} />
                                                                            <Typography color="green">
                                                                                Passing
                                                                            </Typography>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: "4px",
                                                                            }}
                                                                        >
                                                                            <ErrorIcon sx={{ color: "red" }} />
                                                                            <Typography color="red">Failing</Typography>
                                                                        </div>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ flexDirection: "column" }}>
                                                            {dnsRecords.map((test, index) => (
                                                                <Accordion key={index}>
                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                                        <Grid container spacing={2} alignItems="center">
                                                                            <Grid item xs={6}>
                                                                                <Typography>
                                                                                    {test.name.toUpperCase()}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                {test.status === "Passing" ? (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            gap: "4px",
                                                                                        }}
                                                                                    >
                                                                                        <CheckCircleIcon
                                                                                            sx={{ color: "green" }}
                                                                                        />
                                                                                        <Typography color="green">
                                                                                            Passing
                                                                                        </Typography>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            gap: "4px",
                                                                                        }}
                                                                                    >
                                                                                        <ErrorIcon
                                                                                            sx={{ color: "red" }}
                                                                                        />
                                                                                        <Typography color="red">
                                                                                            Failing
                                                                                        </Typography>
                                                                                    </div>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                    {/* <AccordionDetails>
                                                        {/* <Typography>Details about {test.name} test...</Typography>
                                                    </AccordionDetails> */}
                                                                </Accordion>
                                                            ))}
                                                        </AccordionDetails>
                                                    </Accordion>

                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            sx={{ backgroundColor: "#fafbff" }}
                                                        >
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={6}>
                                                                    <Typography>Domain Reputation</Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    {domainStatus === "Passing" ? (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: 4,
                                                                            }}
                                                                        >
                                                                            <CheckCircleIcon sx={{ color: "green" }} />
                                                                            <Typography color="green">
                                                                                Passing
                                                                            </Typography>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: 4,
                                                                            }}
                                                                        >
                                                                            <ErrorIcon sx={{ color: "red" }} />
                                                                            <Typography color="red">Failing</Typography>
                                                                        </div>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ flexDirection: "column" }}>
                                                            {domainTest.map((test, index) => (
                                                                <Accordion key={index}>
                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                                        <Grid container spacing={2} alignItems="center">
                                                                            <Grid item xs={6}>
                                                                                <Typography>{test.name}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                {test.status === "Passing" ? (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            gap: 4,
                                                                                        }}
                                                                                    >
                                                                                        <CheckCircleIcon
                                                                                            sx={{ color: "green" }}
                                                                                        />
                                                                                        <Typography color="green">
                                                                                            Passing
                                                                                        </Typography>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            gap: 4,
                                                                                        }}
                                                                                    >
                                                                                        <ErrorIcon
                                                                                            sx={{ color: "red" }}
                                                                                        />
                                                                                        <Typography color="red">
                                                                                            Failing
                                                                                        </Typography>
                                                                                    </div>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                    {/* <AccordionDetails>
                                                        <Typography>Details about {test.name}...</Typography>
                                                    </AccordionDetails> */}
                                                                </Accordion>
                                                            ))}
                                                        </AccordionDetails>
                                                    </Accordion>

                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            sx={{ backgroundColor: "#fafbff" }}
                                                        >
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={6}>
                                                                    <Typography>IP Reputation</Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    {ipStatus === "Passing" ? (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: 4,
                                                                            }}
                                                                        >
                                                                            <CheckCircleIcon sx={{ color: "green" }} />
                                                                            <Typography color="green">
                                                                                Passing
                                                                            </Typography>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: 4,
                                                                            }}
                                                                        >
                                                                            <ErrorIcon sx={{ color: "red" }} />
                                                                            <Typography color="red">Failing</Typography>
                                                                        </div>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ flexDirection: "column" }}>
                                                            {ipTests.map((test, index) => (
                                                                <Accordion key={index}>
                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                                        <Grid container spacing={2} alignItems="center">
                                                                            <Grid item xs={6}>
                                                                                <Typography>{test.name}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                {test.status === "Passing" ? (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            gap: 4,
                                                                                        }}
                                                                                    >
                                                                                        <CheckCircleIcon
                                                                                            sx={{ color: "green" }}
                                                                                        />
                                                                                        <Typography color="green">
                                                                                            Passing
                                                                                        </Typography>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            gap: 4,
                                                                                        }}
                                                                                    >
                                                                                        <ErrorIcon
                                                                                            sx={{ color: "red" }}
                                                                                        />
                                                                                        <Typography color="red">
                                                                                            Failing
                                                                                        </Typography>
                                                                                    </div>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography>
                                                                            Details about {test.name}...
                                                                        </Typography>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            ))}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </>
                                            )}
                                        </Paper>
                                    </ClickAwayListener>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Paper
                                        sx={{
                                            height: "480px",
                                            padding: "20px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            overflowY: templates.length > 10 ? "auto" : "hidden", // Scrollable if more than 10 items
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        {loading ? (
                                            <Skeleton variant="text" width={200} height={40} />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    color: "#24a0ed",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                TEMPLATES
                                            </Typography>
                                        )}
                                        {loading ? (
                                            <List>
                                                {[...Array(5)].map((_, index) => (
                                                    <ListItem key={index}>
                                                        <Skeleton variant="text" width="100%" height={60} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : (
                                            <List>
                                                {templates.map((template) => (
                                                    <div key={template.id}>
                                                        <ListItem
                                                            button
                                                            selected={selectedTemplate?.id === template.id}
                                                            onClick={() => handleTemplateSelect(template)}
                                                            sx={{
                                                                border: "1px solid #ddd",
                                                                borderRadius: "5px",
                                                                backgroundColor: "white", // Set the background color for all items
                                                                "&:hover": {
                                                                    backgroundColor: "#e0f2f1", // Hover effect
                                                                },
                                                                marginBottom: "2px",
                                                                textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={template.subject.slice(0, 20).concat("....")}
                                                                sx={{ textAlign: "center" }}
                                                            />
                                                            <IconButton onClick={() => handleExpandClick(template.id)}>
                                                                <ExpandMoreIcon />
                                                            </IconButton>
                                                        </ListItem>
                                                        {expandedTemplate === template.id && (
                                                            <div>
                                                                {template.reasons.map((error, index) => {
                                                                    // const isTrue = error.reasons.includes("true");
                                                                    return Object.keys(error).map((key) => {
                                                                        const isTrue =
                                                                            error[key] === true ? true : false;
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyItems: "center",
                                                                                    justifyContent: "space-between",
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    sx={{
                                                                                        marginLeft: "10px",
                                                                                    }}
                                                                                >
                                                                                    {key}
                                                                                </Typography>
                                                                                <div>
                                                                                    {isTrue ? (
                                                                                        <ReportProblemIcon
                                                                                            style={{
                                                                                                color: "red",
                                                                                                height: "20px",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <CheckBoxIcon
                                                                                            style={{
                                                                                                color: "green",
                                                                                                height: "20px",
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    });
                                                                })}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </List>
                                        )}
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={9}>
                                    <Paper
                                        sx={{
                                            height: "480px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#E9EAEC",
                                            overflowY: "auto",
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        {loading ? (
                                            <Skeleton
                                                variant="text"
                                                width={200}
                                                height={40}
                                                sx={{ alignItems: "center" }}
                                            />
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    backgroundColor: "#24a0ed",
                                                    padding: "15px",
                                                }}
                                            >
                                                <div style={circleStyle("#4CBB17")}></div>
                                                <div style={circleStyle1("#F6FB7A")}></div>
                                                <div style={circleStyle2("#B4E380")}></div>
                                            </Box>
                                        )}
                                        {loading ? (
                                            <Box>
                                                <Skeleton variant="text" width="100%" height={60} />
                                                <Skeleton
                                                    variant="rectangular"
                                                    width="100%"
                                                    height={300}
                                                    sx={{ marginBottom: 2 }}
                                                />
                                            </Box>
                                        ) : (
                                            <Box>
                                                <Box sx={{ padding: "8px" }}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            borderRadius: "5px",
                                                            padding: "5px",
                                                            marginBottom: "20px",
                                                            backgroundColor: "white",
                                                            alignItems: "center",
                                                            height: "40px",
                                                            gap: 2,
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                margin: "16px 0",
                                                                padding: "10px 10px",
                                                                borderRadius: "10px",
                                                                width: "80%",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {selectedTemplate.subject}
                                                        </Typography>

                                                        <ContentCopyIcon
                                                            onClick={handleCopySubject}
                                                            sx={{ marginLeft: "auto" }}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box sx={{ padding: "10px", marginTop: "-25px" }}>
                                                    <Box
                                                        sx={{
                                                            borderRadius: "5px",
                                                            padding: "10px",
                                                            backgroundColor: "white",
                                                            alignItems: "center",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        <div
                                                            style={{ height: "300px", overflowY: "auto" }}
                                                            // contentEditable="true"
                                                            dangerouslySetInnerHTML={{
                                                                __html: selectedTemplate.originalContent,
                                                            }}
                                                        ></div>
                                                        <ContentCopyIcon
                                                            onClick={handleCopyBody}
                                                            sx={{ marginLeft: "auto" }}
                                                        />

                                                        <Button
                                                            variant="contained"
                                                            className="body-btn"
                                                            onClick={handleClickOpen}
                                                        >
                                                            Refined Template
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    </Paper>
                                </Grid>

                                {/* Domain Blacklist Section */}
                                <Grid item xs={12} md={6}>
                                    <Paper
                                        sx={{
                                            padding: "20px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            height: "300px", // Set max height for scrollable section
                                            overflowY: "auto", // Enable vertical scrolling
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        {loading ? (
                                            <Skeleton variant="text" width={200} height={40} />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    color: "#24a0ed",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Domain Blacklist
                                            </Typography>
                                        )}

                                        {loading ? (
                                            <List>
                                                {[...Array(3)].map((_, index) => (
                                                    <ListItem key={index}>
                                                        <Skeleton variant="text" width="100%" height={80} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : (
                                            <List>
                                                {domainBlacklist.length === 0 ? (
                                                    <ListItem>
                                                        <ListItemText
                                                            primary="No data found"
                                                            sx={{ textAlign: "center" }}
                                                        />
                                                    </ListItem>
                                                ) : (
                                                    domainBlacklist.map((entry, index) => (
                                                        <ListItem
                                                            key={index}
                                                            button
                                                            onClick={() => handleDelist("Domain", entry.url)}
                                                            sx={{
                                                                border: "1px solid #ddd",
                                                                borderRadius: "5px",
                                                                marginBottom: "2px",
                                                                "&:hover": {
                                                                    backgroundColor: "#e0f2f1", // Hover effect
                                                                },
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={entry.name}
                                                                sx={{ textAlign: "center" }}
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => handleDelist("IP", entry.url)}
                                                            >
                                                                Delist
                                                            </Button>
                                                        </ListItem>
                                                    ))
                                                )}
                                            </List>
                                        )}
                                    </Paper>
                                </Grid>

                                {/* IP Blacklist Section */}
                                <Grid item xs={12} md={6}>
                                    <Paper
                                        sx={{
                                            padding: "20px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            height: "300px", // Set max height for scrollable section
                                            overflowY: "auto",
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        {loading ? (
                                            <Skeleton variant="text" width={200} height={40} />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    color: "#24a0ed",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                IP Blacklist
                                            </Typography>
                                        )}
                                        {loading ? (
                                            <List>
                                                {[...Array(3)].map((_, index) => (
                                                    <ListItem key={index}>
                                                        <Skeleton variant="text" width="100%" height={80} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : (
                                            <List>
                                                {ipBlacklist.length === 0 ? (
                                                    <ListItem>
                                                        <ListItemText
                                                            primary="No data found"
                                                            sx={{ textAlign: "center" }}
                                                        />
                                                    </ListItem>
                                                ) : (
                                                    ipBlacklist.map((entry, index) => (
                                                        <ListItem
                                                            key={index}
                                                            button
                                                            onClick={() => handleDelist("IP", entry.url)}
                                                            sx={{
                                                                border: "1px solid #ddd",
                                                                borderRadius: "5px",
                                                                marginBottom: "2px",
                                                                "&:hover": {
                                                                    backgroundColor: "#e0f2f1", // Hover effect
                                                                },
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={entry.name}
                                                                sx={{ textAlign: "center" }}
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => handleDelist("IP", entry.url)}
                                                            >
                                                                Delist
                                                            </Button>
                                                        </ListItem>
                                                    ))
                                                )}
                                            </List>
                                        )}
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Paper
                                        sx={{
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: 2,
                                            padding: "10px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            position: "relative", // Add relative positioning for containing absolute elements
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            background: "cover",
                                            boxShadow: "none !important",
                                            filter: "blur(10px)",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#24a0ed",
                                                marginBottom: "10px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                                fontWeight: "500",
                                                boxShadow: "none !important",
                                            }}
                                        >
                                            Pie Chart
                                        </Typography>
                                        {loading ? (
                                            <Skeleton variant="circular" width={260} height={300} />
                                        ) : (
                                            <Box className="PieChartBox">
                                                <Pie data={data} options={options} width={300} height={300} />
                                            </Box>
                                        )}
                                    </Paper>

                                    <div class="bg-text">
                                        <div className="text-bottom">
                                            <h4 style={{ color: "black" }}>Available on app integration.</h4>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Paper
                                        sx={{
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: 2,
                                            padding: "20px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            position: "relative",
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#24a0ed",
                                                marginBottom: "10px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                                fontWeight: "500",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Line Graph
                                        </Typography>
                                        {loading ? (
                                            <Skeleton variant="rectangular" width="100%" height={300} />
                                        ) : (
                                            <Box className="line-chartBox">
                                                <Line data={data2} options={options2} width={200} height={107} />
                                            </Box>
                                        )}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>{" "}
                    </>
                ) : (
                    <Box className="container_box">
                        <Grid container spacing={3}>
                            {/* Score Card Section */}
                            <Grid item xs={12} md={3}>
                                <Paper
                                    sx={{
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 2,
                                        padding: "20px",
                                        border: "1px solid #f1f1f1",
                                        borderRadius: "5px",
                                        backgroundColor: "#fff",
                                        boxShadow: "none !important",
                                    }}
                                >
                                    {loading ? (
                                        <>
                                            <Skeleton variant="text" width={200} height={40} />
                                        </>
                                    ) : (
                                        // <div>cuming....</div>
                                        <Typography
                                            gutterBottom
                                            sx={{
                                                color: "#24a0ed",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Overall Audit Score
                                        </Typography>
                                    )}
                                    <Box
                                        sx={{
                                            width: "180px",
                                            height: "180px",
                                            position: "relative",
                                        }}
                                    >
                                        {loading ? (
                                            <Skeleton variant="circular" width={180} height={180} />
                                        ) : (
                                            <CircularProgressbar
                                                value={overallScore}
                                                styles={buildStyles({
                                                    pathColor: `rgba(54, 162, 235, ${overallScore / 100})`,
                                                    trailColor: "#d6d6d6",
                                                    backgroundColor: "#3e98c7",
                                                    textSize: "16px", // Adjust the text size
                                                })}
                                            />
                                        )}
                                        {!loading && (
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    fontSize: "20px",
                                                    color: "green",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {`${overallScore}%`}
                                            </Box>
                                        )}
                                    </Box>
                                    {/* {!loading && (
                                <Button
                                    variant="contained"
                                    onClick={increaseProgress}
                                    sx={{
                                        marginTop: 2,
                                    }}
                                >
                                    Increase Progress
                                </Button>
                            )} */}
                                </Paper>
                            </Grid>

                            {/* Test Section */}
                            <Grid item xs={12} md={9}>
                                <ClickAwayListener onClickAway={closeAccordion}>
                                    <Paper
                                        sx={{
                                            height: "266px", // Set a maximum height
                                            padding: "10px 10px",
                                            border: "1px solid #f1f1f1",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            overflowY: "auto", // Add scroll bar on the Paper
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                {loading ? (
                                                    <>
                                                        <Skeleton variant="text" width={100} height={30} />
                                                    </>
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            color: "#24a0ed",
                                                            display: "flex",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Test
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {loading ? (
                                                    <Skeleton variant="text" width={100} height={30} />
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            color: "#24a0ed",
                                                            display: "flex",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Severity
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {loading ? (
                                            <Box>
                                                <Skeleton variant="rectangular" width="100%" height={112} />
                                                <Skeleton variant="rectangular" width="100%" height={112} />
                                            </Box>
                                        ) : (
                                            <>
                                                <Accordion expanded={isAccordionOpen} onChange={toggleAccordion}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        sx={{ backgroundColor: "#fafbff" }}
                                                    >
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={6}>
                                                                <Typography className="DNS-text">DNS</Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                {dnsStatus === "Passing" ? (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "4px",
                                                                        }}
                                                                    >
                                                                        <CheckCircleIcon sx={{ color: "green" }} />
                                                                        <Typography color="green">Passing</Typography>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "4px",
                                                                        }}
                                                                    >
                                                                        <ErrorIcon sx={{ color: "red" }} />
                                                                        <Typography color="red">Failing</Typography>
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={{ flexDirection: "column" }}>
                                                        {dnsRecords.map((test, index) => (
                                                            <Accordion key={index}>
                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={6}>
                                                                            <Typography>
                                                                                {test.name.toUpperCase()}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            {test.status === "Passing" ? (
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        gap: "4px",
                                                                                    }}
                                                                                >
                                                                                    <CheckCircleIcon
                                                                                        sx={{ color: "green" }}
                                                                                    />
                                                                                    <Typography color="green">
                                                                                        Passing
                                                                                    </Typography>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        gap: "4px",
                                                                                    }}
                                                                                >
                                                                                    <ErrorIcon sx={{ color: "red" }} />
                                                                                    <Typography color="red">
                                                                                        Failing
                                                                                    </Typography>
                                                                                </div>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionSummary>
                                                                {/* <AccordionDetails>
                                                        {/* <Typography>Details about {test.name} test...</Typography>
                                                    </AccordionDetails> */}
                                                            </Accordion>
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        sx={{ backgroundColor: "#fafbff" }}
                                                    >
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={6}>
                                                                <Typography>Domain Reputation</Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                {domainStatus === "Passing" ? (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: 4,
                                                                        }}
                                                                    >
                                                                        <CheckCircleIcon sx={{ color: "green" }} />
                                                                        <Typography color="green">Passing</Typography>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: 4,
                                                                        }}
                                                                    >
                                                                        <ErrorIcon sx={{ color: "red" }} />
                                                                        <Typography color="red">Failing</Typography>
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={{ flexDirection: "column" }}>
                                                        {domainTest.map((test, index) => (
                                                            <Accordion key={index}>
                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={6}>
                                                                            <Typography>{test.name}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            {test.status === "Passing" ? (
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        gap: 4,
                                                                                    }}
                                                                                >
                                                                                    <CheckCircleIcon
                                                                                        sx={{ color: "green" }}
                                                                                    />
                                                                                    <Typography color="green">
                                                                                        Passing
                                                                                    </Typography>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        gap: 4,
                                                                                    }}
                                                                                >
                                                                                    <ErrorIcon sx={{ color: "red" }} />
                                                                                    <Typography color="red">
                                                                                        Failing
                                                                                    </Typography>
                                                                                </div>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionSummary>
                                                                {/* <AccordionDetails>
                                                        <Typography>Details about {test.name}...</Typography>
                                                    </AccordionDetails> */}
                                                            </Accordion>
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        sx={{ backgroundColor: "#fafbff" }}
                                                    >
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={6}>
                                                                <Typography>IP Reputation</Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                {ipStatus === "Passing" ? (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: 4,
                                                                        }}
                                                                    >
                                                                        <CheckCircleIcon sx={{ color: "green" }} />
                                                                        <Typography color="green">Passing</Typography>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: 4,
                                                                        }}
                                                                    >
                                                                        <ErrorIcon sx={{ color: "red" }} />
                                                                        <Typography color="red">Failing</Typography>
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={{ flexDirection: "column" }}>
                                                        {ipTests.map((test, index) => (
                                                            <Accordion key={index}>
                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item xs={6}>
                                                                            <Typography>{test.name}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            {test.status === "Passing" ? (
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        gap: 4,
                                                                                    }}
                                                                                >
                                                                                    <CheckCircleIcon
                                                                                        sx={{ color: "green" }}
                                                                                    />
                                                                                    <Typography color="green">
                                                                                        Passing
                                                                                    </Typography>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        gap: 4,
                                                                                    }}
                                                                                >
                                                                                    <ErrorIcon sx={{ color: "red" }} />
                                                                                    <Typography color="red">
                                                                                        Failing
                                                                                    </Typography>
                                                                                </div>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography>
                                                                        Details about {test.name}...
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </>
                                        )}
                                    </Paper>
                                </ClickAwayListener>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Paper
                                    sx={{
                                        height: "480px",
                                        padding: "20px",
                                        border: "1px solid #f1f1f1",
                                        borderRadius: "5px",
                                        backgroundColor: "#fff",
                                        overflowY: templates.length > 10 ? "auto" : "hidden", // Scrollable if more than 10 items
                                        boxShadow: "none !important",
                                    }}
                                >
                                    {loading ? (
                                        <Skeleton variant="text" width={200} height={40} />
                                    ) : (
                                        <Typography
                                            sx={{
                                                color: "#24a0ed",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            TEMPLATES
                                        </Typography>
                                    )}
                                    {loading ? (
                                        <List>
                                            {[...Array(5)].map((_, index) => (
                                                <ListItem key={index}>
                                                    <Skeleton variant="text" width="100%" height={60} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        <List>
                                            {templates.map((template) => (
                                                <div key={template.id}>
                                                    <ListItem
                                                        button
                                                        selected={selectedTemplate?.id === template.id}
                                                        onClick={() => handleTemplateSelect(template)}
                                                        sx={{
                                                            border: "1px solid #ddd",
                                                            borderRadius: "5px",
                                                            backgroundColor: "white", // Set the background color for all items
                                                            "&:hover": {
                                                                backgroundColor: "#e0f2f1", // Hover effect
                                                            },
                                                            marginBottom: "2px",
                                                            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={template.subject.slice(0, 20).concat("....")}
                                                            sx={{ textAlign: "center" }}
                                                        />
                                                        <IconButton onClick={() => handleExpandClick(template.id)}>
                                                            <ExpandMoreIcon />
                                                        </IconButton>
                                                    </ListItem>
                                                    {expandedTemplate === template.id && (
                                                        <div
                                                            style={{
                                                                backgroundColor: "#FBFBFB",
                                                                borderRadius: "5px",
                                                                padding: "5px",
                                                                marginBottom: "5px",
                                                            }}
                                                        >
                                                            {template.reasons.map((error, index) => {
                                                                // const isTrue = error.reasons.includes("true");
                                                                return Object.keys(error).map((key) => {
                                                                    const isTrue = error[key] === true ? true : false;
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyItems: "center",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    marginLeft: "10px",
                                                                                }}
                                                                            >
                                                                                {key}
                                                                            </Typography>
                                                                            <div>
                                                                                {isTrue ? (
                                                                                    <ReportProblemIcon
                                                                                        style={{
                                                                                            color: "red",
                                                                                            height: "20px",
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <CheckBoxIcon
                                                                                        style={{
                                                                                            color: "green",
                                                                                            height: "20px",
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                });
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </List>
                                    )}
                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={9}>
                                <Paper
                                    sx={{
                                        height: "480px",
                                        border: "1px solid #f1f1f1",
                                        borderRadius: "5px",
                                        backgroundColor: "#E9EAEC",
                                        overflowY: "auto",
                                        boxShadow: "none !important",
                                    }}
                                >
                                    {loading ? (
                                        <Skeleton
                                            variant="text"
                                            width={200}
                                            height={40}
                                            sx={{ alignItems: "center" }}
                                        />
                                    ) : (
                                        <Box sx={{ display: "flex", backgroundColor: "#24a0ed", padding: "15px" }}>
                                            <div style={circleStyle("#4CBB17")}></div>
                                            <div style={circleStyle1("#F6FB7A")}></div>
                                            <div style={circleStyle2("#B4E380")}></div>
                                        </Box>
                                    )}
                                    {loading ? (
                                        <Box>
                                            <Skeleton variant="text" width="100%" height={60} />
                                            <Skeleton
                                                variant="rectangular"
                                                width="100%"
                                                height={300}
                                                sx={{ marginBottom: 2 }}
                                            />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Box sx={{ padding: "8px" }}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        borderRadius: "5px",
                                                        padding: "5px",
                                                        marginBottom: "20px",
                                                        backgroundColor: "white",
                                                        alignItems: "center",
                                                        height: "40px",
                                                        gap: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{
                                                            fontWeight: "bold",
                                                            color: "#24a0ed",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        Subject:
                                                    </Typography>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: selectedTemplate.subject
                                                                ? selectedTemplate.subject
                                                                : "Default Subject",
                                                        }}
                                                        value={
                                                            selectedTemplate.subject
                                                                ? selectedTemplate.subject
                                                                : "Default Subject"
                                                        }
                                                    />
                                                    <ContentCopyIcon
                                                        onClick={handleCopySubject}
                                                        sx={{ marginLeft: "auto" }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={{ padding: "10px", marginTop: "-25px" }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: "5px",
                                                        padding: "10px",
                                                        backgroundColor: "white",
                                                        alignItems: "center",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    <div
                                                        style={{ height: "300px", overflowY: "auto" }}
                                                        // contentEditable="true"
                                                        dangerouslySetInnerHTML={{
                                                            __html: selectedTemplate.originalContent,
                                                        }}
                                                    ></div>
                                                    <ContentCopyIcon
                                                        onClick={handleCopyBody}
                                                        sx={{ marginLeft: "auto" }}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        className="body-btn"
                                                        onClick={handleClickOpen}
                                                    >
                                                        Refined Template
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Paper>
                            </Grid>

                            {/* Domain Blacklist Section */}
                            <Grid item xs={12} md={6}>
                                <Paper
                                    sx={{
                                        padding: "20px",
                                        border: "1px solid #f1f1f1",
                                        borderRadius: "5px",
                                        backgroundColor: "#fff",
                                        height: "300px", // Set max height for scrollable section
                                        overflowY: "auto", // Enable vertical scrolling
                                        boxShadow: "none !important",
                                    }}
                                >
                                    {loading ? (
                                        <Skeleton variant="text" width={200} height={40} />
                                    ) : (
                                        <Typography
                                            sx={{
                                                color: "#24a0ed",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Domain Blacklist
                                        </Typography>
                                    )}

                                    {loading ? (
                                        <List>
                                            {[...Array(3)].map((_, index) => (
                                                <ListItem key={index}>
                                                    <Skeleton variant="text" width="100%" height={80} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        <List>
                                            {domainBlacklist.length === 0 ? (
                                                <ListItem>
                                                    <ListItemText
                                                        primary="No data found"
                                                        sx={{ textAlign: "center" }}
                                                    />
                                                </ListItem>
                                            ) : (
                                                domainBlacklist.map((entry, index) => (
                                                    <ListItem
                                                        key={index}
                                                        button
                                                        onClick={() => handleDelist("Domain", entry.url)}
                                                        sx={{
                                                            border: "1px solid #ddd",
                                                            borderRadius: "5px",
                                                            marginBottom: "2px",
                                                            "&:hover": {
                                                                backgroundColor: "#e0f2f1", // Hover effect
                                                            },
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={entry.name}
                                                            sx={{ textAlign: "center" }}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => handleDelist("IP", entry.url)}
                                                        >
                                                            Delist
                                                        </Button>
                                                    </ListItem>
                                                ))
                                            )}
                                        </List>
                                    )}
                                </Paper>
                            </Grid>

                            {/* IP Blacklist Section */}
                            <Grid item xs={12} md={6}>
                                <Paper
                                    sx={{
                                        padding: "20px",
                                        border: "1px solid #f1f1f1",
                                        borderRadius: "5px",
                                        backgroundColor: "#fff",
                                        height: "300px", // Set max height for scrollable section
                                        overflowY: "auto",
                                        boxShadow: "none !important",
                                    }}
                                >
                                    {loading ? (
                                        <Skeleton variant="text" width={200} height={40} />
                                    ) : (
                                        <Typography
                                            sx={{
                                                color: "#24a0ed",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            IP Blacklist
                                        </Typography>
                                    )}
                                    {loading ? (
                                        <List>
                                            {[...Array(3)].map((_, index) => (
                                                <ListItem key={index}>
                                                    <Skeleton variant="text" width="100%" height={80} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        <List>
                                            {ipBlacklist.length === 0 ? (
                                                <ListItem>
                                                    <ListItemText
                                                        primary="No data found"
                                                        sx={{ textAlign: "center" }}
                                                    />
                                                </ListItem>
                                            ) : (
                                                ipBlacklist.map((entry, index) => (
                                                    <ListItem
                                                        key={index}
                                                        button
                                                        onClick={() => handleDelist("IP", entry.url)}
                                                        sx={{
                                                            border: "1px solid #ddd",
                                                            borderRadius: "5px",
                                                            marginBottom: "2px",
                                                            "&:hover": {
                                                                backgroundColor: "#e0f2f1", // Hover effect
                                                            },
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={entry.name}
                                                            sx={{ textAlign: "center" }}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => handleDelist("IP", entry.url)}
                                                        >
                                                            Delist
                                                        </Button>
                                                    </ListItem>
                                                ))
                                            )}
                                        </List>
                                    )}
                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Paper
                                    sx={{
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 2,
                                        padding: "10px",
                                        border: "1px solid #f1f1f1",
                                        borderRadius: "5px",
                                        backgroundColor: "#fff",
                                        position: "relative", // Add relative positioning for containing absolute elements
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        background: "cover",
                                        boxShadow: "none !important",
                                        filter: "blur(10px)",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#24a0ed",
                                            marginBottom: "10px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                            fontWeight: "500",
                                            boxShadow: "none !important",
                                        }}
                                    >
                                        Pie Chart
                                    </Typography>
                                    {loading ? (
                                        <Skeleton variant="circular" width={260} height={300} />
                                    ) : (
                                        <Box className="PieChartBox">
                                            <Pie data={data} options={options} width={300} height={300} />
                                        </Box>
                                    )}
                                </Paper>

                                <div class="bg-text">
                                    <div className="text-bottom">
                                        <h4 style={{ color: "black" }}>Available on app integration.</h4>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Paper
                                    sx={{
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 2,
                                        padding: "20px",
                                        border: "1px solid #f1f1f1",
                                        borderRadius: "5px",
                                        backgroundColor: "#fff",
                                        position: "relative",
                                        boxShadow: "none !important",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#24a0ed",
                                            marginBottom: "10px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                            fontWeight: "500",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Line Graph
                                    </Typography>
                                    {loading ? (
                                        <Skeleton variant="rectangular" width="100%" height={300} />
                                    ) : (
                                        <Box className="line-chartBox">
                                            <Line data={data2} options={options2} width={200} height={107} />
                                        </Box>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </>

            <>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    sx={{
                        "& .MuiDialog-paper": {
                            width: "600px",
                            height: "600px",
                        },
                    }}
                >
                    <DialogTitle>
                        <Box className="dialog-titlebox">
                            <Typography className="dialog-typo">TEMPLATES</Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Box className="content-subjectbox">
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#24a0ed",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    Subject:
                                </Typography>
                                <div
                                    style={{ marginLeft: "5px" }}
                                    dangerouslySetInnerHTML={{ __html: selectedTemplate.subject }}
                                />
                                <ContentCopyIcon onClick={handleCopySubject} sx={{ marginLeft: "auto" }} />
                            </Box>
                        </DialogContentText>
                        <DialogContentText>
                            <Box className="content-bodybox">
                                <div dangerouslySetInnerHTML={{ __html: selectedTemplate.refinedContent }}></div>
                                <ContentCopyIcon onClick={handlerefinedBody} sx={{ marginLeft: "auto" }} />
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="dialog-btn" variant="contained" color="success" onClick={handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
            <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default AuditReport;
