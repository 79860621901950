import React, { useState, useEffect } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import * as XLSX from "xlsx";
import {
    CardContent,
    Card,
    Button,
    Typography,
    TextField,
    Snackbar,
    Alert,
    CircularProgress,
    Box,
    Grid,
} from "@mui/material";
import Papa from "papaparse";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const FindEsp = () => {
    const [fileUpload, setFileUpload] = useState(null);
    // const [parsedData, setParsedData] = useState([]);
    const [espRecords, setEspRecords] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMsg, setsuccessMsg] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [ipDomain, setIpDomain] = useState("");
    const [checkbox, setCheckbox] = useState(false);
    const [storedomain, setStoredomain] = useState([]);
    const [showcheck, setShowcheck] = useState(true);
    const [displayedRecords, setDisplayedRecords] = useState([]);
    const [fileName, setFileName] = useState("");
    const [code, setCode] = useState("");
    const [progress, setProgress] = useState(1);
    const [allData, setAllData] = useState([]);
    const [showDomainError, setDomainError] = useState(false);
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        setDisplayedRecords(espRecords.slice(0, 10));
    }, [espRecords]);

    function handleFileUpload(e) {
        console.log(e.target.files[0]);
        setFileUpload(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setSuccessSnackbarOpen(true);
        setsuccessMsg("File loaded");
    }

    function CsvReader() {
        if (fileUpload === null) {
            setErrorSnackbarOpen(true);
            setErrorMessage("First upload the file");
            return;
        }

        Papa.parse(fileUpload, {
            header: true,
            skipEmptyLines: true,
            complete: function async(results) {
                console.log(results.data, "results of data printing");
                // setParsedData(results.data);
                const columnKey = Object.keys(results.data[0]).map((key) => key.toLowerCase());
                if (columnKey.includes("domain") || columnKey.includes("email")) {
                    GetEsp(results.data);
                    setShowcheck(false);
                    setCheckbox(true);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Unmatched File Content");
                }
            },
        });
    }
    // const StyledTableCell = styled(TableCell)(({ theme }) => ({
    //     [`&.${tableCellClasses.head}`]: {
    //         backgroundColor: theme.palette.primary.main,
    //         color: theme.palette.primary.contrastText,
    //         fontSize: "1rem",
    //         fontWeight: "normal",
    //     },
    //     [`&.${tableCellClasses.body}`]: {
    //         fontSize: "0.875rem",
    //     },
    // }));
    // const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //     "&:last-child td, &:last-child th": {
    //         border: 0,
    //     },
    // }));

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };

    async function ranString() {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "esp-";
        const charactersLength = characters.length;
        for (let i = 0; i < 12; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    async function GetEsp(d) {
        let parse = d;
        const gettinCode = await ranString();
        setCode(gettinCode);
        setLoader(true);
        setProgress(1);
        try {
            pollForStatus(gettinCode);
            fetch(`${REACT_APP_SERVER_URL}/GetEsp`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    domains: parse,
                    fileName: fileName,
                    uniqueCode: gettinCode,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data, "data getting");
                    setLoader(false);
                    if (data.status === 200) {
                        setAllData(data.data);
                        setEspRecords(data.data.successfulRecords);
                        setSuccessSnackbarOpen(true);
                        setsuccessMsg("Result Fetched Successfully");
                    } else {
                        setErrorSnackbarOpen(true);
                        setErrorMessage(data.message);
                    }
                });
        } catch (err) {
            console.log(err, "error");
        }
    }

    function pollForStatus(uniqueCode) {
        const intervalId = setInterval(async () => {
            try {
                const response = await fetch(`${REACT_APP_SERVER_URL}/CheckEspStatus`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify({ uniqueCode }),
                });

                const statusData = await response.json();
                console.log(statusData, "Polling status data");

                setProgress(statusData.data.processStatus);
                if (statusData.data.processStatus === 100) {
                    clearInterval(intervalId);

                    // setLoader(false);
                    setProgress(0);
                    if (statusData.data.status === "Completed") {
                        setSuccessSnackbarOpen(true);
                        setsuccessMsg("Result Fetched Successfully");
                    }
                }
            } catch (err) {
                clearInterval(intervalId);
                console.log(err, "error during polling");
                setErrorSnackbarOpen(true);
                setErrorMessage("An error occurred during polling");
            }
        }, 5000);
    }

    const handleCheckDomain = (e) => {
        e.preventDefault();
        const regexDomain = /^(https?:\/\/)?([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}(\/.*)?$/;
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (regexDomain.test(ipDomain) || regexEmail.test(ipDomain)) {
            fetch(`${REACT_APP_SERVER_URL}/GetEspForSingleDomainOrEmail`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    domain: { domain: ipDomain },
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.status === 200) {
                        setStoredomain(data.data);

                        setCheckbox(true);
                        // setStoredomain(data.data);
                        // setIpDomain(data.data);

                        setSuccessSnackbarOpen(true);
                        setsuccessMsg("Domain Fetched Successfully");
                    } else {
                        setErrorSnackbarOpen(true);
                        setErrorMessage(data.error);
                    }
                });
            setDomainError(false);
        } else {
            console.log("Wrong input");
            setDomainError(true);
        }
        setIpDomain("");
    };

    function backfunction() {
        setEspRecords([]);
        setShowcheck(true);
    }

    let dummyData = [
        ["Domain"],
        ["filegov.co"],
        ["filegov.net"],
        ["filegov.org"],
        ["filesgov.com"],
        ["gov-file.biz"],
        ["gov-file.co"],
        ["gov-file.com"],
        ["gov-file.net"],
        ["gov-file.online"],
        ["gov-file.pro"],
        ["govfil.com"],
        ["govfile.biz"],
        ["govfile.net"],
        ["govfile.online"],
        ["govfiled.com"],
        ["govfill.com"],
        ["myfilegov.com"],
        ["thefilegov.com"],
        ["thegovfile.co"],
        ["thegovfile.com"],
        ["thegovfile.net"],
        ["thegovfile.org"],
        ["haybeach.com"],
        ["r13.com,"],
        ["mukesh.jha@xemailverify.com"],
        ["harshit.jaiswal@xemailverify.com"],
        ["randomstring@google.com"],
        ["checkdomain@google.com"],
        ["checkdomain@outlook.com"],
    ];

    const downloadExcel = () => {
        const workbook = XLSX.utils.book_new();

        const ws1 = XLSX.utils.json_to_sheet(allData.successfulRecords);
        XLSX.utils.book_append_sheet(workbook, ws1, "All Records");

        if (allData.failedRecords.length > 0) {
            const ws2 = XLSX.utils.json_to_sheet(allData.failedRecords);
            XLSX.utils.book_append_sheet(workbook, ws2, "Error Records");
        }

        XLSX.writeFile(workbook, "esp-file.xlsx");
    };
    return (
        <>
            {espRecords.length === 0 ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Typography sx={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>
                        ESP Finder - Email Service Provider Finder
                    </Typography>
                    <br />
                    <Card
                        sx={{
                            width: "50%",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                        }}
                    >
                        <CardContent
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            {loader ? (
                                <div
                                    style={{
                                        position: "relative",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CircularProgress variant="determinate" value={progress} size={80} />
                                    <div
                                        style={{
                                            position: "absolute",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="textSecondary">
                                            {`${Math.round(progress)}%`}
                                        </Typography>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Typography>Batch Search by Upload File</Typography>
                                    <UploadFileIcon
                                        style={{
                                            fontSize: "48px",
                                            marginBottom: "10px",
                                            color: "#2196f3",
                                            marginTop: "10px",
                                        }}
                                    ></UploadFileIcon>{" "}
                                    <input
                                        type="file"
                                        onChange={handleFileUpload}
                                        style={{ paddingLeft: "25%" }}
                                        accept=".csv"
                                    />
                                    <Box style={{ display: "flex" }}>
                                        <Button
                                            onClick={CsvReader}
                                            variant="contained"
                                            component="label"
                                            style={{ marginTop: "30px" }}
                                        >
                                            Upload ESP Finder File
                                        </Button>
                                        <CSVLink
                                            data={dummyData}
                                            filename={"dummyData.csv"}
                                            style={{
                                                border: "none",
                                                borderRadius: "4px",
                                                backgroundColor: "#1976D2",
                                                textDecoration: "none",
                                                color: "white",
                                                marginTop: "30px",
                                                marginLeft: "10px",
                                                padding: "10px",
                                                fontSize: "15px",
                                                "&:hover": {
                                                    backgroundColor: "#1565c0",
                                                },
                                            }}
                                        >
                                            Download Sample File
                                        </CSVLink>
                                    </Box>
                                </>
                            )}
                        </CardContent>
                    </Card>
                </div>
            ) : (
                <>
                    <ArrowBackIcon onClick={backfunction} sx={{ cursor: "pointer" }}></ArrowBackIcon>
                    <div style={{ display: "flex", justifyContent: "space-between", marginRight: "52px" }}>
                        <Typography sx={{ fontSize: "30px", ml: "55px" }}>RECORDS</Typography>

                        <FileDownloadIcon
                            titleAccess="export"
                            style={{
                                cursor: "pointer",
                                fontSize: "48px",
                                marginBottom: "10px",
                                color: "#2196f3",
                                marginLeft: "55px",
                            }}
                            onClick={downloadExcel}
                        />
                    </div>

                    <Box>
                        <CardContent>
                            <Grid
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontWeight: 600,
                                    color: "white",
                                    fontSize: "20px",
                                    background: "#004aad",
                                    borderRadius: "10px",
                                    padding: "1%",
                                    marginTop: "30px",
                                    lineHeight: "18px",
                                }}
                                container
                                spacing={2}
                                alignItems="center"
                            >
                                <Grid item sm={4} sx={{ marginTop: "-7px" }}>
                                    <label>Domain</label>
                                </Grid>
                                <Grid item sm={4} sx={{ marginTop: "-7px" }}>
                                    <label>Esp</label>
                                </Grid>
                            </Grid>
                        </CardContent>
                        {espRecords &&
                            espRecords.length > 0 &&
                            espRecords.map((data) => (
                                <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                    <div
                                        style={{
                                            width: "97.2%",
                                            height: "60px",
                                            border: "0.1rem solid #EEE7DA",
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                            padding: "1%",
                                            borderRadius: "10px",
                                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <CardContent>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid sm={8.3} id="dashboard">
                                                    <Typography sx={{ marginTop: "7px" }}>
                                                        {data.domain ? data.domain : data.email}
                                                    </Typography>
                                                </Grid>
                                                <Grid sm={1.6} id="dashboard">
                                                    {" "}
                                                    <Typography sx={{ marginTop: "7px" }}>{data.esp}</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </div>
                                </div>
                            ))}
                    </Box>
                </>
            )}

            {showcheck && (
                <Box>
                    <Box style={{ display: "flex", justifyContent: "center" }} sx={{ width: "100%" }}>
                        <TextField
                            error={showDomainError}
                            label="Enter Domain / Email "
                            id="outlined-size-small"
                            sx={{ width: "50%", marginTop: "50px" }}
                            value={ipDomain}
                            onChange={(e) => {
                                setIpDomain(e.target.value);
                            }}
                            helperText={
                                showDomainError &&
                                "Invalid input. Please enter a valid email (e.g., user@example.com) or a valid domain/URL (e.g., https://example.com)."
                            }
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleCheckDomain}
                            style={{ marginLeft: "20px", marginTop: "50px", maxHeight: "50px" }}
                        >
                            Single Search
                        </Button>
                    </Box>

                    <Box>
                        {checkbox && storedomain.length !== 0 && (
                            <>
                                <CardContent>
                                    <Grid
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                            color: "white",
                                            fontSize: "20px",
                                            background: "#004aad",
                                            borderRadius: "10px",
                                            padding: "1%",
                                            marginTop: "30px",
                                            lineHeight: "18px",
                                        }}
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item sm={4} sx={{ marginTop: "-10px" }}>
                                            <label>Domain/Email</label>
                                        </Grid>
                                        <Grid item sm={4} sx={{ marginTop: "-10px" }}>
                                            <label>ESP</label>
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                {storedomain.map((data, index) => (
                                    <div style={{ display: "flex", flexDirection: "column", gap: 2 }} key={index}>
                                        <div
                                            style={{
                                                width: "97.2%",
                                                height: "60px",
                                                border: "0.1rem solid #EEE7DA",
                                                marginTop: "1%",
                                                marginBottom: "1%",
                                                borderRadius: "10px",
                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item sm={8.3}>
                                                        <Typography sx={{ margin: "7px" }}>{data.domain}</Typography>
                                                    </Grid>
                                                    <Grid sm={1.6} id="dashboard">
                                                        {" "}
                                                        <Typography sx={{ marginTop: "7px" }}>{data.esp}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </Box>
                </Box>
            )}

            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMsg}
                </Alert>
            </Snackbar>
        </>
    );
};

export default FindEsp;
