import React from "react";
import {
    Typography,
    Paper,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ClickAwayListener,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const OverviewPanel = ({ isAccordionOpen, toggleAccordion, closeAccordion, dnsRecords, domainTest, ipTests }) => {
    return (
        <div>

            <Grid item xs={12} md={9}>
                <ClickAwayListener onClickAway={closeAccordion}>
                    <Paper
                        sx={{
                        
                            padding: "10px 10px",
                            border: "1px solid #f1f1f1",
                            borderRadius: "5px",
                            backgroundColor: "#fff",
                            overflowY: "auto",
                            boxShadow: "none !important",
                            width:"850px"
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <Typography sx={{ color: "#24a0ed", display: "flex", fontWeight: "bold" }}>
                                    Test
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ color: "#24a0ed", display: "flex", fontWeight: "bold" }}>
                                    Severity
                                </Typography>
                            </Grid>
                        </Grid>
                        <>
                            <Accordion expanded={isAccordionOpen} onChange={toggleAccordion}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: "#fafbff" }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography>DNS</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {dnsRecords[0].status === "Passing" ? (
                                                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                    <CheckCircleIcon sx={{ color: "green" }} />
                                                    <Typography color="green">Passing</Typography>
                                                </div>
                                            ) : (
                                                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                    <ErrorIcon sx={{ color: "red" }} />
                                                    <Typography color="red">Failing</Typography>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails sx={{ flexDirection: "column" }}>
                                    {dnsRecords.map((test, index) => (
                                        <Accordion key={index}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Typography>{test.name.toUpperCase()}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {test.status === "Passing" ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "4px",
                                                                }}
                                                            >
                                                                <CheckCircleIcon sx={{ color: "green" }} />
                                                                <Typography color="green">Passing</Typography>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "4px",
                                                                }}
                                                            >
                                                                <ErrorIcon sx={{ color: "red" }} />
                                                                <Typography color="red">Failing</Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            {/* <AccordionDetails>
                        {/* <Typography>Details about {test.name} test...</Typography>
                      </AccordionDetails> */}
                                        </Accordion>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: "#fafbff" }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography>Domain Reputation</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {domainTest[0].status === "Passing" ? (
                                                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                                    <CheckCircleIcon sx={{ color: "green" }} />
                                                    <Typography color="green">Passing</Typography>
                                                </div>
                                            ) : (
                                                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                                    <ErrorIcon sx={{ color: "red" }} />
                                                    <Typography color="red">Failing</Typography>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails sx={{ flexDirection: "column" }}>
                                    {domainTest.map((test, index) => (
                                        <Accordion key={index}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Typography>{test.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {test.status === "Passing" ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <CheckCircleIcon sx={{ color: "green" }} />
                                                                <Typography color="green">Passing</Typography>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <ErrorIcon sx={{ color: "red" }} />
                                                                <Typography color="red">Failing</Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            {/* <AccordionDetails>
                        <Typography>Details about {test.name}...</Typography>
                      </AccordionDetails> */}
                                        </Accordion>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: "#fafbff" }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography>IP Reputation</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {ipTests[0].status === "Passing" ? (
                                                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                                    <CheckCircleIcon sx={{ color: "green" }} />
                                                    <Typography color="green">Passing</Typography>
                                                </div>
                                            ) : (
                                                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                                    <ErrorIcon sx={{ color: "red" }} />
                                                    <Typography color="red">Failing</Typography>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails sx={{ flexDirection: "column" }}>
                                    {ipTests.map((test, index) => (
                                        <Accordion key={index}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Typography>{test.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {test.status === "Passing" ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <CheckCircleIcon sx={{ color: "green" }} />
                                                                <Typography color="green">Passing</Typography>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <ErrorIcon sx={{ color: "red" }} />
                                                                <Typography color="red">Failing</Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>Details about {test.name}...</Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    </Paper>
                </ClickAwayListener>
            </Grid>
        </div>
    );
};

export default OverviewPanel;

