import React from "react";
import { Accordian } from "./Accordian";
import { Container } from "@mui/material";
import { Button, Box, Typography, CircularProgress } from "@mui/material";
import LinearProgressWithLabel from "../LinearProgressWithLabel";
import AuditAccordianDetails from "./AuditAccordianDetail";
import ClientContentDialog from "../ClientContentDialog";
const AuditSteps = ({
    records,
    isLoading,
    processStatus,
    progress,
    accordianData,
    accordianStyle,
    showError,
    handleDialog,
    showDialog,
    handleClientSubmit,
    handleOverView,
    senderId,
    previousHandler,
    reCheckId,
    circularProgress,
    count,
}) => {
    const isPerfomedStep2 = localStorage.getItem(`${reCheckId}-Step2`);
    return (
        <Container className="accordiancontainer">
            {circularProgress && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )}

            {isLoading && (
                <Box className="linearProgress-box">
                    <Box>
                        <Typography>{processStatus.length === 0 ? "Processing..." : processStatus}</Typography>
                        <Typography>Please wait a moment or check back in a few minutes.</Typography>
                        <LinearProgressWithLabel value={progress} />
                    </Box>
                </Box>
            )}

            <Box style={{ marginTop: "20px" }}>
                {records.length !== 0
                    ? records.map((accordian, index) => (
                          <Accordian
                              className={`accordian`}
                              key={index}
                              style={accordianStyle[index] && accordianStyle[index]}
                              title={accordianData[index][0].processName && accordianData[index][0].processName}
                              indexStep = {index}
                          >
                              <AuditAccordianDetails data={accordianData[index] ? accordianData[index] : []} />
                          </Accordian>
                      ))
                    : []}
                {showError && <p>No data found</p>}
                {/* {records.length === 1 && !isLoading && ( */}

                {/* {records.length === 1 && !isLoading &&( */}
                {records.length === 1 && !isLoading && !isPerfomedStep2 && (
                    <Button variant="contained" color="success" style={{ float: "right" }} onClick={handleDialog}>
                        Execute Step 2
                    </Button>
                )}
                {records.length >= 2 && (
                    <>
                        <Button
                            variant="contained"
                            style={{ float: "right", margin: "10px" }}
                            color="primary"
                            onClick={previousHandler}
                        >
                            Revalidate Content
                        </Button>
                        <Button
                            variant="contained"
                            style={{ float: "right", margin: "10px" }}
                            color="success"
                            onClick={handleOverView}
                        >
                            Overview Result
                        </Button>
                    </>
                )}
                {showDialog && (
                    <ClientContentDialog
                        onClose={handleDialog}
                        open={showDialog}
                        onSubmit={(clientData) => handleClientSubmit(clientData)}
                    />
                )}
            </Box>
        </Container>
    );
};
export default AuditSteps;
