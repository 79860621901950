import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import "./ipblacklist.css";
import Link from "@mui/material/Link";
import { ReactComponent as Back } from "./back-arrow.svg";
import {
    Box,
    Grid,
    Button,
    TableRow,
    TableCell,
    Typography,
    Stack,
    CircularProgress,
    Snackbar,
    Alert,
    Dialog,
    DialogActions,
    DialogTitle,
    CardContent,
    TableContainer,
    Paper,
    TableBody,
    Table,
    TableHead,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HistoryIcon from "@mui/icons-material/History";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Skeleton } from "@mui/material";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F8F9FB",
        color: theme.palette.primary.black,
        fontSize: "1rem",
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "0.875rem",
    },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     // hide last border

//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }));

const IpBlacklist = () => {
    const [ipAddress, setIpAddress] = useState("");
    const [isBlacklisted, setIsBlacklisted] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [historyLoading, sethistoryLoading] = useState(false);
    const [LoadingcheckIp, setLoadingcheckIp] = useState(false);
    const [records, setRecords] = useState([]);
    const [historyIp, setHistoryIp] = useState([]);
    const [ip, setIp] = useState("");
    const [allIptable, setTable] = useState(true);
    const [historytable, setHistorytable] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [notifiFeature, setNotifiFeature] = useState("none");
    const [slackshow, setSlackshow] = useState(false);
    const [slackurl, setslackurl] = useState("");
    const [successMsg, setsuccessMsg] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [notifibox, setNotifiBox] = useState(true);
    const [currentpage, setcurrentpage] = useState(1);
    const [recordlimit, setrecordlimit] = useState(7);
    const [totalpage, setTotalpage] = useState();
    const [Paginationvar, setPaginationvar] = useState(false);
    const [historypage, sethistorypage] = useState(1);
    const [historyTotalpage, setHistoryTotalpage] = useState();
    const [historydomain, sethistorydomain] = useState("");
    const [deleteIpDialougeOpen, setdDeleteIpDialougeOpen] = React.useState(false);
    const [deleteId, setdeleteId] = React.useState("");
    const [Loading1, setLoading1] = useState(false);
    const [Loading2, setLoading2] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        handleCheckIP();
    };
    const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.white,
                color: "rgba(0, 0, 0, 0.87)",
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        })
    );
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(even)": {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };

    useEffect(() => {
        recordData();
        // setLoading(true);
    }, [currentpage]);

    const handleCheckIP = (e) => {
        setNotifiBox(false);
        setLoadingcheckIp(true);
        setTable(false);
        setHistorytable(false);
        e.preventDefault();

        if (ipAddress.length > 3) {
            try {
                e.preventDefault();

                fetch(`${REACT_APP_SERVER_URL}/check?ip=${ipAddress}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.data.length > 0) {
                            setLoadingcheckIp(false);
                            setIp(data.ipaddress);
                            setIsBlacklisted(data.data);
                            setLoadingcheckIp(false);
                        } else {
                            setLoadingcheckIp(false);
                            setTable(true);
                            setErrorSnackbarOpen(true);
                            setErrorMessage("No record found for IP/Domain or Invalid IP/Domain");
                        }
                    });
            } catch (err) {
                console.log(err, "Error in Checking Ip Blacklist");
            }
            return;
        } else {
            setLoadingcheckIp(false);
            setErrorSnackbarOpen(true);
            setErrorMessage("No record found for IP/Domain or Invalid IP/Domain");
            setTable(true);
        }
    };

    function Historyhandle(e, page, limit) {
        setNotifiBox(false);
        setTable(false);
        let history = e;
        let historypage = page;
        let recordlimit = limit;
        setHistorytable(true);
        sethistoryLoading(true);

        fetch(`${REACT_APP_SERVER_URL}/history?ip=${history}&page=${historypage}&limit=${recordlimit}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                sethistoryLoading(false);
                if (data.data.length === 0) {
                    setTable(true);
                    setErrorSnackbarOpen(true);
                    setErrorMessage("No history available as this domain is added today");
                } else {
                    setHistoryIp(data.data);
                    // sethistoryLoading(false);
                    setHistoryTotalpage(data.totalPages);
                    sethistorydomain(data.ip);
                }
            });
    }
    const handleChange = (event, value) => {
        setcurrentpage(value);
    };
    const handlehistoryChange = (event, value) => {
        // sethistorypage(value);
        Historyhandle(historydomain, value, recordlimit);
    };
    function Date(data) {
        let a = data;
        let d = "";
        // let b = `${a[8] + a[9]}-${a[5] + a[6]}-${a[0] + a[1] + a[2] + a[3]}`;
        let b = `${a[5] + a[6]}-${a[8] + a[9]}-${a[0] + a[1] + a[2] + a[3]}`;
        let datas = b.slice(0, 2);
        switch (datas) {
            case "01":
                d = "Jan";
                break;
            case "02":
                d = "Feb";
                break;
            case "03":
                d = "Mar";
                break;
            case "04":
                d = "Apr";
                break;
            case "05":
                d = "May";
                break;
            case "06":
                d = "Jun";
                break;
            case "07":
                d = "Jul";
                break;
            case "08":
                d = "Aug";
                break;
            case "09":
                d = "Sep";
                break;
            case "10":
                d = "Oct";
                break;
            case "11":
                d = "Nov";
                break;
            case "12":
                d = "Dec";
                break;
        }
        let finaldate = `${d}-${a[8] + a[9]}-${a[0] + a[1] + a[2] + a[3]}`;
        return finaldate;
    }

    function notifiFunction(e) {
        if (e === "slack") {
            setSlackshow(true);
        } else if (e === "none") {
            setSlackshow(false);
            let mail = e;

            fetch(`${REACT_APP_SERVER_URL}/Noneoption`, {
                headers: {
                    Accept: "application/json",
                    "Contendt-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    option: mail,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Saved notification to None");
                });
        } else {
            setSlackshow(false);
            let mail = e;

            fetch(`${REACT_APP_SERVER_URL}/Mailoption`, {
                headers: {
                    Accept: "application/json",
                    "Contendt-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    option: mail,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("The Records Will be sent to your Mail");
                });
        }
    }

    function SaveSlackUrl(url) {
        if (url === "") {
            setErrorSnackbarOpen(true);
            setErrorMessage("SlackWebhook url is empty");
            return;
        } else {
            let slackUrl = url;
            fetch(`${REACT_APP_SERVER_URL}/slackUrl`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    option: "slack",
                    slackurl: slackUrl,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setSlackshow(false);
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("The Records Will be sent to your Slack");
                });
        }
    }

    function StatusCheck(data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].status === "listed") {
                return (
                    <div
                        style={{
                            display: "flex",
                            padding: "3px",
                            width: "60%",
                            border: "2px solid #FF5D5D",
                            borderRadius: "5px",
                            marginTop: "-5px ",
                        }}
                    >
                        <CancelIcon sx={{ color: "red" }}></CancelIcon>
                        <Typography>Blacklisted</Typography>
                    </div>
                );

                // return <CancelIcon sx={{color: 'red'}}></CancelIcon>;
            }
        }
        return (
            <div
                style={{
                    display: "flex",
                    width: "45%",
                    textAlign: "center",
                    padding: "3px",
                    border: "2px solid green",
                    borderRadius: "5px",
                    marginTop: "-5px",
                }}
            >
                <CheckBoxIcon sx={{ color: "green" }}></CheckBoxIcon>
                <Typography> Clean</Typography>
            </div>
        );
    }

    function recordData(e) {
        setLoading1(true);
        setNotifiBox(true);

        fetch(`${REACT_APP_SERVER_URL}/ipRecords?page=${currentpage}&limit=${recordlimit}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setPaginationvar(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setLoading1(false);
                    setLoading2(false);
                    setLoading(false);
                    setTotalpage(data.totalPages);
                    setRecords(data.data);
                    checkMedium(data.medium);
                    setPaginationvar(true);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                // Handle error if needed
                setLoading1(false); // Stop loader if there's an error
                setLoading2(false);
                setLoading(false);
            });
    }

    function checkMedium(m) {
        console.log(m, "m porintit");
        if (m.slack === false && m.mail === true) {
            setNotifiFeature("mail");
        } else if (m.slack === true && m.mail === false) {
            setNotifiFeature("slack");
        } else {
            setNotifiFeature("none");
        }
    }

    function back() {
        setHistorytable(false);
        setIsBlacklisted([]);
        recordData();
        setTable(true);
    }

    function DeleteIpHandle(ip) {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/DeleteIp`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                ip: ip,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoading(false);
                if (data.status === 200) {
                    recordData();
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Ip Deleted successfully.");
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Something went wrong.");
                }
            });
    }

    const SkeletonTableBody = ({ rowCount = 5 }) => {
        const skeletonRows = [...Array(rowCount)].map((_, index) => (
            <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="rectangular" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} height={30} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
            </StyledTableRow>
        ));

        return <TableBody>{skeletonRows}</TableBody>;
    };

    return (
        <>
            {Loading ? (
                <Box sx={{ width: "100%", display: "center", justifyContent: "center", overflow: "hidden" }}>
                    <CircularProgress />
                    <Typography>Deleting...</Typography>
                </Box>
            ) : (
                <div>
                    <Dialog
                        PaperProps={{
                            style: { borderRadius: "20px" },
                        }}
                        open={deleteIpDialougeOpen}
                    >
                        <div
                            style={{
                                width: "500px",
                                height: "200px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <DialogTitle id="alert-dialog-title">
                                <p style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                                    Are you sure you want to delete account?
                                </p>
                            </DialogTitle>
                            <DialogActions sx={{ mb: 4, float: "right" }}>
                                <Button
                                    onClick={() => {
                                        DeleteIpHandle(deleteId);
                                        setdDeleteIpDialougeOpen(false);
                                    }}
                                    variant="contained"
                                    sx={{ color: "#fff" }}
                                    color="success"
                                >
                                    Delete
                                </Button>
                                <Button
                                    onClick={() => {
                                        setdDeleteIpDialougeOpen(false);
                                        setdeleteId("");
                                    }}
                                    variant="contained"
                                    sx={{ color: "#fff" }}
                                    color="error"
                                    disabled={false}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                    <Box sx={{ textAlign: "center" }}>
                        <Typography style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>
                            Blacklist Checker
                        </Typography>
                        <br />
                        <Typography
                            style={{
                                textAlign: "center",
                                fontSize: "18px",
                                color: "rgba(71, 84, 103, 1)",
                                fontWeight: "400px",
                                lineHeight: "24px",
                            }}
                        >
                            Discover in an instant where your provided IPs or domains stand on the blacklist. Get a
                            detailed report on their status and ensure your online presence is pristine. Protect your
                            reputation with ease.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px", marginLeft: "2px" }}>
                        <Typography
                            style={{
                                fontSize: "20px",
                                fontWeight: 600,
                                lineHeight: "28px",
                                color: "rgba(16, 24, 40, 1)",
                            }}
                        >
                            All Records
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "15px", alignItems: "center", bottom: "0px" }}>
                        <form onSubmit={handleSubmit} style={{ marginLeft: "2px" }}>
                            <TextField
                                sx={{ width: "30%" }}
                                label="Enter IP/Domain "
                                id="outlined-size-small"
                                size="small"
                                value={ipAddress}
                                onChange={(e) => setIpAddress(e.target.value)}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ marginLeft: "10px", padding: "6px", backgroundColor: "#004aad" }}
                                onClick={handleCheckIP}
                            >
                                Add
                            </Button>
                        </form>
                        {notifibox && (
                            <div id="notification">
                                <Box>
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1, minWidth: 230, marginTop: "0px", marginLeft: "300px" }}
                                    >
                                        <InputLabel id="demo-simple-select-standard-label">
                                            Get Daily Blacklist Reports
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={notifiFeature}
                                            onChange={(e) => {
                                                let selectFeature = e.target.value;
                                                setNotifiFeature(selectFeature);
                                                notifiFunction(selectFeature);
                                            }}
                                            label="Get Daily Blacklist Reports"
                                        >
                                            <MenuItem value="none">None</MenuItem>
                                            <MenuItem value="slack">SLACK</MenuItem>
                                            <MenuItem value="mail">EMAIL</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                {slackshow && (
                                    <form id="notifiForm" style={{ marginLeft: "195px" }}>
                                        <TextField
                                            label="Enter Your slackWebhookUrl"
                                            id="outlined-size-small"
                                            defaultValue="Small"
                                            size="small"
                                            onChange={(e) => setslackurl(e.target.value)}
                                            value={slackurl}
                                        />
                                        <Button sx={{ marginLeft: "4px" }} onClick={() => SaveSlackUrl(slackurl)}>
                                            Save
                                        </Button>
                                        <Link
                                            href="https://youtu.be/NfdZgcIRi5c"
                                            target="blank"
                                            title="Find where to get SlackWebhookUrl"
                                            sx={{ color: "red", textDecoration: "none" }}
                                        >
                                            Tutorial<HelpIcon></HelpIcon>{" "}
                                        </Link>
                                    </form>
                                )}
                            </div>
                        )}
                    </Box>

                    {allIptable && (
                        <Box sx={{ marginTop: "15px" }}>
                            {/* {Loading1 ? (
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                records.length !== 0 && (
                                    <Box>
                                        <CardContent>
                                            <Grid
                                                style={{
                                                    width: "102.7%",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    fontWeight: 600,
                                                    color: "white",
                                                    fontSize: "16px",
                                                    background: "#004aad",
                                                    borderRadius: "10px",
                                                    padding: "1%",
                                                    marginTop: "30px",
                                                    lineHeight: "18px",
                                                }}
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid item sm={4} sx={{ marginTop: "-10px" }}>
                                                    <label>IP/DOMAIN</label>
                                                </Grid>
                                                <Grid item sm={2} sx={{ marginTop: "-10px" }}>
                                                    <label>LISTED</label>
                                                </Grid>
                                                <Grid item sm={2} sx={{ marginTop: "-10px" }}>
                                                    <label>CLEAN</label>
                                                </Grid>
                                                <Grid item sm={1.8} sx={{ marginTop: "-10px" }}>
                                                    <label>LAST 7 DAYS HISTORY</label>
                                                </Grid>
                                                <Grid item sm={2} sx={{ marginTop: "-10px" }}>
                                                    <label>DELETE</label>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        {records &&
                                            records
                                                .sort((a, b) => (a.status === "listed" ? -1 : 1))
                                                .map((data, i) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            gap: 4,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: "60px",
                                                                border: "0.1rem solid #EEE7DA",
                                                                marginBottom: "1%",
                                                                padding: "1.5%",
                                                                borderRadius: "10px",
                                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                cursor:
                                                                    data.status === "Verified" ? "pointer" : "default",
                                                                backgroundColor:
                                                                    data.status === "Verified"
                                                                        ? "#f5f5f5"
                                                                        : "transparent",
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    alignItems="center"
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        marginTop: "-20px",
                                                                    }}
                                                                >
                                                                    <Grid sm={4.2} id="ip">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {data.ip}
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid sm={1.8} id="ip">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >{`${data.Blacklisted}`}</Typography>
                                                                    </Grid>

                                                                    <Grid sm={1.7} id="ip">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >{`${data.Clean}`}</Typography>
                                                                    </Grid>

                                                                    <Grid sm={1.8}>
                                                                        {" "}
                                                                        <Button
                                                                            variant="text"
                                                                            sx={{
                                                                                width: "70%",
                                                                                border: "2px solid blue",
                                                                                fontSize: "14px",
                                                                                textAlign: "center",
                                                                                height: "35px",
                                                                            }}
                                                                            onClick={() =>
                                                                                Historyhandle(
                                                                                    data.ip,
                                                                                    historypage,
                                                                                    recordlimit
                                                                                )
                                                                            }
                                                                        >
                                                                            <LightTooltip
                                                                                title={
                                                                                    <span style={{ color: "blue" }}>
                                                                                        View Details
                                                                                    </span>
                                                                                }
                                                                            >
                                                                                <HistoryIcon
                                                                                    sx={{
                                                                                        height: "20px",
                                                                                        marginRight: "5px",
                                                                                    }}
                                                                                ></HistoryIcon>
                                                                            </LightTooltip>
                                                                            History
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid sm={1.7}>
                                                                        <Button
                                                                            sx={{
                                                                                color: "#EF6D6D",
                                                                                marginRight: "20%",
                                                                            }}
                                                                            onClick={() => {
                                                                                setdDeleteIpDialougeOpen(true);
                                                                                setdeleteId(data.ip);
                                                                            }}
                                                                        >
                                                                            <LightTooltip
                                                                                title={
                                                                                    <span style={{ color: "red" }}>
                                                                                        Delete Button
                                                                                    </span>
                                                                                }
                                                                            >
                                                                                <Delete />
                                                                            </LightTooltip>
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </div>
                                                    </div>
                                                ))}
                                    </Box>
                                )
                            )} */}
                            {Loading1 ? (
                                <SkeletonTableBody />
                            ) : (
                                records.length !== 0 && (
                                    <Box>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 800 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>IP/DOMAIN</StyledTableCell>
                                                        <StyledTableCell align="right">LISTED</StyledTableCell>
                                                        <StyledTableCell align="right">CLEAN</StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            LAST 7 DAYS HISTORY
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">DELETE</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {records &&
                                                        records
                                                            .sort((a, b) => (a.status === "listed" ? -1 : 1))
                                                            .map((data, i) => (
                                                                <StyledTableRow key={data.ip}>
                                                                    <StyledTableCell component="th" scope="row">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {data.ip}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {`${data.Blacklisted}`}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {`${data.Clean}`}
                                                                        </Typography>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        <Button
                                                                            variant="text"
                                                                            sx={{
                                                                                width: "40%",
                                                                                border: "2px solid blue",
                                                                                fontSize: "14px",
                                                                                textAlign: "center",
                                                                                height: "35px",
                                                                            }}
                                                                            onClick={() =>
                                                                                Historyhandle(
                                                                                    data.ip,
                                                                                    historypage,
                                                                                    recordlimit
                                                                                )
                                                                            }
                                                                        >
                                                                            <LightTooltip
                                                                                title={
                                                                                    <span style={{ color: "blue" }}>
                                                                                        View Details
                                                                                    </span>
                                                                                }
                                                                            >
                                                                                <HistoryIcon
                                                                                    sx={{
                                                                                        height: "20px",
                                                                                        marginRight: "5px",
                                                                                    }}
                                                                                />
                                                                            </LightTooltip>
                                                                            History
                                                                        </Button>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        <Button
                                                                            sx={{ color: "#EF6D6D" }}
                                                                            onClick={() => {
                                                                                setdDeleteIpDialougeOpen(true);
                                                                                setdeleteId(data.ip);
                                                                            }}
                                                                        >
                                                                            <LightTooltip
                                                                                title={
                                                                                    <span style={{ color: "red" }}>
                                                                                        Delete Button
                                                                                    </span>
                                                                                }
                                                                            >
                                                                                <DeleteIcon />
                                                                            </LightTooltip>
                                                                        </Button>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )
                            )}

                            {
                                <div style={{ marginTop: "13px" }}>
                                    <Stack
                                        spacing={2}
                                        sx={{
                                            textAlign: "center",
                                            justifyContent: "center",
                                            justifyItems: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {Loading1 ? (
                                            <Pagination count={totalpage} color="primary" disabled />
                                        ) : (
                                            <Pagination
                                                count={totalpage}
                                                color="primary"
                                                onChange={handleChange}
                                                value={currentpage}
                                            />
                                        )}
                                    </Stack>
                                </div>
                            }
                        </Box>
                    )}

                    {historytable && (
                        <Box sx={{ marginTop: "-45px" }}>
                            <h3 style={{ marginTop: 50 }}></h3>
                            {historyLoading ? (
                                <SkeletonTableBody />
                            ) : (
                                historyIp.length !== 0 && (
                                    <Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                textAlign: "center",
                                                alignItems: "center",
                                                gap: "6px",
                                                padding: "10px",
                                                marginLeft: "-10px",
                                            }}
                                        >
                                            <KeyboardBackspaceIcon
                                                onClick={back}
                                                sx={{ borderRadius: "5px", backgroundColor: "#478CCF",color:'white'}}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: "20px",
                                                    marginTop: "5px",
                                                    marginLeft: "10px",
                                                    fontWeight: 550,
                                                }}
                                            >
                                                {`${historyIp[0].ip} Last 7 Days Blacklist History `}{" "}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                                                    <TableHead>
                                                        <StyledTableRow>
                                                            <StyledTableCell>IP/DOMAIN</StyledTableCell>
                                                            <StyledTableCell>Date</StyledTableCell>
                                                            <StyledTableCell>Listed</StyledTableCell>
                                                            <StyledTableCell>Details</StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {historyIp &&
                                                            historyIp
                                                                .sort(
                                                                    (a, b) =>
                                                                        Date(b.cron_updateDate) -
                                                                        Date(a.cron_updateDate)
                                                                )
                                                                .map((data, i) => (
                                                                    <StyledTableRow key={i}>
                                                                        <StyledTableCell component="th" scope="row">
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                            >
                                                                                {data.ip}
                                                                            </Typography>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                            >
                                                                                {Date(data.cron_updateDate).slice(
                                                                                    0,
                                                                                    11
                                                                                )}
                                                                            </Typography>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {data.Blacklisted === null
                                                                                    ? "NULL"
                                                                                    : data.details.Blacklisted}
                                                                            </Typography>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell>
                                                                            <Link
                                                                                sx={{
                                                                                    textDecoration: "none",
                                                                                    padding: "7px",
                                                                                    borderRadius: "5px",
                                                                                    border: "2px solid blue",
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                                href={`${process.env.REACT_APP_URL}/check/${data.code.slice(0, data.id.length)}`}
                                                                                target="_blank"
                                                                            >
                                                                                Details
                                                                            </Link>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            {/* <CardContent>
                                                <Grid
                                                    style={{
                                                        width: "102.7%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        fontWeight: 600,
                                                        color: "white",
                                                        fontSize: "16px",
                                                        background: "#004aad",
                                                        borderRadius: "10px",
                                                        padding: "1.5%",
                                                        marginTop: "30px",
                                                        lineHeight: "18px",
                                                    }}
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                >
                                                    <Grid sm={4} sx={{ marginTop: "8px" }}>
                                                        <label id="detailsTrowIp">IP/DOMAIN</label>
                                                    </Grid>
                                                    <Grid sm={2} sx={{ marginTop: "8px" }}>
                                                        <label id="detailsTrowIp">Date</label>
                                                    </Grid>
                                                    <Grid sm={2} sx={{ marginTop: "8px" }}>
                                                        <label id="detailsTrowIp">Listed</label>
                                                    </Grid>
                                                    <Grid sm={2.2} sx={{ marginTop: "8px" }}>
                                                        <label id="detailsTrowIp">Details</label>
                                                    </Grid>
                                                </Grid>
                                            </CardContent> */}
                                            {/* {historyIp &&
                                                historyIp
                                                    .sort((a, b) => Date(b.cron_updateDate) - Date(a.cron_updateDate))
                                                    .map((data, i) => (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                gap: 4,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    height: "60px",
                                                                    border: "0.1rem solid #EEE7DA",
                                                                    marginBottom: "1%",
                                                                    padding: "1.5%",
                                                                    borderRadius: "10px",
                                                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                    cursor:
                                                                        data.status === "Verified"
                                                                            ? "pointer"
                                                                            : "default",
                                                                    backgroundColor:
                                                                        data.status === "Verified"
                                                                            ? "#f5f5f5"
                                                                            : "transparent",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                <CardContent>
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        alignItems="center"
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        <Grid sm={3.5} id="ip">
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                            >
                                                                                {data.ip}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid sm={2} id="ip">
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                            >
                                                                                {Date(data.cron_updateDate).slice(
                                                                                    0,
                                                                                    11
                                                                                )}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid sm={2} id="ip">
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {data.Blacklisted === null
                                                                                    ? "NULL"
                                                                                    : data.details.Blacklisted}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid sm={2} id="detailsTrowIp">
                                                                            <Link
                                                                                sx={{
                                                                                    textDecoration: "none",
                                                                                    padding: "7px",
                                                                                    borderRadius: "5px",
                                                                                    border: "2px solid blue",
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                                href={`${process.env.REACT_APP_URL}/check/${data.code.slice(0, data.id.length)}`}
                                                                                target="_blank"
                                                                            >
                                                                                Details
                                                                            </Link>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </div>
                                                        </div>
                                                    ))} */}
                                        </Box>
                                        {/* {< div style={{
                                            marginTop: '13px'
                                        }}>
                                            <Stack spacing={2} sx={{ textAlign: 'center', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center', }}>
                                                <Pagination count={historyTotalpage} color="primary" onChange={handlehistoryChange} />
                                            </Stack>
                                        </div>

                                        } */}
                                    </Box>
                                )
                            )}
                        </Box>
                    )}

                    {LoadingcheckIp ? (
                        <Box sx={{ width: "100%", display: "center", justifyContent: "center", marginTop: 3 }}>
                            <CircularProgress />
                            <Typography>
                                {" "}
                                Searching status for all zones associated with the domain, it would take approx 30 to 40
                                seconds...
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ marginTop: "15px" }}>
                                {isBlacklisted.length !== 0 && (
                                    <Box>
                                        <Button onClick={back}>
                                            <Back></Back>
                                        </Button>

                                        <CardContent>
                                            <Grid
                                                style={{
                                                    width: "102.7%",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    fontWeight: 600,
                                                    color: "white",
                                                    fontSize: "16px",
                                                    background: "#004aad",
                                                    borderRadius: "10px",
                                                    padding: "1.5%",
                                                    marginTop: "30px",
                                                    lineHeight: "18px",
                                                }}
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid sm={3.5}>
                                                    <label id="ip">IP Address</label>
                                                </Grid>
                                                <Grid sm={2}>
                                                    <label id="status">Status</label>
                                                </Grid>
                                                <Grid sm={2.9}>
                                                    <label id="blacklist">Zones</label>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        {isBlacklisted &&
                                            isBlacklisted
                                                .sort((a, b) => (a.status === "listed" ? -1 : 1))
                                                .map((data, i) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            gap: 4,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: "60px",
                                                                border: "0.1rem solid #EEE7DA",
                                                                marginBottom: "1%",
                                                                padding: "1.5%",
                                                                borderRadius: "10px",
                                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                cursor:
                                                                    data.status === "Verified" ? "pointer" : "default",
                                                                backgroundColor:
                                                                    data.status === "Verified"
                                                                        ? "#f5f5f5"
                                                                        : "transparent",
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    alignItems="center"
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <Grid sm={4} id="ip">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {ip}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        sm={2.4}
                                                                        id="ip"
                                                                        sx={{
                                                                            fontSize: "16px",
                                                                            display: "flex",
                                                                            fontWeight: 500,
                                                                            lineHeight: "18px",
                                                                        }}
                                                                    >
                                                                        {data.status === "listed" ? (
                                                                            <Typography
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    width: "40%",
                                                                                    textAlign: "center",
                                                                                    border: "2px solid red",
                                                                                    padding: "3px",
                                                                                    borderRadius: "5px",
                                                                                    marginTop: "-4px",
                                                                                }}
                                                                            >
                                                                                <CancelIcon
                                                                                    sx={{
                                                                                        color: "red",
                                                                                        height: "20px",
                                                                                    }}
                                                                                ></CancelIcon>
                                                                                Listed
                                                                            </Typography>
                                                                        ) : (
                                                                            <Typography
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    width: "30%",
                                                                                    textAlign: "center",
                                                                                    border: "2px solid green",
                                                                                    borderRadius: "5px",
                                                                                    padding: "3px",
                                                                                    height: "30px",
                                                                                    marginTop: "-4px",
                                                                                }}
                                                                            >
                                                                                <CheckBoxIcon
                                                                                    sx={{
                                                                                        color: "green",
                                                                                        height: "20px",
                                                                                    }}
                                                                                >
                                                                                    Ok
                                                                                </CheckBoxIcon>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        fontWeight: 500,
                                                                                        lineHeight: "18px",
                                                                                    }}
                                                                                >
                                                                                    Ok
                                                                                </Typography>
                                                                            </Typography>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid sm={3} id="ip">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                fontWeight: 500,
                                                                                lineHeight: "18px",
                                                                            }}
                                                                        >
                                                                            {data.zone}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </div>
                                                    </div>
                                                ))}
                                    </Box>
                                )}
                                <Snackbar
                                    open={errorSnackbarOpen}
                                    autoHideDuration={2000}
                                    onClose={handleErrorSnackbarClose}
                                >
                                    <Alert
                                        onClose={handleErrorSnackbarClose}
                                        variant="filled"
                                        severity="error"
                                        sx={{ width: "100%" }}
                                    >
                                        {errorMessage}
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={successSnackbarOpen}
                                    autoHideDuration={6000}
                                    onClose={handleSuccessSnackbarClose}
                                >
                                    <Alert
                                        onClose={handleSuccessSnackbarClose}
                                        variant="filled"
                                        severity="success"
                                        sx={{ width: "100%" }}
                                    >
                                        {successMsg}
                                    </Alert>
                                </Snackbar>
                            </Box>
                        </>
                    )}
                </div>
            )}
        </>
    );
};
export default IpBlacklist;
