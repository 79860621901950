import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import axios from "axios";
const root = ReactDOM.createRoot(document.getElementById("root"));
axios.interceptors.request.use((request) => {
    console.log(request, "request");
    return request;
});
axios.interceptors.response.use((response) => {
    console.log(response, "res");
    const urlObj = new URL(response.request.responseURL);
    const params = new URLSearchParams(urlObj.search);
    const code = params.get("code");
    code && localStorage.setItem(code, JSON.stringify(response.data));
    return response;
});
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
