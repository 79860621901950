import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import BarChart from "../../components/chart-component/chart";
import LineChart from "./LineChart";
import Template from "./Template";

const BccComponent = () => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedData, setSelectedData] = useState({
        labels: ["Inbox", "Spam"],
        datasets: [
            {
                label: "Counts",
                data: [0, 0],
                backgroundColor: ["rgba(75,192,192,0.6)", "rgba(153,102,255,0.6)"],
            },
        ],
    });

    const [lineChartData, setLineChartData] = useState({
        labels: ["Total Inbox Count", "Total Spam Count"],
        datasets: [
            {
                label: "Counts",
                data: [0, 0],
                borderColor: ["rgba(75,192,192,0.6)", "rgba(153,102,255,0.6)"],
                fill: false,
            },
        ],
    });

    const handleCardClick = (data) => {
        if (data && Array.isArray(data.graphData)) {
            setSelectedCard(data.bcc_email);

            const labels = data.graphData.map((item) => item.date);
            const inboxData = data.graphData.map((item) => item.inbox_count);
            const spamData = data.graphData.map((item) => item.spam_count);

            setSelectedData({
                labels: labels,
                datasets: [
                    {
                        label: "Inbox count",
                        data: inboxData,
                        backgroundColor: "rgba(75,192,192,0.6)",
                    },
                    {
                        label: "Spam count",
                        data: spamData,
                        backgroundColor: "rgba(153,102,255,0.6)",
                    },
                ],
            });
        } else {
            console.error("graphData is undefined or not an array");
        }
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                // text: "Bar Chart Example",
            },
        },
    };

    // Sample data
    const bccData = [
        {
            bcc_email: "email1@example.com",
            total_inbox: 120,
            total_spam: 30,
            graphData: [
                { date: "2024-01-01", inbox_count: 50, spam_count: 10 },
                { date: "2024-01-02", inbox_count: 70, spam_count: 20 },
            ],
        },
        {
            bcc_email: "email2@example.com",
            total_inbox: 150,
            total_spam: 20,
            graphData: [
                { date: "2024-01-01", inbox_count: 80, spam_count: 15 },
                { date: "2024-01-02", inbox_count: 70, spam_count: 5 },
            ],
        },
        {
            bcc_email: "email3@example.com",
            total_inbox: 100,
            total_spam: 50,
            graphData: [
                { date: "2024-01-01", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-02", inbox_count: 40, spam_count: 20 },
            ],
        },
        {
            bcc_email: "email4@example.com",
            total_inbox: 120,
            total_spam: 30,
            graphData: [
                { date: "2024-01-01", inbox_count: 50, spam_count: 10 },
                { date: "2024-01-02", inbox_count: 70, spam_count: 20 },
            ],
        },
    ];

    const templates = [
        {
            id: 1,
            name: "Template 1",
            subject:
                "There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain",
            body: `<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body>
    <div style="box-sizing:inherit">Hi there, we're reaching out to a few businesses in your area, and yours came up.
    </div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Curious– if we could show you the Top Business Asset that other businesses use to
        THRIVE even in uncertain economies that we could install in your company…</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Would you be interested in learning more?</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">What we do is install client acquisition systems into businesses like yours (because
        getting clients is the first thing you can control during uncertain times.)</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">And this allows you to:</div>
    <div style="box-sizing:inherit">(1) Gain predictable revenue month over month</div>
    <div style="box-sizing:inherit">(2) Build certainty about where your next client is coming from</div>
    <div style="box-sizing:inherit">(3) Build a truly scalable business (because if you don't have predictable client
        growth, you can't scale)</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Look, one of our clients used this exact system to generate significant start-up
        revenue in under 6 months.</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Would love to show you the process. What day/time works best?</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Excited to connect with you.</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">
        <div style="box-sizing:inherit">Ava Johnson</div>
        <div style="box-sizing:inherit">Marketing Director at PEMA. io&nbsp;</div>
        <div style="box-sizing:inherit">PEMA LLC</div>
        <div style="box-sizing:inherit">1000 Brickell Avenue, Miami FL</div>
        <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
        <div style="box-sizing:inherit">P.S. Reply "Unsub" if you do not wish to receive emails.&nbsp;</div>
    </div>
</body>
</html>`,
            value: "20",
            lineData: [
                { date: "2024-01-01", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-02", inbox_count: 10, spam_count: 20 },
                { date: "2024-01-03", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-04", inbox_count: 10, spam_count: 40 },
                { date: "2024-01-05", inbox_count: 60, spam_count: 60 },
                { date: "2024-01-06", inbox_count: 20, spam_count: 90 },
                { date: "2024-01-07", inbox_count: 40, spam_count: 20 },
            ],
        },
        {
            id: 2,
            name: "Template 2",
            subject: "Subject 2",
            body: "<p>Sample body 2</p>",
            value: "35",
            lineData: [
                { date: "2024-01-01", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-02", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-03", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-04", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-05", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-06", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-07", inbox_count: 40, spam_count: 20 },
            ],
        },
        {
            id: 3,
            name: "Template 3",
            subject: "Subject 3",
            body: "<p>Sample body 3</p>",
            value: "65",
            lineData: [
                { date: "2024-01-01", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-02", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-03", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-04", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-05", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-06", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-07", inbox_count: 40, spam_count: 20 },
            ],
        },
        {
            id: 4,
            name: "Template 4",
            subject: "Subject 1",
            body: "<p>Sample body 1</p>",
            value: "20",
            lineData: [
                { date: "2024-01-01", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-02", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-03", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-04", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-05", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-06", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-07", inbox_count: 40, spam_count: 20 },
            ],
        },
        {
            id: 5,
            name: "Template 5",
            subject: "Subject 2",
            body: "<p>Sample body 2</p>",
            value: "35",
            lineData: [
                { date: "2024-01-01", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-02", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-03", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-04", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-05", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-06", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-07", inbox_count: 40, spam_count: 20 },
            ],
        },
        {
            id: 6,
            name: "Template 6",
            subject: "Subject 3",
            body: "<p>Sample body 3</p>",
            value: "65",
            lineData: [
                { date: "2024-01-01", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-02", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-03", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-04", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-05", inbox_count: 60, spam_count: 30 },
                { date: "2024-01-06", inbox_count: 40, spam_count: 20 },
                { date: "2024-01-07", inbox_count: 40, spam_count: 20 },
            ],
        },
    ];

    const spamWords = ["free", "offer", "click", "money"];

    const handleTemplateSelect = (data) => {
        if (data && Array.isArray(data.lineData)) {
            const labels = data.lineData.map((item) => item.date);
            const inboxData = data.lineData.map((item) => item.inbox_count);
            const spamData = data.lineData.map((item) => item.spam_count);
            setLineChartData({
                labels: labels,
                datasets: [
                    {
                        label: "Inbox Count",
                        data: inboxData,
                        fill: false,
                        borderColor: "rgb(75, 192, 192)",
                        tension: 0.1,
                    },
                    {
                        label: "Spam Count",
                        data: spamData,
                        fill: false,
                        borderColor: "rgb(255, 99, 132)",
                        tension: 0.1,
                    },
                ],
            });
        } else {
            console.error("graphData is undefined or not an array");
        }
    };

    const option = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Inbox vs Spam Count Over Time",
            },
        },
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                overflow: "auto",
                                height: "322px",
                                gap: 5,
                                padding: "10px",
                                border: "1px solid #f1f1f1",
                                backgroundColor: "#fff",
                            }}
                        >
                            {Array.isArray(bccData) && bccData.length > 0 ? (
                                bccData.map((data) => (
                                    <Box
                                        key={data.bcc_email}
                                        sx={{
                                            width: bccData.length === 1 ? "100%" : "250px",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            borderRadius: "5px",
                                            minHeight: "110px",
                                            height: "auto",
                                            alignContent: "center",
                                            border:
                                                selectedCard === data.bcc_email
                                                    ? "1px solid blue"
                                                    : "1px solid #f1f1f1",
                                            backgroundColor: selectedCard === data.bcc_email ? "#f0f8ff" : "white",
                                            marginLeft: "10px",
                                        }}
                                        onClick={() => handleCardClick(data)}
                                    >
                                        <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                                            {data.bcc_email}
                                        </Typography>
                                        <Typography className="typo-inbox">Total Inbox: {data.total_inbox}</Typography>
                                        <Typography className="typo-spam">Total Spam: {data.total_spam}</Typography>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No BCC data available</Typography>
                            )}
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ backgroundColor: "#fff", padding: "5px" }}>
                        <BarChart data={selectedData} options={options} />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} md={6.5}>
                    <Box>
                        <Template templates={templates} spamWords={spamWords} onTemplateSelect={handleTemplateSelect} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={5.5} sx={{}}>
                    <Box sx={{ backgroundColor: "#fff", padding: "10px" }}>
                        <LineChart data={lineChartData} options={option} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BccComponent;
