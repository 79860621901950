import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F8F9FB",
        color: theme.palette.common.black,
        fontSize: 16,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.white,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function TableComponent({ columns, rows, handleDelete, handleDeleteSelected }) {
    const [selected, setSelected] = useState([]);

    // Handle selecting all rows
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((row) => row.mail);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // Handle individual checkbox click
    const handleCheckboxClick = (mail) => {
        const selectedIndex = selected.indexOf(mail);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, mail);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    // Check if a row is selected
    const isSelected = (mail) => selected.indexOf(mail) !== -1;

    return (
        <div>
            {selected.length > 0 && (
                <DeleteIcon
                    sx={{ cursor: "pointer", mt: "20px", mb: "-13px", color: "red" }}
                    titleAccess="Delete Selected"
                    onClickCapture={() => handleDeleteSelected(selected)}
                    size="medium"
                ></DeleteIcon>
            )}
            <TableContainer
                component={Paper}
                sx={{ marginTop: 3, maxHeight: "70vh", border: 1, borderColor: "grey.300" }}
            >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                            <StyledTableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < rows.length}
                                    checked={rows.length > 0 && selected.length === rows.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{ "aria-label": "select all rows" }}
                                />
                            </StyledTableCell>
                            {columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align || "left"}
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                            <StyledTableCell align="left">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            const isItemSelected = isSelected(row.mail);
                            return (
                                <StyledTableRow key={row.mail} selected={isItemSelected} hover>
                                    <StyledTableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected}
                                            onChange={() => handleCheckboxClick(row.mail)}
                                            inputProps={{ "aria-label": `select row ${row.mail}` }}
                                        />
                                    </StyledTableCell>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.id}
                                            align={column.align || "left"}
                                            sx={{ paddingX: 2 }}
                                        >
                                            {row[column.id]}
                                        </StyledTableCell>
                                    ))}
                                    <StyledTableCell align="center">
                                        <DeleteIcon
                                            sx={{ cursor: "pointer", color: "red", "&:hover": { color: "darkred" } }}
                                            titleAccess="Delete"
                                            onClick={() => handleDelete(row.mail)}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TableComponent;
