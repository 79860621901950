import React, { useState } from "react";
import { Box, Grid, Card, CardContent, Typography, CardActionArea, Avatar } from "@mui/material";
import ActionComponent from "./ActionComponent";
import OverviewPanel from "./OverviewPanel";
import BlacklistPanel from "./BlacklistPanel";
import TemplatePanel from "./TemplatePanel";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

const ExampleDashboard = () => {
    const [selectedPanel, setSelectedPanel] = useState("DNS OverView");
    const [isAccordionOpen, setAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setAccordionOpen(!isAccordionOpen);
    };

    const closeAccordion = () => {
        setAccordionOpen(false);
    };

    const handleDelist = (type, url) => {
        // Handle delist logic here
        console.log(`Delist ${type} - ${url}`);
    };

    const dnsRecords = [
        { name: "dns record 1", status: "Passing" },
        { name: "dns record 2", status: "Failing" },
    ];

    const domainTest = [
        { name: "domain test 1", status: "Passing" },
        { name: "domain test 2", status: "Failing" },
    ];

    const ipTests = [
        { name: "ip test 1", status: "Passing" },
        { name: "ip test 2", status: "Failing" },
    ];

    const domainBlacklist = [
        { name: "domain 1", url: "https://example.com" },
        { name: "domain 1", url: "https://example.com" },
        { name: "domain 1", url: "https://example.com" },
        { name: "domain 1", url: "https://example.com" },
        { name: "domain 1", url: "https://example.com" },
        { name: "domain 1", url: "https://example.com" },
    ];
    const ipBlacklist = [
        { name: "ip 1", url: "https://example.com" },
        { name: "ip 1", url: "https://example.com" },
        { name: "ip 1", url: "https://example.com" },
        { name: "ip 1", url: "https://example.com" },
        { name: "ip 1", url: "https://example.com" },
        { name: "ip 1", url: "https://example.com" },
    ];

    const handlePanelSelect = (panel) => {
        setSelectedPanel(panel);
    };
    const chartData = {
        labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
        datasets: [
            {
                label: "Series 1",
                data: [300, 500, 750, 250, 600, 900, 650, 400, 700],
                borderColor: "rgba(75, 192, 192, 1)",
                fill: false,
            },
            {
                label: "Series 2",
                data: [200, 700, 650, 500, 750, 600, 900, 400, 750],
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
            },
        ],
    };

    const allscoreCard = {
        score: 87,
        maxScore: 100,
        scoreType: "High Score",
    };
    const cardData = [
        {
            title: "IPT",
            subtitle: "Inbox Count",
            count: 20,
        },
        {
            title: "IPT",
            subtitle: "Spam Count",
            count: 35,
        },
    ];

    const InboxingEspcardData = [
        {
            title: "Inboxing ESP",
            subtitle: "Inbox Count",
            count: 20,
        },
        {
            title: "Inboxing ESP",
            subtitle: "Spam Count",
            count: 35,
        },
    ];

    const dummyData = [
        {
            icon: <RocketLaunchIcon />,
            value: "20",
            description: "Per day volume",
        },
        {
            icon: <ChatBubbleIcon />,
            value: "50",
            description: "Per day warmup volume",
        },
    ];

    const templates = [
        {
            id: 1,
            name: "Template 1",
            subject:
                "There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain",
            body: `<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body>
    <div style="box-sizing:inherit">Hi there, we're reaching out to a few businesses in your area, and yours came up.
    </div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Curious– if we could show you the Top Business Asset that other businesses use to
        THRIVE even in uncertain economies that we could install in your company…</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Would you be interested in learning more?</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">What we do is install client acquisition systems into businesses like yours (because
        getting clients is the first thing you can control during uncertain times.)</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">And this allows you to:</div>
    <div style="box-sizing:inherit">(1) Gain predictable revenue month over month</div>
    <div style="box-sizing:inherit">(2) Build certainty about where your next client is coming from</div>
    <div style="box-sizing:inherit">(3) Build a truly scalable business (because if you don't have predictable client
        growth, you can't scale)</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Look, one of our clients used this exact system to generate significant start-up
        revenue in under 6 months.</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Would love to show you the process. What day/time works best?</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">Excited to connect with you.</div>
    <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
    <div style="box-sizing:inherit">
        <div style="box-sizing:inherit">Ava Johnson</div>
        <div style="box-sizing:inherit">Marketing Director at PEMA. io&nbsp;</div>
        <div style="box-sizing:inherit">PEMA LLC</div>
        <div style="box-sizing:inherit">1000 Brickell Avenue, Miami FL</div>
        <div style="box-sizing:inherit"><br style="box-sizing:inherit"></div>
        <div style="box-sizing:inherit">P.S. Reply "Unsub" if you do not wish to receive emails.&nbsp;</div>
    </div>
</body>
</html>`,
            value: "90",
        },
        { id: 2, name: "Template 2", subject: "Subject 2", body: "<p>Sample body 2</p>", value: "90" },
        { id: 3, name: "Template 3", subject: "Subject 3", body: "<p>Sample body 3</p>", value: "90" },
        { id: 4, name: "Template 1", subject: "Subject 4", body: "<p>Sample body 1</p>", value: "90" },
        { id: 5, name: "Template 2", subject: "Subject 5", body: "<p>Sample body 2</p>", value: "35" },
        { id: 6, name: "Template 3", subject: "Subject 6", body: "<p>Sample body 3</p>", value: "25" },
    ];

    const spamWords = ["free", "offer", "click", "money"];

    return (
        <Box>
            <Grid container spacing={2} columns={16}>
                <Grid item xs={3} sm={8} md={4}>
                    <Card
                        sx={{
                            width: "100%",

                            mb: 2,
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            color: "white",
                            backgroundColor: "#0884e2",
                        }}
                    >
                        <CardContent sx={{ textAlign: "center" }}>
                            <Typography variant="h4">{allscoreCard.scoreType}</Typography>
                            <Typography variant="h2">{allscoreCard.score}</Typography>
                            <Typography variant="subtitle1">out of {allscoreCard.maxScore}</Typography>
                        </CardContent>
                        <div style={{ padding: "10px" }}>
                            <Typography sx={{ padding: "10px", backgroundColor: "#9EC4DF", borderRadius: "5px" }}>
                                The score is calculated directly from these metrics: inbox rate, domain health, and IP
                                insights
                            </Typography>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={3} sm={8} md={4}>
                    {cardData.map((data, index) => (
                        <Card
                            key={index}
                            sx={{
                                display: "flex",
                                width: "100%",
                                maxWidth: "400px",
                                height: "120px",
                                "&:hover": { backgroundColor: "#F7F7F9" },
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                mb: 2,
                            }}
                        >
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {data.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {data.subtitle}
                                    </Typography>
                                    <Typography variant="h5" color="primary">
                                        {data.count}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </Grid>
                <Grid item xs={3} sm={8} md={4}>
                    {InboxingEspcardData.map((data, index) => (
                        <Card
                            key={index}
                            sx={{
                                width: "100%",
                                maxWidth: "400px",
                                height: "120px",
                                "&:hover": { backgroundColor: "#F7F7F9" },
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                mb: 2,
                            }}
                        >
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {data.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {data.subtitle}
                                    </Typography>
                                    <Typography variant="h5" color="primary">
                                        {data.count}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </Grid>

                <Grid item xs={3} sm={8} md={4}>
                    {dummyData.map((data, index) => (
                        <Card
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "120px",
                                p: 2,
                                mb: 2,
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                "&:hover": { backgroundColor: "#F7F7F9" },
                            }}
                        >
                            <Avatar sx={{ bgcolor: "#E0E0E0", mr: 2 }}>{data.icon}</Avatar>
                            <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                                <Typography variant="h5" component="div">
                                    {data.value}
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    {data.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <ActionComponent onSelect={handlePanelSelect} />
                </Grid>
                <Grid item xs={12} md={9}>
                    {selectedPanel === "Blacklist" && (
                        <OverviewPanel
                            domainBlacklist={domainBlacklist}
                            ipBlacklist={ipBlacklist}
                            handleDelist={handleDelist}
                        />
                    )}
                    {selectedPanel === "DNS OverView" && (
                        <BlacklistPanel
                            isAccordionOpen={isAccordionOpen}
                            toggleAccordion={toggleAccordion}
                            closeAccordion={closeAccordion}
                            dnsRecords={dnsRecords}
                            domainTest={domainTest}
                            ipTests={ipTests}
                        />
                    )}
                    {selectedPanel === "TemplateList" && <TemplatePanel templates={templates} spamWords={spamWords} />}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ExampleDashboard;
