import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import "./spreadsheet.css";
import { Box, Typography, TextField, InputAdornment, Skeleton, Snackbar, Alert, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CSVLink } from "react-csv";
import moment from "moment";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import SearchIcon from "@mui/icons-material/Search";
import googleImg from "../../img/icons/google.png";
import outlookImg from "../../img/icons/outlook.png";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation } from "react-router-dom"; // Import Link from react-router-dom
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { withStyles } from "@mui/styles";
import { Button, Dialog, DialogContent, Grid, CircularProgress, IconButton } from "@mui/material";
import google from "../../img/icons/google.png";
import imap_smtp from "../../img/icons/imap_smtp.png";
import { DialogActions } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MuiAlert from "@mui/material/Alert";
import { Accordian } from "../manualAudit/ManualAccordian/Accordian";
import InfoIcon from "@mui/icons-material/Info";
import CustomizedDialogs from "./CustomizedDialogs";
import { subDays, format } from "date-fns";
import DomainListDialog from "./DomainListDialog";
import empty from "../../img/icons/empty.png";
import { FlareSharp } from "@mui/icons-material";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F8F9FB",
        color: theme.palette.common.black,
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "center",
        padding: "15px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        textAlign: "center",
        padding: "10px",
        backgroundColor: "#fff",
    },
}));
const StyledButton = withStyles({
    root: {
        backgroundColor: "#fff",
        border: "1px solid #E7E7E7",
        "&:hover": {
            backgroundColor: "#fff",
            boxShadow: "0px 8px 40px rgba(4, 0, 49, 0.16)",
        },
    },
})(Button);
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: "#fff",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const CustomTooltip = styled(Tooltip)(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: "white",
        color: "black",
    },
    [`& .MuiTooltip-arrow`]: {
        color: "white",
    },
}));

const StyledMuiAlert = styled(MuiAlert)(({ theme }) => ({
    icon: {
        color: "red",
    },
    backgroundColor: "#FF5722",
    color: "white",
}));

const SpreadSheet = () => {
    // const history = useHistory();
    const [notifiFeature, setNotifiFeature] = useState("");
    const [notifiInterval, setNotifiInterval] = useState("");
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filter, setFilter] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [isDateSelected, setIsDateSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [slackshow, setSlackshow] = useState(false);
    const [slackurl, setslackurl] = useState("");
    const [notifibox, setNotifiBox] = useState(true);
    const [successMsg, setsuccessMsg] = useState("");
    const location = useLocation();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [alignment1, setAlignment1] = React.useState("Insights");
    const [Insights, setInsights] = useState(true);
    const [Health, setHealth] = useState(false);
    const message = location.state?.message;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState({
        title: "",
        description: "",
        email: "",
        descriptiondateRange: "",
        description1: "",
        description2: "",
    });

    const handleCleanClick = (item) => {
        setDialogContent({
            //   title: `Cleaning up `,
            description: (
                <span style={{ fontFamily: "sans-serif" }}>
                    You are about to delete all messages from
                    <br></br>
                    <span style={{ color: "blue", fontWeight: "bold", fontWeight: 400 }}>{item.email}</span> received
                    before and on
                    <br></br>
                    <span style={{ color: "blue", fontWeight: "bold", fontWeight: 400 }}>
                        {" "}
                        {formattedPreviousDay}
                    </span>.{" "}
                    <span style={{ color: "black", fontWeight: "bold", fontWeight: 500 }}>
                        <br />
                        Please note that this action is permanent
                    </span>
                    .
                </span>
            ),
            email: `${item.email}`,
            descriptiondateRange: `${formattedPreviousDay}`,
            description1: <span>Select a date range to delete messages </span>,
            description2: <span>Since Inception to</span>,
        });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        if (message) {
            setSnackbarSeverity("error");
            setSnackbarMessage(message);
            setSnackbarOpen(true);
        }
    }, [message]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        const today = moment();
        const previousMonth = moment().subtract(1, "month");
        setStartDate(previousMonth.toDate());
        setEndDate(today.toDate());
    }, []);
    const [clientbox, setClientBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [smtpDialogBox, setSMTPDialogBox] = useState(false);
    const [smtpDialogBox1, setSMTPDialogBox1] = useState(false);
    const [smtpUsername, setSmtpUsername] = useState("");
    const [smtpPassword, setSmtpPassword] = useState("");
    const [smtpHost, setSmtpHost] = useState("");
    const [smtpPort, setSmtpPort] = useState("");
    const [imapHost, setImapHost] = useState("");
    const [imapPort, setImapPort] = useState("");
    const [imapPassword, setImapPassword] = useState("");
    const [ImapDialogBox, setIMAPDialogBox] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [openDomainboxDialog, setOpenDomainboxDialog] = useState(false);
    const [domainList, setDomainList] = useState([{ mail: "", exist: "" }]);
    const [bccemail, setBccEmail] = useState([]);
    const [deleteloading, setDeleteLoading] = useState(false);
    const [addloading, setAddLoading] = useState(false);
    useEffect(() => {
        if (startDate && endDate) {
            fetchMailAudit(startDate, endDate);
            setIsDateSelected(true);
        }
    }, [startDate, endDate]);

    function clientDialogbox() {
        setClientBox(true);
    }

    function handleclose() {
        setClientBox(false);
        setSMTPDialogBox(false);
        setSMTPDialogBox1(false);
    }

    function Smpt_set1() {
        setSMTPDialogBox(true);
        setClientBox(false);
        setSMTPDialogBox1(false);
    }

    function Smpt_set2() {
        setSMTPDialogBox1(true);
        setSMTPDialogBox(false);
    }

    function ImapDialogbox() {
        setIMAPDialogBox(true);
        setSMTPDialogBox1(false);
    }

    const GoogleAuth = async () => {
        try {
            const response = await fetch(`${REACT_APP_SERVER_URL}/googlereact`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            window.location.href = data.data;
        } catch (error) {
            console.error("Error during Google authentication:", error);
        }
    };

    const smtpDataHandler = () => {
        fetch(`${REACT_APP_SERVER_URL}/onBoardBccImap`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                mail: email,
                imap_port: imapPort,
                imap_host: imapHost,
                credentials: smtpPassword,

                smtp_host: smtpHost,
                smtp_port: smtpPort,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage(data.message);
                    setIMAPDialogBox(false);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                    setIMAPDialogBox(false);
                }
            })
            .catch((error) => {
                setErrorMessage("Error: " + error.message);
            });
    };

    useEffect(() => {
        if (filter) {
            setFilteredData(data.filter((row) => row.domainName.toLowerCase().includes(filter.toLowerCase())));
        } else {
            setFilteredData(data);
        }
    }, [filter, data]);

    const fetchMailAudit = async (startDate, endDate) => {
        try {
            setIsLoading(true);

            const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
            const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

            const response = await fetch(
                `${REACT_APP_SERVER_URL}/fetchMailAuditV1?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    method: "GET",
                }
            );
            const result = await response.json();
            setLoading(false);
            if (result.status === 200) {
                setData(result.data);
            }
        } catch (error) {
            console.error("Error fetching mail audit data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };

    const handleDateRangeApply = (dates) => {
        if (dates && dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        } else if (!dates) {
            setStartDate(null);
            setEndDate(null);
        }
    };

    const DaliyNotification = async (event) => {
        const selectedValue = event.target.value;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/notification_interval`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({ notification_interval: selectedValue }),
            });

            const result = await response.json();
            console.log("-------+++++++++++---------", result);
            if (result.status === 200) {
                setNotifiInterval(selectedValue);
                setsuccessMsg("Notification preferences updated successfully!");
                setSuccessSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error updating notification preferences:", error);
        }
    };

    const handleAuditClick = async (row) => {
        console.log("inside handleAuditClick");
        // Implement your audit logic here, for example:
        console.log("Performing audit for row:", row);
        // You can navigate to a new page, show a modal, or perform any other action based on the row data.
        // history.push("/audit-report");
        const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
        const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
        window.location.href = `/dashboard/audit-report?fromDate=${formattedStartDate}&toDate=${formattedEndDate}&domainName=${row.domainName}`;
    };

    const updateNotificationSettings = async (notificationType, slackUrl = "") => {
        const response = await fetch(`${REACT_APP_SERVER_URL}/bcc/notification`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                notificationType,
                slackUrl,
            }),
        });
        const data = await response.json();
        if (response.status === 200) {
            setSuccessSnackbarOpen(true);
            setsuccessMsg(data.msg);
        } else {
            setErrorSnackbarOpen(true);
            setErrorMessage(data.msg);
        }
    };
    const notifiFunction = (e) => {
        if (e === "slack") {
            setSlackshow(true);
        } else {
            setSlackshow(false);
            updateNotificationSettings(e);
        }
    };
    const SaveSlackUrl = (url) => {
        if (url === "") {
            setErrorSnackbarOpen(true);
            setErrorMessage("SlackWebhook url is empty");
            return;
        }
        updateNotificationSettings("slack", url);
    }; // style={accordianStyle[index] && accordianStyle[index]}

    const HandleChange1 = (event, newAlignment) => {
        console.log("alignment", newAlignment);
        if (newAlignment === "Insights") {
            setInsights(true);
            setHealth(false);
            setAlignment1("Insights");
        } else if (newAlignment === "Health") {
            setInsights(false);
            setHealth(true);
            setAlignment1("Health");
        }
    };

    const handlebccOnboardUsersList = () => {
        fetch(`${REACT_APP_SERVER_URL}/bccOnboardUsersList`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    setDomainList(data.data);
                    setOpenDomainboxDialog(true);
                } else {
                    setErrorMessage("Domain not found");
                    setErrorSnackbarOpen(true);
                }
            })
            .catch((error) => {
                console.error("Error fetching domain list:", error);
            });
    };

    const onboardUsers = async (mail) => {
        setAddLoading(true);
        try {
            const response = await fetch(`${REACT_APP_SERVER_URL}/bccOnboardUsers`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({ mails: mail }),
            });

            const data = await response.json();

            if (response.ok) {
                setAddLoading(false);
                setOpenDomainboxDialog(false);
                setSuccessSnackbarOpen(true);
                setSuccessMessage(data.message);
            } else {
                console.error("Error:", data);
            }
        } catch (error) {
            console.error("Fetch error:", error);
        }
    };

    const currentDate = new Date();
    const previousDay = subDays(currentDate, 1);
    const formattedPreviousDay = format(previousDay, "dd-MM-yyyy");

    const handleCloseDomainDialog = () => {
        setOpenDomainboxDialog(false);
    };
    const handledomainClose = () => {
        setOpenDomainboxDialog(false);
    };

    useEffect(() => {
        fetchHealthToggleApi();
    }, []);

    const fetchHealthToggleApi = () => {
        fetch(`${REACT_APP_SERVER_URL}/fetchHealthToggleApi`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                console.log("hit the api");
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    if (data.data && data.data.length > 0) {
                        setBccEmail(data.data);
                    } else {
                        setErrorMessage("Data not found");
                        setErrorSnackbarOpen(true);
                    }
                } else {
                    setErrorMessage("Bcc Email not found");
                    setErrorSnackbarOpen(true);
                }
            })
            .catch((error) => {
                console.error("Error fetching Bcc Email:", error);
                setErrorMessage("Error fetching data");
                setErrorSnackbarOpen(true);
            });
    };

    const NoDataImage = styled("img")(({ theme }) => ({
        width: "200px",
        height: "auto",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }));

    const NoDataFound = ({ onRefresh }) => (
        <Box sx={{ textAlign: "center" }}>
            <NoDataImage src={empty} alt="No data" />
            <Typography variant="body1" color="textSecondary">
                No data found, Try refreshing or adjusting your search criteria.
            </Typography>
        </Box>
    );

    const cleanBccMailBox = async (...args) => {
        setDeleteLoading(true);
        let body = "";
        // if (args.length === 1) {
        //     body = JSON.stringify({
        //         date: args[0],
        //     });
        // } else {
        //     body = JSON.stringify({
        //         mail: args[0],
        //         date: args[1],
        //     });
        // }
        body = JSON.stringify({
            mail: args[0],
            date: args[1],
        });
        try {
            console.log("hitting this function...");
            const response = await fetch(`${REACT_APP_SERVER_URL}/cleanBccMailBox`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: body,
            });

            const data = await response.json();
            if (response.ok) {
                console.log("Success:", data);
                setDeleteLoading(false);
                setOpenDialog(false);
                setSuccessSnackbarOpen(true);
                setSuccessMessage(data.message);
            } else {
                console.error("Error:", data);
                setOpenDialog(false);
            }
        } catch (error) {
            console.error("Fetch error:", error);
        }
    };

    return (
        <Box>
            <Box>
                <Typography variant="h5" gutterBottom>
                    BccMail Data
                </Typography>
                <Box
                    sx={{
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <ToggleButtonGroup
                        variant="solid"
                        value={alignment1}
                        exclusive
                        onChange={HandleChange1}
                        aria-label="Platform"
                        sx={{ marginBottom: "20px", width: "50%", height: "40px" }}
                    >
                        <ToggleButton
                            style={{
                                width: "auto",
                                padding: "10px",
                                color: alignment1 === "Insights" ? "white" : "black",
                                backgroundColor: alignment1 === "Insights" ? "#004aad" : "white",
                                letterSpacing: "1px",
                            }}
                            value="Insights"
                        >
                            Insights
                        </ToggleButton>
                        <Tooltip
                            title="Review the status of your BCC IDs to assess their overall health"
                            placement="top"
                        >
                            <ToggleButton
                                style={{
                                    width: "auto",
                                    padding: "10px",
                                    color: alignment1 === "Health" ? "white" : "black",
                                    backgroundColor: alignment1 === "Health" ? "#004aad" : "white",
                                    letterSpacing: "2px",
                                }}
                                value="Health"
                            >
                                Health
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            {Insights && (
                <>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                className="btn-add"
                                style={{ marginRight: "10px", backgroundColor: "#004aad" }}
                                variant="contained"
                                onClick={handlebccOnboardUsersList}
                            >
                                Add New
                            </Button>
                            {filteredData.length > 0 ? (
                                <CSVLink
                                    className="csvlsetManualink"
                                    data={filteredData}
                                    filename={"Download file.csv"}
                                >
                                    <Button variant="contained" className="csvlink-btn">
                                        Download CSV
                                    </Button>
                                </CSVLink>
                            ) : (
                                <Button variant="contained" className="csvlink-btn" disabled>
                                    Download CSV
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Box className="textfield">
                        <Box>
                            <TextField
                                className="inputfield"
                                label="Filter by Domain"
                                variant="outlined"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ marginRight: "10px" }}
                            />
                            <DateRangePicker
                                value={[startDate, endDate]}
                                onChange={handleDateRangeApply}
                                defaultValue={[startDate, endDate]}
                                className="custom-date-picker"
                                containerStyle={{ zIndex: 10000 }}
                                panelStyle={{ zIndex: 10000 }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Get Daily Notifications Reports</InputLabel>
                                <Select
                                    label="Get Daily Notifications Reports"
                                    id="demo-simple-select"
                                    value={notifiInterval}
                                    onChange={DaliyNotification}
                                    size="small"
                                    style={{ width: "250px", marginRight: "10px" }}
                                >
                                    <MenuItem value="none">None</MenuItem>
                                    <MenuItem value="daily">Daily</MenuItem>
                                    <MenuItem value="weekly">Weekly</MenuItem>
                                    <MenuItem value="bi-weekly">Bi-Weekly</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="demo-simple-select-standard-label">Get Daily BCC Reports</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={notifiFeature}
                                    size="small"
                                    style={{ width: "200px" }}
                                    onChange={(e) => {
                                        let selectFeature = e.target.value;
                                        setNotifiFeature(selectFeature);
                                        notifiFunction(selectFeature);
                                    }}
                                    label="Get Daily Blacklist Reports"
                                >
                                    <MenuItem value="none">None</MenuItem>
                                    <MenuItem value="slack">SLACK</MenuItem>
                                    <MenuItem value="mail">EMAIL</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: "20px", minHeight: "200px", position: "relative" }}>
                        <TableContainer component={Paper} elevation={3}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>DOMAIN NAME</StyledTableCell>
                                        <StyledTableCell align="right">COUNT</StyledTableCell>
                                        <StyledTableCell align="right">ESP</StyledTableCell>
                                        <StyledTableCell align="right">REPORT</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading ? (
                                        [...Array(5)].map((_, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>
                                                    <Skeleton variant="rectangular" height={40} />
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <Skeleton variant="rectangular" height={40} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Skeleton variant="rectangular" height={40} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Skeleton variant="rectangular" height={40} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    ) : filteredData.length === 0 ? (
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={4} align="center">
                                                {isDateSelected ? (
                                                    <Typography variant="body1">
                                                        No data available for selected date range.
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1">
                                                        Please select a date range to view data.
                                                    </Typography>
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ) : (
                                        filteredData.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>
                                                    <Link
                                                        className="dmain-link"
                                                        to={`/dashboard/bcc-details?fromDate=${moment(startDate).format("YYYY-MM-DD")}&toDate=${moment(endDate).format("YYYY-MM-DD")}&domainName=${row.domainName}`}
                                                    >
                                                        {row.domainName}
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {`${row.totalCount} (`}
                                                    <span style={{ color: "green" }}>{row.inboxCount}</span>
                                                    {`/`}
                                                    <span style={{ color: "red" }}>{row.spamCount}</span>
                                                    {`)`}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.esp.google && (
                                                        <div style={{ margin: "10px" }}>
                                                            <img
                                                                src={googleImg}
                                                                alt="Google"
                                                                style={{ width: "24px", marginRight: "8px" }}
                                                            />
                                                            {`${(row.esp.google.inbox || 0) + (row.esp.google.spam || 0)} `}
                                                            <span style={{ color: "green", marginLeft: "4px" }}>
                                                                ({row.esp.google.inbox || 0}/
                                                            </span>
                                                            <span style={{ color: "red", marginLeft: "4px" }}>
                                                                {row.esp.google.spam || 0})
                                                            </span>
                                                        </div>
                                                    )}
                                                    {row.esp.outlook && (
                                                        <div>
                                                            <img
                                                                src={outlookImg}
                                                                alt="Outlook"
                                                                style={{ width: "24px", marginRight: "8px" }}
                                                            />
                                                            {`${(row.esp.outlook.inbox || 0) + (row.esp.outlook.junkemail || 0)} `}
                                                            <span style={{ color: "green", marginLeft: "4px" }}>
                                                                ({row.esp.outlook.inbox || 0}/
                                                            </span>
                                                            <span style={{ color: "red", marginLeft: "4px" }}>
                                                                {row.esp.outlook.junkemail || 0})
                                                            </span>
                                                        </div>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Link
                                                        to={`/dashboard/audit-report?fromDate=${moment(startDate).format("YYYY-MM-DD")}&toDate=${moment(endDate).format("YYYY-MM-DD")}&domainName=${row.domainName}`}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            // onClick={() => handleAuditClick(row)}
                                                            className="audit-btn"
                                                        >
                                                            Audit
                                                        </Button>
                                                    </Link>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            )}

            {Health && (
                <>
                    <Box>
                        {bccemail.length > 0 ? (
                            bccemail.map((item, index) => (
                                <Accordian key={index} title={item.email} healthStatus={item.healthStatus}>
                                    <>
                                        <Typography style={{ color: "gray" }} sx={{ mb: 2 }}>
                                            -This record was calculated based on the data from the previous day.
                                        </Typography>
                                        <Box className="Accordian-mainBox">
                                            <Box className="typo-box">
                                                <Typography style={{ marginRight: 8 }}>
                                                    Sent per day: {item.dailyVolume}
                                                </Typography>
                                                <CustomTooltip title="Daily sent volume" placement="top">
                                                    <InfoIcon fontSize="small" color="action" />
                                                </CustomTooltip>
                                            </Box>
                                            <Box className="typo-box">
                                                <Typography style={{ marginRight: 8 }}>
                                                    Sent per hour: {item.hourlyAverageVolume}
                                                </Typography>
                                                <Tooltip title="Average hourly volume per day" placement="top">
                                                    <InfoIcon fontSize="small" color="action" />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                        <Box className="box-btn">
                                            <Button
                                                className="btn"
                                                variant="contained"
                                                style={{ backgroundColor: "red" }}
                                                onClick={() => handleCleanClick(item)}
                                            >
                                                Purge Mailbox
                                            </Button>
                                        </Box>
                                    </>
                                </Accordian>
                            ))
                        ) : (
                            <NoDataFound onRefresh={fetchHealthToggleApi} />
                        )}

                        <CustomizedDialogs
                            open={openDialog}
                            onClose={handleCloseDialog}
                            title={dialogContent.title}
                            description={dialogContent.description}
                            description1={dialogContent.description1}
                            email={dialogContent.email}
                            descriptiondateRange={dialogContent.descriptiondateRange}
                            description2={dialogContent.description2}
                            Add={(e, d) => cleanBccMailBox(e, d)}
                            loading={deleteloading}
                        />
                    </Box>
                </>
            )}
            <DomainListDialog
                open={openDomainboxDialog}
                onClose={handleCloseDomainDialog}
                domainList={domainList}
                onAdd={onboardUsers}
                Close={handledomainClose}
                loading={addloading}
            />

            <Dialog
                open={clientbox}
                maxWidth="md"
                PaperProps={{
                    style: {
                        borderRadius: "10px",
                        paddingTop: "24px",
                        paddingBottom: "24px",
                    },
                }}
            >
                <Box className="dialog-mainbox">
                    <Box className="dailog-hader">
                        <Typography className="hader-typ">Connect a new BCC account</Typography>
                    </Box>
                    <DialogContent>
                        <StyledButton className="styled-btn" onClick={GoogleAuth} fullWidth>
                            <Box>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={3}>
                                        <img src={google} alt="google" style={{ width: "40px" }} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                color: "#9491AD",
                                            }}
                                        >
                                            Google
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                color: "#000",
                                            }}
                                        >
                                            Google / G-Suite
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </StyledButton>

                        {/* <StyledButton
                            sx={{
                                padding: "16px",
                                marginTop: "16px",
                                marginBottom: "32px",
                            }}
                            // onClick={outlookHit}
                            fullWidth
                        >
                            <Box>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={3}>
                                        <img src={outlook} alt="outlook" style={{ width: "50px" }} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                color: "#9491AD",
                                            }}
                                        >
                                            Outlook
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                color: "#000",
                                            }}
                                        >
                                            Outlook / Microsoft365
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </StyledButton> */}
                        <StyledButton
                            sx={{
                                p: 2,
                                mb: 2,
                            }}
                            onClick={Smpt_set1}
                            fullWidth
                        >
                            <Box>
                                <Grid
                                    container
                                    spacing={3}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Grid item xs={0} sm={0} md={3} lg={3} xl={3}>
                                        <img src={imap_smtp} alt="imap_smtp" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                color: "#9491AD",
                                            }}
                                        >
                                            Any provider
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                color: "#000",
                                            }}
                                        >
                                            IMAP / SMTP
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </StyledButton>
                    </DialogContent>
                </Box>

                <Box className="dailog-footer">
                    <Button className="footer-btn" variant="contained" onClick={handleclose}>
                        Cancel
                    </Button>
                </Box>
            </Dialog>
            <>
                <Dialog
                    open={smtpDialogBox}
                    PaperProps={{
                        style: {
                            borderRadius: "20px",
                            padding: "24px",
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 4,
                        }}
                    >
                        <img src={imap_smtp} alt="imap_smtp" style={{ float: "left", width: "25px" }} />
                        <Box sx={{ ml: 2, textAlign: "center" }}>
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: "700",
                                }}
                            >
                                Connect any provider
                            </Typography>
                            <Typography>IMAP / SMTP</Typography>
                        </Box>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                label="First Name"
                                type="text"
                                required
                                fullWidth
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                label="Last Name"
                                type="text"
                                required
                                fullWidth
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        fullWidth
                        type="email"
                        label="Email"
                        required
                        value={email}
                        onChange={(e) => {
                            setSmtpUsername(e.target.value);
                            setEmail(e.target.value);
                        }}
                        sx={{ my: 2 }}
                    />
                    <Button
                        disabled={!firstName || !lastName || !email}
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "#38C68B",
                            p: 1,
                            mt: 2,
                            "&:disabled": {
                                backgroundColor: "#d3d3d3",
                            },
                        }}
                        onClick={Smpt_set2}
                    >
                        Next
                    </Button>

                    <DialogActions>
                        <Box
                            sx={{
                                width: "100%",
                                fontWeight: "700",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "14px",
                            }}
                        >
                            <IconButton onClick={clientDialogbox}>
                                <ArrowBackIcon />
                            </IconButton>
                            Select another provider
                        </Box>
                        <Box sx={{ my: 3 }}>
                            <Button
                                sx={{
                                    py: 1,
                                    color: "#481BEB",
                                    borderColor: "#E7E7E7",
                                    fontSize: "12px",
                                    fontFamily: "'Inter', sans-serif",
                                    "&:hover": {
                                        borderColor: "#E7E7E7",
                                    },
                                }}
                                variant="outlined"
                                onClick={handleclose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={smtpDialogBox1}
                    PaperProps={{
                        style: {
                            borderRadius: "20px",
                            padding: "24px",
                        },
                    }}
                >
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                            }}
                        >
                            <img src={imap_smtp} alt="imap_smtp" sx={{ float: "left", width: "25px" }} />
                            <Box sx={{ ml: 2 }}>
                                <Typography
                                    sx={{
                                        fontSize: "18px",
                                        fontWeight: "700",
                                    }}
                                >
                                    Connect to
                                </Typography>
                                <Typography>SMTP</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <TextField
                                fullWidth
                                label="Username"
                                required
                                value={smtpUsername}
                                onChange={(e) => setSmtpUsername(e.target.value)}
                                sx={{}}
                            />
                            <TextField
                                fullWidth
                                type="password"
                                label="SMTP Password"
                                onChange={(e) => setSmtpPassword(e.target.value)}
                                required
                                sx={{ my: 2 }}
                            />
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextField
                                        required
                                        label="SMTP Host"
                                        value={smtpHost}
                                        fullWidth
                                        onChange={(e) => setSmtpHost(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextField
                                        required
                                        label="SMTP Port"
                                        type="number"
                                        value={smtpPort}
                                        fullWidth
                                        onChange={(e) => setSmtpPort(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    disabled={
                                        // buttonLoading ||
                                        smtpUsername === "" || smtpPassword === "" || smtpHost === "" || smtpPort === ""
                                    }
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "#38C68B",
                                        p: 1,
                                        mt: 2,
                                    }}
                                    onClick={ImapDialogbox}
                                >
                                    Next
                                </Button>
                            </Box>
                        </Box>
                        <DialogActions>
                            <Box
                                sx={{
                                    width: "100%",
                                    fontWeight: "700",
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "14px",
                                }}
                            >
                                <IconButton onClick={Smpt_set1}>
                                    <ArrowBackIcon />
                                </IconButton>
                                Select another provider
                            </Box>
                            <Box sx={{ my: 3 }}>
                                <Button
                                    sx={{
                                        py: 1,
                                        color: "#481BEB",
                                        borderColor: "#E7E7E7",
                                        fontSize: "12px",
                                        fontFamily: "'Inter', sans-serif",
                                        "&:hover": {
                                            borderColor: "#E7E7E7",
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={handleclose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </DialogActions>
                    </>
                </Dialog>

                <Dialog
                    open={ImapDialogBox}
                    PaperProps={{
                        style: {
                            borderRadius: "20px",
                            padding: "24px",
                        },
                    }}
                >
                    <></>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 4,
                        }}
                    >
                        <img src={imap_smtp} alt="imap_smtp" style={{ float: "left", width: "25px" }} />
                        <Box sx={{ ml: 2, textAlign: "center" }}>
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: "700",
                                }}
                            >
                                Connect any provider
                            </Typography>
                            <Typography>IMAP</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <TextField
                            fullWidth
                            type="password"
                            label="IMAP Password"
                            onChange={(e) => setImapPassword(e.target.value)}
                            required
                            sx={{ my: 2 }}
                        />
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <TextField
                                    required
                                    label="IMAP Host"
                                    value={imapHost}
                                    fullWidth
                                    onChange={(e) => setImapHost(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <TextField
                                    required
                                    label="IMAP Port"
                                    type="number"
                                    value={imapPort}
                                    fullWidth
                                    onChange={(e) => setImapPort(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                disabled={
                                    // buttonLoading ||
                                    imapPassword === "" || imapHost === "" || imapPort === ""
                                }
                                variant="contained"
                                fullWidth
                                sx={{
                                    backgroundColor: "#38C68B",
                                    p: 1,
                                    mt: 2,
                                }}
                                onClick={smtpDataHandler}
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </>
            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                // anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <StyledMuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {snackbarMessage}
                </StyledMuiAlert>
            </Snackbar>
        </Box>
    );
};

export default SpreadSheet;
