import React from "react";
import { Typography, Paper, Grid, List, ListItem, ListItemText, Button, Skeleton } from "@mui/material";

const BlacklistPanel = ({ loading, domainBlacklist, ipBlacklist, handleDelist }) => {
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper
                        sx={{
                            padding: "20px",
                            border: "1px solid #f1f1f1",
                            borderRadius: "5px",
                            backgroundColor: "#fff",
                            height: "300px",
                            overflowY: "auto",
                            boxShadow: "none !important",
                        }}
                    >
                        {loading ? (
                            <Skeleton variant="text" width={200} height={40} />
                        ) : (
                            <Typography
                                sx={{
                                    color: "#24a0ed",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                Domain Blacklist
                            </Typography>
                        )}
                        {loading ? (
                            <List>
                                {[...Array(3)].map((_, index) => (
                                    <ListItem key={index}>
                                        <Skeleton variant="text" width="100%" height={80} />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <List>
                                {domainBlacklist.length === 0 ? (
                                    <ListItem>
                                        <ListItemText primary="No data found" sx={{ textAlign: "center" }} />
                                    </ListItem>
                                ) : (
                                    domainBlacklist.map((entry, index) => (
                                        <ListItem
                                            key={index}
                                            button
                                            onClick={() => handleDelist("Domain", entry.url)}
                                            sx={{
                                                border: "1px solid #ddd",
                                                borderRadius: "5px",
                                                marginBottom: "2px",
                                                "&:hover": {
                                                    backgroundColor: "#e0f2f1",
                                                },
                                            }}
                                        >
                                            <ListItemText primary={entry.name} sx={{ textAlign: "center" }} />
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDelist("Domain", entry.url)}
                                            >
                                                Delist
                                            </Button>
                                        </ListItem>
                                    ))
                                )}
                            </List>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper
                        sx={{
                            padding: "20px",
                            border: "1px solid #f1f1f1",
                            borderRadius: "5px",
                            backgroundColor: "#fff",
                            height: "300px",
                            overflowY: "auto",
                            boxShadow: "none !important",
                        }}
                    >
                        {loading ? (
                            <Skeleton variant="text" width={200} height={40} />
                        ) : (
                            <Typography
                                sx={{
                                    color: "#24a0ed",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                IP Blacklist
                            </Typography>
                        )}
                        {loading ? (
                            <List>
                                {[...Array(3)].map((_, index) => (
                                    <ListItem key={index}>
                                        <Skeleton variant="text" width="100%" height={80} />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <List>
                                {ipBlacklist.length === 0 ? (
                                    <ListItem>
                                        <ListItemText primary="No data found" sx={{ textAlign: "center" }} />
                                    </ListItem>
                                ) : (
                                    ipBlacklist.map((entry, index) => (
                                        <ListItem
                                            key={index}
                                            button
                                            onClick={() => handleDelist("IP", entry.url)}
                                            sx={{
                                                border: "1px solid #ddd",
                                                borderRadius: "5px",
                                                marginBottom: "2px",
                                                "&:hover": {
                                                    backgroundColor: "#e0f2f1",
                                                },
                                            }}
                                        >
                                            <ListItemText primary={entry.name} sx={{ textAlign: "center" }} />
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDelist("IP", entry.url)}
                                            >
                                                Delist
                                            </Button>
                                        </ListItem>
                                    ))
                                )}
                            </List>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default BlacklistPanel;
