import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SelectedSenderAccordian from "./SelectedSenderAccordian";
import Overview from "../AuditOverview/AuditOverview";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const ManualAccordian = () => {
    let { selectedSender } = useParams();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/;

    return (
        <div>
            {emailRegex.test(selectedSender) || domainRegex.test(selectedSender) ? (
                <SelectedSenderAccordian selectedSender={selectedSender} />
            ) : (
                <Overview unicodeId={selectedSender} />
            )}
        </div>
    );
};
export default ManualAccordian;
