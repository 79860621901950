import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
export const Accordian = ({ title, children, style, healthStatus,indexStep }) => {

     const healthColor = healthStatus === "good" ? "green" : "red";
      const tooltipMessage =
          healthStatus === "good"
              ? "The health of the BCC IDs is good, and the volume is within acceptable limits."
              : "The health of the BCC IDs is poor due to reaching the maximum volume.";
    return (
        <Box>
            <Accordion style={style ? style : { padding: "10px", marginBottom: "20px" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <span style={{ fontSize: "18px" }}>{title ? `Step ${indexStep+1} - ${title}` : "No title Found"}</span>
                    {healthStatus && (
                        <Tooltip title={tooltipMessage} arrow placement="top">
                            <span
                                style={{
                                    color: healthColor,
                                    marginLeft: "10px",
                                    backgroundColor: "#dff1ff",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                }}
                            >
                                {healthStatus}
                            </span>
                        </Tooltip>
                    )}
                </AccordionSummary>
                <AccordionDetails>{children ? children : "No data Found"}</AccordionDetails>
            </Accordion>
        </Box>
    );
};
