import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const LineChart = ({ data, options }) => {
    return (
        <div style={{ width: "500px", height: "550px" }}>
           <Line data={data} options={options} width={500} height={500} />
        </div>
    );
};


export default LineChart;
