import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
    Box,
    Grid,
    CardContent,
    Card,
    Button,
    TableContainer,
    TableBody,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    CircularProgress,
    Snackbar,
    Alert,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F8F9FB",
        fontSize: "16px",
        fontWeight:600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function ExtraTools() {
    const [alignment1, setAlignment1] = React.useState("DNG");
    const [dng, setDng] = useState(true);
    const [eha, setEha] = useState(false);
    const [domain, setDomain] = useState("");
    const [extension, setExtension] = useState("");
    const [num_domains, setNum_domains] = useState("");
    const [res, setRes] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [emailHeader, setEmailHeader] = useState("");
    const [resHeader, setResHeader] = useState([]);
    const [dataDomain, setDataDomain] = useState([]);
    const [generateLoader, setGenerateLoader] = useState(false);

    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    const HandleChange1 = (event, newAlignment) => {
        setAlignment1(newAlignment);
        if (newAlignment === "DNG") {
            setDng(true);
            setEha(false);
        } else if (newAlignment === "EHA") {
            setNum_domains("");
            setDomain("");
            setDng(false);
            setEha(true);
        }
    };

    function checkAvailability(d) {
        setGenerateLoader(true);
        let checkDomain = d;

        fetch(`${REACT_APP_SERVER_URL}/checkAvailability`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                domains: checkDomain,
            }),
        })
            .then((response) => {
                setGenerateLoader(false);

                return response.json();
            })
            .then((data) => {
                console.log(data, "data prinint");
                if (data.status === 200) {
                    setDataDomain(data.data);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.msg);
                }
            });
    }

    function generateDomain(sub_domain, extension, domain, num_domains) {
        // console.log(sub_domain, 'subdomain')
        // e.preventDefault();

        if (domain === "" || num_domains === "") {
            setErrorSnackbarOpen(true);
            setErrorMessage("Domain and No. of Domain fields are mandatory");
            return;
        }
        if (!/^\d+$/.test(num_domains)) {
            setErrorSnackbarOpen(true);
            setErrorMessage("No of Domains contain only Numeric data");
            return;
        }
        let str1 = [".com", ".net", ".org", ".info", ".co", ".ai", ".dev", ".ca", ".us"];
        let str2 = [
            "try",
            "go",
            "pro",
            "meet",
            "the",
            "scale",
            "get",
            "my",
            "run",
            "hq",
            "app",
            "labs",
            "cloud",
            "consulting",
        ];
        let array = [];
        let name = sub_domain ? sub_domain : "";

        for (let i = 0; i < num_domains; i++) {
            let suffix = str2[i % str2.length]; // Use modulo to cycle through the suffixes
            let domainExtension = extension ? extension : str1[i % str1.length]; // Use modulo to cycle through the extensions if extension is not provided
            let domainName = suffix + domain + domainExtension;
            array.push(domainName);
        }

        setRes(array);
        checkAvailability(array);
    }

    async function analyze(e) {
        e.preventDefault();
        let lines = emailHeader.split("\n");
        let headers = [];

        // Parsing each line of the header
        for (var i = 0; i < lines.length; i++) {
            var line = lines[i];
            var colonIndex = line.indexOf(":");
            if (colonIndex !== -1) {
                var headerName = line.substring(0, colonIndex).trim();
                var headerValue = line.substring(colonIndex + 1).trim();
                headers.push({ header: headerName, value: headerValue });
            }
        }
        if (headers.length === 0) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Bad header or input field is empty");
            return;
        } else {
            setResHeader(headers);
        }
    }

    return (
        <Box sx={{ width: "100%", textAlign: "center" }}>
            <Box>
                <span style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>Extra Tools</span>

            </Box>

            <Box sx={{ textAlign: "center", margin: "25px" }}>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment1}
                    exclusive
                    onChange={HandleChange1}
                    aria-label="Platform"
                    sx={{ marginBottom: "20px" }}
                >
                    <ToggleButton
                        value="DNG"
                        sx={{
                            color: alignment1 === "manual" ? "white" : "black",
                            backgroundColor: alignment1 === "manual" ? "#004aad" : "white",
                        }}
                    >
                        Secondary Domain Name Generator
                    </ToggleButton>
                    <ToggleButton
                        value="EHA"
                        sx={{
                            color: alignment1 === "auto" ? "white" : "black",
                            backgroundColor: alignment1 === "auto" ? "#004aad" : "white",
                        }}
                    >
                        Email Header Analyzer
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {dng && (
                <Box sx={{ mt: -2 }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                            style={{
                                fontSize: "24px",
                                lineHeight: "38px",
                            }}
                        >
                            Domain Name
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", marginTop: "16px" }}>
                        <TextField
                            required
                            id="outlined-basic"
                            label="Enter Domain Name"
                            variant="outlined"
                            sx={{ minWidth: "25%", minHeight: "40%" }}
                            size="small"
                            onChange={(e) => {
                                setDomain(e.target.value);
                            }}
                        />

                        <TextField
                            required
                            id="outlined-basic"
                            label="Enter The Number Of Domain"
                            variant="outlined"
                            sx={{ minWidth: "25%", minHeight: "40%", marginLeft: "30px" }}
                            size="small"
                            onChange={(e) => {
                                setNum_domains(e.target.value);
                            }}
                        />

                        <TextField
                            id="outlined-basic"
                            label="Enter The Extension"
                            variant="outlined"
                            sx={{ minWidth: "30%", minHeight: "40%", marginLeft: "30px" }}
                            size="small"
                            onChange={(e) => {
                                setExtension(e.target.value);
                            }}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            onClick={(e) => generateDomain("", extension, domain, num_domains)}
                            style={{ width: "200px", backgroundColor: "#004aad", color: "#fff", marginLeft: "39px" }}
                        >
                            Generate
                        </Button>
                    </Box>

                    <br></br>
                    {generateLoader && (
                        <>
                            <Box>
                                <CircularProgress></CircularProgress>
                            </Box>
                        </>
                    )}
                    {dataDomain.length !== 0 && (
                        <Box>
                            <TableContainer sx={{ borderRadius: "10px", border: "1px solid #E7E7E7" }}>
                                <Table sx={{ textAlign: "center" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Domain Name</StyledTableCell>
                                            <StyledTableCell align="left">Availability</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataDomain &&
                                            dataDomain.map((data, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell align="left">{data.domain}</StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {data.available === true ? (
                                                            <div style={{ color: "green" }}>Available</div>
                                                        ) : data.available === false ? (
                                                            <div style={{ color: "red" }}>Not Available</div>
                                                        ) : (
                                                            <div style={{ color: "blue" }}>
                                                                Check with domain provider
                                                            </div>
                                                        )}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <CardContent>
                                <Grid
                                    style={{
                                        width: "89.5%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontWeight: 600,
                                        color: "white",
                                        fontSize: "18px",
                                        background: "#525CEB",
                                        borderRadius: "10px",
                                        padding: "1%",
                                        marginTop: "-5px",
                                        overflow: "hidden",
                                    }}
                                    container
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid sm={0}>
                                        <label>Domain Name</label>
                                    </Grid>
                                    <Grid sm={2}>
                                        <label>Availability</label>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            {dataDomain &&
                                dataDomain.map((data, i) => (
                                    <div style={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
                                        <div
                                            style={{
                                                width: "120%",
                                                height: "10vh",
                                                border: "0.1rem solid #EEE7DA",
                                                marginBottom: "1%",
                                                padding: "1.5%",
                                                borderRadius: "10px",
                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                cursor: data.status === "Verified" ? "pointer" : "default",
                                                maxWidth: 1000,
                                                overflow: "hidden",
                                                backgroundColor: data.status === "Verified" ? "#f5f5f5" : "transparent",
                                                alignItems: "center",
                                                marginTop: "-8px",
                                            }}
                                        >
                                            <CardContent>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        marginTop: "-20px",
                                                    }}
                                                >
                                                    <Grid>
                                                        <Typography sx={{ marginTop: "8px" }}>{data.domain}</Typography>
                                                    </Grid>
                                                    <Grid>
                                                        <Typography>
                                                            {data.available === true ? (
                                                                <div style={{ color: "green" }}>Available</div>
                                                            ) : data.available === false ? (
                                                                <div style={{ color: "red" }}>Not Available</div>
                                                            ) : (
                                                                <div style={{ color: "blue" }}>
                                                                    Check with domain provider
                                                                </div>
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </div>
                                    </div>
                                ))} */}
                        </Box>
                    )}
                </Box>
            )}
            {eha && (
                <>
                    <Box>
                        <Typography style={{ fontSize: "24px", fontWeight: "700px" }}>Email Header Analyze </Typography>
                    </Box>
                    <Card
                        sx={{
                            width: "100%",
                            marginRight: "8.5%",
                            borderRadius: "10px",
                            marginTop: "5px",
                            padding: "10px",
                            backgroundColor: "whitesmoke",
                        }}
                    >
                        <CardContent>
                            <Box>
                                <textarea
                                    className="tarea"
                                    onChange={(e) => {
                                        setEmailHeader(e.target.value);
                                    }}
                                ></textarea>
                                <br></br>
                                <Button
                                    variant="contained"
                                    sx={{ float: "right", marginBottom: "20px", marginTop: "10px" }}
                                    onClick={analyze}
                                >
                                    Analyze header
                                </Button>
                            </Box>
                        </CardContent>
                        {resHeader.length !== 0 && (
                            <Box>
                                {/* <CardContent>
                                    <Grid
                                        style={{
                                            minWidth: 1200,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                            color: "white",
                                            fontSize: "18px",
                                            background: "#525CEB",
                                            borderRadius: "10px",
                                            padding: "1%",
                                            marginTop: "-5px",
                                            overflow: "hidden",
                                        }}
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid>
                                            <label>Key</label>
                                        </Grid>
                                        <Grid>
                                            <label>Value</label>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                {resHeader &&
                                    resHeader.map((data, i) => (
                                        <div
                                            style={{
                                                minWidth: 1000,
                                                height: "10vh",
                                                border: "0.1rem solid #EEE7DA",
                                                marginBottom: "1%",
                                                padding: "1.5%",
                                                borderRadius: "10px",
                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                cursor: data.status === "Verified" ? "pointer" : "default",
                                                backgroundColor: data.status === "Verified" ? "#f5f5f5" : "transparent",
                                            }}
                                        >
                                            <CardContent>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    key={i}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Grid>{data.header}</Grid>
                                                    <Grid>{data.value}</Grid>
                                                </Grid>
                                            </CardContent>
                                        </div>
                                    ))} */}
                                <TableContainer sx={{ borderRadius: "10px", border: "1px solid #E7E7E7" }}>
                                    <Table sx={{ textAlign: "center" }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left">
                                                    <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                                                        Key
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                                                        Value
                                                    </Typography>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {resHeader &&
                                                resHeader.map((data, i) => (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell align="left">
                                                            <Typography sx={{ fontSize: "15px" }}>
                                                                {data.header}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            <Typography sx={{ fontSize: "15px" }}>
                                                                {data.value}
                                                            </Typography>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                    </Card>
                </>
            )}
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default ExtraTools;
