import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { ToggleButtonGroup, ToggleButton, TextField, CircularProgress } from "@mui/material";
import { isBefore } from "date-fns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function CustomizedDialogs({
    open,
    onClose,
    description,
    email,
    descriptiondateRange,
    description1,
    description2,
    Add,
    loading,
}) {
    const [alignment, setAlignment] = React.useState("Default");
    const [Default, setDefault] = useState(true);
    const [dateRange, setDateRange] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [error, setError] = useState("");
    const [currentDate] = useState(new Date());

    const HandleChange1 = (event, newAlignment) => {
        if (newAlignment === "Default") {
            setDefault(true);
            setDateRange(false);
            setAlignment("Default");
        } else if (newAlignment === "dateRange") {
            setDefault(false);
            setDateRange(true);
            setAlignment("dateRange");
        }
    };

    const handleTextFieldChange = (event) => {
        const input = event.target.value;
        setTextFieldValue(input);

        const parsedDate = Date.parse(input);

        if (isNaN(parsedDate)) {
            setError("Invalid date format");
        } else if (isBefore(new Date(parsedDate), currentDate)) {
            setError("Check if the entered date is in the past");
        } else {
            setError("Please enter a date in the past");
        }
    };

    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            sx={{
                "& .MuiDialog-paper": {
                    width: 500,
                    maxWidth: 500,
                    height: "auto",
                },
            }}
        >
            <DialogTitle sx={{ m: 2, p: 2 }} id="customized-dialog-title">
                <ToggleButtonGroup
                    variant="solid"
                    value={alignment}
                    exclusive
                    onChange={HandleChange1}
                    aria-label="Platform"
                    sx={{
                        marginBottom: "10px",
                        width: "100%",
                        height: "40px",
                        justifyContent: "center",
                        marginTop: "10px",
                    }}
                >
                    <ToggleButton
                        style={{
                            width: "auto",
                            padding: "10px",
                            color: alignment === "Default" ? "white" : "black",
                            backgroundColor: alignment === "Default" ? "#004aad" : "white",
                        }}
                        value="Default"
                    >
                        Default
                    </ToggleButton>
                    <ToggleButton
                        style={{
                            width: "auto",
                            padding: "10px",
                            color: alignment === "dateRange" ? "white" : "black",
                            backgroundColor: alignment === "dateRange" ? "#004aad" : "white",
                        }}
                        value="dateRange"
                    >
                        dateRange
                    </ToggleButton>
                </ToggleButtonGroup>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            {Default && (
                <>
                    <DialogContent dividers>
                        <Typography gutterBottom sx={{ textAlign: "center" }}>
                            {description}
                        </Typography>
                        {/* <Typography sx={{ textAlign: "center", fontSize: "16px" }}>{email}</Typography>
                        <Typography sx={{ textAlign: "center", fontSize: "16px" }}>{descriptiondateRange}</Typography> */}
                    </DialogContent>
                    <DialogActions style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "red" }}
                            onClick={() => Add(email, descriptiondateRange)}
                            disabled={loading}
                            startIcon={loading && <CircularProgress size={20} style={{ color: "white" }} />}
                        >
                            {loading ? "Deleting..." : "Confirm, Delete"}
                        </Button>
                        <Button autoFocus variant="contained" color="primary" onClick={onClose}>
                            Dismiss
                        </Button>
                    </DialogActions>
                </>
            )}

            {dateRange && (
                <>
                    <DialogContent dividers>
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                            {" "}
                            {description1}
                        </Typography>
                        <Typography gutterBottom sx={{ textAlign: "center" }}>
                            {description2}
                        </Typography>
                        <div style={{ textAlign: "center" }}>
                            <TextField
                                id="outlined-basic"
                                label="Date"
                                variant="outlined"
                                value={textFieldValue}
                                onChange={handleTextFieldChange}
                                error={!!error}
                                helperText={error}
                                size="small"
                                placeholder="DD/MM/YYYY"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "red" }}
                            onClick={() => Add(email, textFieldValue)}
                            disabled={loading}
                            startIcon={loading && <CircularProgress size={20} style={{ color: "white" }} />}
                        >
                            {loading ? "Deleting..." : "Confirm, Delete"}
                        </Button>
                        <Button autoFocus variant="contained" color="primary" onClick={onClose}>
                            Dismiss
                        </Button>
                    </DialogActions>
                </>
            )}
        </BootstrapDialog>
    );
}

export default CustomizedDialogs;
