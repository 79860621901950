import React, { useState } from "react";
import { styled, createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
    AppBar as MuiAppBar,
    Toolbar,
    Typography,
    Box,
    IconButton,
    List,
    ListItem,
    Drawer as MuiDrawer,
    Divider,
    Container,
    MenuItem,
    Menu,
    Stack,
} from "@mui/material";

import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink, useNavigate, Outlet } from "react-router-dom";
import emailAcc from "../../img/icons/emailAcc.png";
import tools from "../../img/icons/tools.png";
import email1 from "../../img/icons/email1.png";
import domain from "../../img/icons/domain.png";
import blacklist from "../../img/icons/blacklist.png";
import records from "../../img/icons/records.png";
import spam from "../../img/icons/spam.png";
import avtar from "../../img/icons/avtar.png";
import blueemail from "../../img/icons/blueemail.png";
import bluedomain from "../../img/icons/bluedomain.png";
import blueblacklist from "../../img/icons/blueblacklist.png";
import bluedns from "../../img/icons/bluedns.png";
import bluespam from "../../img/icons/bluespam.png";
import bluetool from "../../img/icons/bluetool.png";
import bluesub from "../../img/icons/bluesub.png";
import sub from "../../img/icons/sub.png";
import bluecal from "../../img/icons/bluecal.png";
import receivemail from "../../img/icons/outlook.png";
import globe from "../../img/icons/globe.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import cal from "../../img/icons/cal.png";
import xaudit from "../../img/icons/xaudit.png";
import emailaudit from "../../img/icons/email_audit.png";
import logo1 from "../../img/icons/logo1.png";
import jwt from "jwt-decode";
import cookie from "cookiejs";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
const drawerWidth = 244;

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: "white",
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 15,
        },
    })
);

const DashBoard = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [open, setOpen] = React.useState(true);
    const [isadmin, setAdmin] = React.useState("");
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(null);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(true);
    const [userRakesh, setUserRakesh] = useState(true);
    const [IptIcon, setIptIcon] = useState(false);
    const [DomIcon, setDomIcon] = useState(false);
    const [Blacklist, setBlacklist] = useState(false);
    const [DNS, setDNS] = useState(false);
    const [Spam, setSpam] = useState(false);
    const [Tool, setTool] = useState(false);
    const [Sub, setSub] = useState(false);
    const [Cal, setCal] = useState(false);
    const [Help, setHelp] = useState(false);
    const [email, setEmail] = useState(false);
    const [Tool1, setTool1] = useState(false);
    const [spreadsheet, setSpreadSheet] = useState(false);
    const [checkUserPema, setCheckUserPema] = useState(false);
    const [integration, setIntegration] = useState(false);
    const [manualAudit, setManualAudit] = useState(false);

    //uncomment for server development
    //comment for local development

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    React.useEffect(() => {
        let isLogin = cookie.get("token");
        if (!isLogin) navigate("/login");
        else {
            pageRenderUserCheck();
            const user = jwt(isLogin);
            console.log(user);
            setAdmin(user.admin);
            setFName(user.firstname);
            setLName(user.lastname);
            setEmail(user.email);
        }
    }, [navigate]);

    // useEffect(() => {

    // }, [])

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(null);
    };
    const handleProfileMenuOpen = (event) => {
        setProfileMenuOpen(event.currentTarget);
    };
    const handleLogoutAPI = (e) => {
        e.preventDefault();
        fetch(`${REACT_APP_SERVER_URL}/logout`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        }).then((response) => {
            if (response.status === 200) {
                console.log("User Logged out");
                navigate("/login");
                return response.json();
            } else {
                console.log("Some error");
            }
        });
    };

    console.log("page rendering here");

    const pageRenderUserCheck = () => {
        try {
            fetch(`${REACT_APP_SERVER_URL}/checkUserForDashboard`, {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.data === "rakesh@saleshive.com") {
                        setUserRakesh(true);
                        setCheckUserPema(false);
                    } else if (data.data === "pema@pema.io") {
                        setUserRakesh(false);
                        setCheckUserPema(true);
                    } else {
                        setUserRakesh(false);
                        setCheckUserPema(false);
                    }
                    console.log(data, "Response data");
                })
                .catch((error) => {
                    console.error("Fetch error:", error);
                });
        } catch (error) {
            console.error("Error:", error);
        }
    };

    function iptClickHandler() {
        setIptIcon(true);
        setDomIcon(false);
        setBlacklist(false);
        setDNS(false);
        setSpam(false);
        setTool(false);
        setSub(false);
        setHelp(false);
        setCal(false);
        setTool1(false);
        setSpreadSheet(false);
    }

    function domRepClick() {
        setDomIcon(true);
        setIptIcon(false);
        setBlacklist(false);
        setDNS(false);
        setSpam(false);
        setTool(false);
        setSub(false);
        setHelp(false);
        setCal(false);
        setTool1(false);
        setSpreadSheet(false);
    }

    function BlacklistClick() {
        setBlacklist(true);
        setDomIcon(false);
        setIptIcon(false);
        setDNS(false);
        setSpam(false);
        setTool(false);
        setSub(false);
        setHelp(false);
        setCal(false);
        setTool1(false);
        setSpreadSheet(false);
    }

    function DNSClick() {
        setDNS(true);
        setBlacklist(false);
        setDomIcon(false);
        setIptIcon(false);
        setSpam(false);
        setTool(false);
        setSub(false);
        setHelp(false);
        setCal(false);
        setTool1(false);
        setSpreadSheet(false);
    }

    function SpamClick() {
        setSpam(true);
        setBlacklist(false);
        setDomIcon(false);
        setIptIcon(false);
        setDNS(false);
        setTool(false);
        setSub(false);
        setHelp(false);
        setCal(false);
        setSpreadSheet(false);
    }

    function ToolClick() {
        setTool(true);
        setSpam(false);
        setBlacklist(false);
        setDomIcon(false);
        setIptIcon(false);
        setDNS(false);
        setSub(false);
        setHelp(false);
        setCal(false);
        setTool1(false);
        setSpreadSheet(false);
    }

    function ToolClick1() {
        setTool1(true);
        setTool(false);
        setSpam(false);
        setBlacklist(false);
        setDomIcon(false);
        setIptIcon(false);
        setDNS(false);
        setSub(false);
        setHelp(false);
        setCal(false);
        setSpreadSheet(false);
    }

    function SubClick() {
        setSub(true);
        setTool(false);
        setSpam(false);
        setBlacklist(false);
        setDomIcon(false);
        setIptIcon(false);
        setDNS(false);
        setHelp(false);
        setCal(false);
        setTool1(false);
        setSpreadSheet(false);
    }

    function CalClick() {
        setCal(true);
        setSub(false);
        setTool(false);
        setSpam(false);
        setBlacklist(false);
        setDomIcon(false);
        setIptIcon(false);
        setDNS(false);
        setHelp(false);
        setTool1(false);
        setSpreadSheet(false);
    }

    function SpreadSheet() {
        setSpreadSheet(true);
        setCal(false);
        setSub(false);
        setTool(false);
        setSpam(false);
        setBlacklist(false);
        setDomIcon(false);
        setIptIcon(false);
        setDNS(false);
        setHelp(false);
        setTool1(false);
    }

    function integrationClick() {
        setIntegration(true);
        setTool1(false);
        setTool(false);
        setSpam(false);
        setBlacklist(false);
        setDomIcon(false);
        setIptIcon(false);
        setDNS(false);
        setSub(false);
        setHelp(false);
        setCal(false);
        setSpreadSheet(false);
    }

    function manualAuditClick() {
        setManualAudit(true);
        setIntegration(false);
        setTool1(false);
        setTool(false);
        setSpam(false);
        setBlacklist(false);
        setDomIcon(false);
        setIptIcon(false);
        setDNS(false);
        setSub(false);
        setHelp(false);
        setCal(false);
        setSpreadSheet(false);
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    open={open}
                    sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                        boxShadow: "none",
                        marginTop: "-2px",
                    }}
                >
                    <Toolbar
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            boxShadow: "none",
                        }}
                    >
                        {!open && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginLeft: "0!important",
                                    color: "black!important",
                                    ...(open && { display: "none" }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        {open && (
                            <IconButton
                                sx={{
                                    marginRight: 5,
                                }}
                                onClick={() => {
                                    handleDrawerClose();
                                }}
                            >
                                {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                                width: "84%",
                                backgroundColor: "white",
                                margin: "5px",
                                overflow: "hidden",
                            }}
                        >
                            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                                <IconButton
                                    variant="outlined"
                                    onClick={handleProfileMenuOpen}
                                    sx={{
                                        mx: 1,
                                        mb: 1,
                                        padding: "8px",
                                        border: "1px solid #e7e7e7",
                                        borderRadius: "6px",
                                        color: "#4F4F4F",
                                        height: "43px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <img
                                            src={avtar}
                                            alt="profile-img"
                                            width="32px"
                                            style={{ borderRadius: "50%" }}
                                        />
                                        <Typography
                                            sx={{
                                                color: "#4F4F4F",
                                                fontSize: "14px",
                                                fontFamily: "'HK Nova', sans-serif",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {isadmin ? <h>Admin</h> : fname}
                                        </Typography>
                                    </Stack>
                                </IconButton>
                                <Box sx={{ float: "right" }}>
                                    {/* <IconButton sx={{ width: "48px", height: "48px", mx: 2, border: "1px solid #e7e7e7" }}>
                                    <QuestionMarkOutlinedIcon />
                                </IconButton>
                                <IconButton sx={{ width: "48px", height: "48px", mx: 2, border: "1px solid #e7e7e7" }}>
                                    <Badge badgeContent={1} color="error">
                                        <NotificationsNoneOutlinedIcon />
                                    </Badge>
                                </IconButton> */}
                                    {/*<Button variant="outlined" onClick={handleProfileMenuOpen} sx={{ mx: 2, }}><AccountCircleIcon></AccountCircleIcon>
                                        {isadmin ? <h>Admin</h> : fname}
                                    </Button>
                            */}
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={profileMenuOpen}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(profileMenuOpen)}
                                        onClose={handleProfileMenuClose}
                                        style={{ border: "1px solid rgba(0, 0, 0, 0.13)" }}
                                    >
                                        <MenuItem
                                            sx={{
                                                width: "100%",
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                    borderbottom: "1px solid Graytext",
                                                }}
                                            >
                                                <div>
                                                    <img
                                                        src={avtar}
                                                        alt="profile-img"
                                                        width="32px"
                                                        style={{ borderRadius: "50%" }}
                                                    />
                                                </div>
                                                <div style={{ padding: "18px", marginTop: "-20px" }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 600,
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        {isadmin ? <h>Admin</h> : fname + " " + lname}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        {isadmin ? <h>Admin</h> : email}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </MenuItem>
                                        <div
                                            style={{
                                                marginTop: "2px",
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                                                width: "auto",
                                            }}
                                        >
                                            <NavLink
                                                to="setting"
                                                style={{
                                                    display: "flex",
                                                    textAlign: "center",
                                                    justifyContent: "space-between",
                                                    justifyItems: "center",
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    color: "black",
                                                    padding: "6px",
                                                }}
                                            >
                                                <ManageAccountsIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginLeft: "8px",
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        mr: "59%",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Setting{" "}
                                                </Typography>
                                            </NavLink>
                                        </div>
                                        <div style={{ width: "auto" }}>
                                            <MenuItem onClick={handleLogoutAPI}>
                                                <LogoutIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginRight: "4px",
                                                    }}
                                                />{" "}
                                                <Typography
                                                    sx={{
                                                        ml: "12px",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Logout{" "}
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                    </Menu>
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    open={open}
                    style={{
                        boxshadow:
                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                    }}
                >
                    <DrawerHeader
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            boxshadow:
                                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                        }}
                    >
                        <Box sx={{ paddingRight: "10%" }}>
                            <img
                                src={emailaudit}
                                alt="img"
                                style={{
                                    height: "50px",
                                    width: "195px",
                                    top: "0",
                                    boxshadow:
                                        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                                    marginLeft: "20px",
                                }}
                            />
                        </Box>
                    </DrawerHeader>
                    <Divider />

                    <List sx={{ pl: 2, fontFamily: `'Inter', sans-serif` }}>
                        {userRakesh ? (
                            <NavLink exact to="dataDashboard" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                <ListItem
                                    sx={{
                                        backgroundColor:
                                            window.location.pathname.split("/")[2] === "Dashboard" ? "#F7F7F9" : "none",
                                        borderRadius: "50px 0px 0px 50px",
                                        borderRight:
                                            window.location.pathname.split("/")[2] === "Dashboard"
                                                ? "4px solid #7953FF"
                                                : "none",
                                        width: "100%",
                                        mb: 2,
                                    }}
                                >
                                    <img
                                        src={emailAcc}
                                        alt=""
                                        style={{
                                            marginRight: 10,
                                            height: "30px",
                                            width: "30px",
                                        }}
                                    />
                                    <Box>DashBoard</Box>
                                </ListItem>
                            </NavLink>
                        ) : (
                            <div>
                                <NavLink exact to="manualAudit" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                    <ListItem
                                        onClick={manualAuditClick}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "left",
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "manualAudit"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "manualAudit"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                            fontSize: "13px",
                                            color:
                                                window.location.pathname.split("/")[2] === "manualAudit"
                                                    ? "blue!important"
                                                    : "none",
                                        }}
                                    >
                                        {!Tool1 ? (
                                            <img
                                                src={records}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={bluedns}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        )}
                                        <div style={{ marginLeft: "12px" }}>Manual Audit</div>
                                    </ListItem>
                                </NavLink>

                                <NavLink exact to="InboxPlacement" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                    <ListItem
                                        onClick={iptClickHandler}
                                        sx={{
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "InboxPlacement"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "InboxPlacement"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            color:
                                                window.location.pathname.split("/")[2] === "InboxPlacement"
                                                    ? "blue!important"
                                                    : "none",
                                            mb: 2,
                                            fontSize: "13px",
                                        }}
                                    >
                                        {!IptIcon ? (
                                            <img
                                                src={email1}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={blueemail}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        )}
                                        <Box sx={{ marginLeft: "12px" }}>Inbox Placement</Box>
                                    </ListItem>
                                </NavLink>
                                <NavLink
                                    exact
                                    to="DomainReputation"
                                    style={{ textDecoration: "none", color: "#4F4F4F" }}
                                >
                                    <ListItem
                                        onClick={domRepClick}
                                        sx={{
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "DomainReputation"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "DomainReputation"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                            fontSize: "13px",
                                            color:
                                                window.location.pathname.split("/")[2] === "DomainReputation"
                                                    ? "blue!important"
                                                    : "none",
                                        }}
                                    >
                                        {!DomIcon ? (
                                            <img
                                                src={domain}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={bluedomain}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        )}
                                        <Box sx={{ marginLeft: "12px" }}>Domain Reputation</Box>
                                    </ListItem>
                                </NavLink>
                                <NavLink exact to="IpBlacklist" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                    <ListItem
                                        onClick={BlacklistClick}
                                        sx={{
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "IpBlacklist"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "IpBlacklist"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                            fontSize: "13px",
                                            color:
                                                window.location.pathname.split("/")[2] === "IpBlacklist"
                                                    ? "blue!important"
                                                    : "none",
                                        }}
                                    >
                                        {!Blacklist ? (
                                            <img
                                                src={blacklist}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                    color: "secondary",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={blueblacklist}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        )}

                                        <Box sx={{ marginLeft: "12px" }}>Blacklist Checker</Box>
                                    </ListItem>
                                </NavLink>

                                <NavLink exact to="DnsRecords" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                    <ListItem
                                        onClick={DNSClick}
                                        sx={{
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "DnsRecords"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "DnsRecords"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                            fontSize: "13px",
                                            color:
                                                window.location.pathname.split("/")[2] === "DnsRecords"
                                                    ? "blue!important"
                                                    : "none",
                                        }}
                                    >
                                        {!DNS ? (
                                            <img
                                                src={globe}
                                                alt=""
                                                style={{
                                                    height: "25px",
                                                    width: "25px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={logo1}
                                                alt=""
                                                style={{
                                                    height: "25px",
                                                    width: "25px",
                                                }}
                                            />
                                        )}

                                        <Box sx={{ marginLeft: "14px" }}> DNS Records</Box>
                                    </ListItem>
                                </NavLink>

                                <NavLink
                                    exact
                                    to="spammy-word-checker"
                                    style={{ textDecoration: "none", color: "#4F4F4F" }}
                                >
                                    <ListItem
                                        onClick={SpamClick}
                                        sx={{
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "spammy-word-checker"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "spammy-word-checker"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                            fontSize: "13px",
                                            color:
                                                window.location.pathname.split("/")[2] === "spammy-word-checker"
                                                    ? "blue!important"
                                                    : "none",
                                        }}
                                    >
                                        {!Spam ? (
                                            <img
                                                src={spam}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={bluespam}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                                className="hover-image"
                                            />
                                        )}

                                        <Box sx={{ marginLeft: "12px" }}>Spam Checker</Box>
                                    </ListItem>
                                </NavLink>
                                <NavLink
                                    exact
                                    to="DomainCalculator"
                                    style={{ textDecoration: "none", color: "#4F4F4F" }}
                                >
                                    <ListItem
                                        onClick={CalClick}
                                        sx={{
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "DomainCalculator"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "DomainCalculator"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                            fontSize: "13px",
                                            color:
                                                window.location.pathname.split("/")[2] === "DomainCalculator"
                                                    ? "blue!important"
                                                    : "none",
                                        }}
                                    >
                                        {!Cal ? (
                                            <img
                                                src={cal}
                                                alt="render"
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={bluecal}
                                                alt="notrender"
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        )}

                                        <Box sx={{ marginLeft: "12px" }}>Cold Email Strategy</Box>
                                    </ListItem>
                                </NavLink>

                                <NavLink exact to="extra-tools" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                    <ListItem
                                        onClick={ToolClick}
                                        sx={{
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "extra-tools"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "extra-tools"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                            fontSize: "13px",
                                            color:
                                                window.location.pathname.split("/")[2] === "extra-tools"
                                                    ? "blue!important"
                                                    : "none",
                                        }}
                                    >
                                        {!Tool ? (
                                            <img
                                                src={tools}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={bluetool}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        )}
                                        <div style={{ marginLeft: "12px" }}>Tools</div>
                                    </ListItem>
                                </NavLink>

                                <NavLink exact to="find-ESP" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                    <ListItem
                                        onClick={ToolClick1}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "left",
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "find-ESP"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "find-ESP"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                            fontSize: "13px",
                                            color:
                                                window.location.pathname.split("/")[2] === "find-ESP"
                                                    ? "blue!important"
                                                    : "none",
                                        }}
                                    >
                                        {!Tool1 ? (
                                            <img
                                                src={records}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={bluedns}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        )}
                                        <div style={{ marginLeft: "12px" }}>ESP Finder</div>
                                    </ListItem>
                                </NavLink>

                                {!checkUserPema && (
                                    <NavLink
                                        exact
                                        to="spreadsheet"
                                        style={{ textDecoration: "none", color: "#4F4F4F" }}
                                    >
                                        <ListItem
                                            onClick={SpreadSheet}
                                            sx={{
                                                backgroundColor:
                                                    window.location.pathname.split("/")[2] === "spreadsheet"
                                                        ? "#F7F7F9"
                                                        : "none",
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight:
                                                    window.location.pathname.split("/")[2] === "spreadsheet"
                                                        ? "4px solid #7953FF"
                                                        : "none",
                                                width: "100%",
                                                mb: 2,
                                                fontSize: "13px",
                                                color:
                                                    window.location.pathname.split("/")[2] === "spreadsheet"
                                                        ? "blue!important"
                                                        : "none",
                                            }}
                                        >
                                            {!spreadsheet ? (
                                                <ScheduleSendIcon
                                                    style={{
                                                        height: "30px",
                                                        width: "30px",
                                                    }}
                                                />
                                            ) : (
                                                <ScheduleSendIcon
                                                    style={{
                                                        color: "#7953FF",
                                                        height: "30px",
                                                        width: "30px",
                                                    }}
                                                />
                                                // <img
                                                //     src={bluespam}
                                                //     alt=""
                                                //     style={{
                                                //         height: "30px",
                                                //         width: "30px",
                                                //     }}
                                                // />
                                            )}

                                            <Box sx={{ marginLeft: "12px" }}>Spam Watch</Box>
                                        </ListItem>
                                    </NavLink>
                                )}

                                {/* <NavLink exact to='teams' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                            <ListItem
                                sx={{
                                    backgroundColor: window.location.pathname.split('/')[2] === 'teams' ? '#F7F7F9' : 'none',
                                    borderRadius: "50px 0px 0px 50px",
                                    borderRight: window.location.pathname.split('/')[2] === 'teams' ? "4px solid #7953FF" : 'none',
                                    width: "100%",
                                    mb: 2
                                }}
                            >
                                <img src={deliverabiltyLogo} alt="" style={{ marginRight: 10 }} />Teams
                            </ListItem>
                        </NavLink> */}

                                {/* {!isadmin ? (
                                    <NavLink
                                        exact
                                        to="subscriptions"
                                        style={{ textDecoration: "none", color: "#4F4F4F" }}
                                    >
                                        <ListItem
                                            onClick={SubClick}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "left",
                                                backgroundColor:
                                                    window.location.pathname.split("/")[2] === "subscriptions"
                                                        ? "#F7F7F9"
                                                        : "none",
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight:
                                                    window.location.pathname.split("/")[2] === "subscriptions"
                                                        ? "4px solid #7953FF"
                                                        : "none",
                                                width: "100%",
                                                mb: "2",
                                                fontSize: "13px",
                                                color:
                                                    window.location.pathname.split("/")[2] === "subscriptions"
                                                        ? "blue!important"
                                                        : "none",
                                            }}
                                        >
                                            {!Sub ? (
                                                <img
                                                    src={sub}
                                                    alt=""
                                                    style={{
                                                        height: "30px",
                                                        width: "30px",
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    src={bluesub}
                                                    alt=""
                                                    style={{
                                                        height: "30px",
                                                        width: "30px",
                                                    }}
                                                />
                                            )}

                                            <Box sx={{ marginLeft: "12px" }}>Subscriptions</Box>
                                        </ListItem>
                                    </NavLink>
                                ) : null} */}

                                <NavLink exact to="integration" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                    <ListItem
                                        onClick={integrationClick}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "left",
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "integration"
                                                    ? "#F7F7F9"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "integration"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                            fontSize: "13px",
                                            color:
                                                window.location.pathname.split("/")[2] === "integration"
                                                    ? "blue!important"
                                                    : "none",
                                        }}
                                    >
                                        {!Tool1 ? (
                                            <img
                                                src={records}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={bluedns}
                                                alt=""
                                                style={{
                                                    height: "30px",
                                                    width: "30px",
                                                }}
                                            />
                                        )}
                                        <div style={{ marginLeft: "12px" }}>Integration</div>
                                    </ListItem>
                                </NavLink>

                                {/* <NavLink exact to='help' style={{ textDecoration: "none", color: "#4F4F4F", }}> 
                                        <ListItem
                                            onClick={HelpClick}
                                            sx={{
                                                backgroundColor: window.location.pathname.split('/')[2] === 'help' ? '#F7F7F9' : 'none',
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight: window.location.pathname.split('/')[2] === 'help' ? "4px solid #7953FF" : 'none',
                                                width: "100%",
                                                mt: 2,
                                                fontSize: '13px',
                                                color: window.location.pathname.split('/')[2] === 'help' ? 'blue!important' : 'none',
                                            }}
                                        >
                                            {!Help ? <img src={help} alt="" style={{
                                                marginRight: 8,
                                                height: '30px',
                                                width: '30px',
                                            }} /> :
                                                <LightTooltip title={<span style={{ color: 'blue' }}>Help</span>} placement="right">
                                                    <img src={bluepage} alt="" style={{
                                                        marginRight: 12, height: '30px',
                                                        width: '30px',
                                                    }} />
                                                </LightTooltip>

                                            }
                                            <Box style={{ marginRight: 8 }}>Help</Box>
                                        </ListItem>
                                    </NavLink>*/}
                            </div>
                        )}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                        backgroundColor: "#FBFBFB",
                    }}
                >
                    <Container sx={{ pt: 2 }}>
                        <Box>
                            {window.location.pathname.split("/")[2] === "DnsRecords" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    DNS Records
                                </Typography>
                            )}

                            {window.location.pathname.split("/")[2] === "IpBlacklist" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Blacklist Checker
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "common_mailbox" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Common Mailbox
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "warmup_tamplet" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Warmup Template
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "email_accounts" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Email Accounts
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "deliverability" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Deliverability
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "integrations" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Integrations
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "InboxPlacement" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        textAlign: "center",
                                        fontSize: "35px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Inbox Placement
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "DomainReputation" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Domain Reputation
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "AutoInbox" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Auto Inbox Placement
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "DomainCalculator" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Domain Calculator
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "teams" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Teams
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "extra-tools" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Extra Tools
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "subscriptions" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Subscriptions
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "spammy-word-checker" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Spam Checker
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "dataDashBoard" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    DashBoard
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "help" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Help
                                </Typography>
                            )}
                            {window.location.pathname.split("/")[2] === "spreadsheet" && (
                                <Typography
                                    color="#000"
                                    sx={{
                                        float: "left",
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                    }}
                                >
                                    Spread Sheet
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ py: 10 }}>
                            <Outlet />
                            {/* <Routes>
                                <Route path='campaign' element={<Campaign />} />
                                <Route path='analytics' element={<Analytics />} />
                                <Route path='email_accounts' element={<EmailAccounts />} />
                                <Route path='integrations' element={<Integrations />} />
                                <Route path="subscriptions" element={<Subscriptions />} />
                                <Route path='common_mailbox' element={<CommonMailbox/>}/>
                            </Routes> */}
                        </Box>
                    </Container>
                </Box>
            </Box>
            {/* <Snackbar open={successSnackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: '100%' }}>
                    Login successful
                </Alert>
            </Snackbar> */}
        </ThemeProvider>
    );
};

export default DashBoard;
